export const setPlace = (place) => ({
    type: "place",
    payload: place
  });
export const Hotel = () => ({
  type: "Hotel",
});
export const _Flight_ = () => ({
  type: "Flight",
});
export const Car_Rentals = () => ({
  type: "Car Rentals",
});
export const Restaurants = () => ({
  type: "Restaurants",
});
export const Things_to_do = () => ({
  type: "Things to do",
});
export const Audio_Tour = () => ({
  type: "Audio Tour",
});
export const _Deals_ = () => ({
  type: "Deals",
});
export const _Blog_ = () => ({
  type: "Blog",
});

import React, { Fragment, useState, useEffect } from "react";

import OwlCarousel from 'react-owl-carousel';
import { apiData } from "../../../../actions";
// import { UsePlaceAuth } from "../../../../AppContext/AppContext";
// import DataLoader from "../../../../Utils/DataLoader";
import location from "../../../../demo/lcation.png"
import { Rate } from "antd";
import { useSelector } from "react-redux";

function FamilyAttraction() {
  const place = useSelector(state => state.place)
  const [loader, setLoader] = useState(false);

  var settings = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 4
        }
    }
  };

  const [familyAttractionData, setFamilyAttractionData] = useState("");
  const GetFamilyAttraction = async () => {
    setLoader(true);
    let payload = {
      city: place,
    };
    let apiRes = await apiData.getFamilyAttraction(payload);
    setFamilyAttractionData(apiRes?.data?.familytopattr?.businesses);
    setLoader(false);
  };
  useEffect(() => {
    GetFamilyAttraction();
  }, [place]);
  return (
    <Fragment>
      {
        !loader ?
          <div className="flight-sec exitment-sec new1">
            <div className="slider-sec">
              <h3>{`Family Top Attractions in ${place?.replace(/\b\w/g, x => x?.toUpperCase())}`}</h3>
              <OwlCarousel {...settings}>
                {familyAttractionData && familyAttractionData.map((ele, index) => (
                  <div key={ele.id || index} data-aos="zoom-in-right" className="slider-main">
                    <div className="slider-block">
                      <a href={ele.url}><img src={ele.image_url} alt="" /></a>
                      <div className="slider-content">
                        <h3>{ele.name.slice(0, 18)}...</h3>
                        <div className="stars">
                          <ul>
                            <li>
                              <Rate
                                allowHalf
                                defaultValue={ele.rating ? ele.rating : 4}
                                disabled
                              />
                            </li>
                          </ul>
                          <p>{`${ele.review_count} Reviews`}</p>
                          <div className="escape-room">
                            <img src="/images/location.svg" alt=""/>
                            <p>{ele.location?.city}</p>
                          </div>
                        </div>
                        <a className="card-footer-btn" href={ele.url}>{ele.categories[0]?.title.slice(0,10)}</a>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>

          </div>
          : ""
      }

    </Fragment>
  );
}

export default FamilyAttraction;

import React, { useEffect, useRef, useState } from 'react'
import AppPromo from '../../../restaurent/appPromo/AppPromo'
import BannerSec from '../../../restaurent/bannerSec/BannerSec'
import OwlCarousel from 'react-owl-carousel';
import { apiData } from '../../../../../actions';
import yelpnew from "../../../../../demo/yelpnew.png"
import { Rate } from 'antd';
import DataLoader from '../../../../../Utils/DataLoader';
import { useSelector } from 'react-redux';
const Brewery = () => {
	let BtnMore = useRef()
	const [deal, setDeal] = useState([])
	const [groupon, setGroupon] = useState([])
	const [loader, setLoader] = useState(false)
	const [loading, setLoading] = useState(false);
	const [popularDeal, setPopularDeal] = useState([])
	const place = useSelector(state => state.place)
	var settings = {
		items: 3,
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		nav: true,
		dots: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 499,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};
	const WineryYelp = async () => {
		let payload = {
			city: place,
			keyword: "Winery",
			limit: 12
		};
		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setLoader(true);
			setPopularDeal(apiRes?.data?.data?.businesses);
			setLoader(false);
		}
	}
	const GetDeals = async () => {
		setLoader(true);
		let payload = {
			city: place,
			keyword: "brewery",
			limit: 12
		};
		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setDeal(apiRes?.data?.data?.businesses);
			setLoader(false);
		}
	};
	const GetGroupon = async () => {
		setLoader(true);
		let payload = {
			city: place,
			keyword: "brewery"
		};
		let apiRes = await apiData.getGroupon(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setGroupon(apiRes?.data?.data?.data?.travelExperienceProducts?.resultList);
			setLoader(false);
		}
	};
	const [isLoading, setIsLoading] = useState(false);


	const loadMore = async () => {
		setIsLoading(true);
		setLoading(true);
		let payload = {
			city: place,
			keyword: "Winery",
			limit: 28
		};
		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setLoader(true);
			setPopularDeal(apiRes?.data?.data?.businesses);
			setLoader(false);
			setLoading(true);
			BtnMore.current.style.display = "none"
		}
		setTimeout(() => {
			// Your data loading logic here

			// Set isLoading to false when loading is complete
			setIsLoading(false);
		}, 1000); // Simulated loading time of 1 second
	}
	useEffect(() => {
		GetDeals();
		GetGroupon();
		WineryYelp();
	}, [place])
	return (
		<div>
			<BannerSec />
			{/* <AdverSec /> */}
			<div className="itemBox winery">
				<div className="rind-the-right-section comedy-sec">
					<div className="container">
						<div className="row">
							<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
								<div className="white-box-area inner rest-deals">
									<div className="row">
										<div className="col-lg-12 col-12">
											<section className="slider-restaurent">
												<div className="slider-main">
													<div className="slider-heading">
														<h3>Winery/Brewery Deals</h3>
													</div>
													<div id="ProductSlide-audio2">
														{groupon.length>0 && (
															<OwlCarousel {...settings}>
																{groupon.map((ele, index) => (
																	<div key={ele.id || index} data-aos="zoom-in-left" className="testimonial-block product">
																		<div className="slider-block">
																			<a href={ele?.link}>
																				<img src={ele?.imageLink} alt=''/>
																				<div className="slider-content">
																					<h3>{ele?.title.length >= 20 ? `${ele?.title.slice(0, 20)}...` : ele?.title}</h3>
																					<div className="stars">
																						<p>
																							<i className="fa fa-star" aria-hidden="true"></i>
																							<i className="fa fa-star" aria-hidden="true"></i>
																							<i className="fa fa-star" aria-hidden="true"></i>
																							<i className="fa fa-star" aria-hidden="true"></i>
																						</p>
																						<p>(339 Reviews)</p>
																					</div>
																				</div>
																				<div style={{ display: "flex", justifyContent: "space-between" }}>
																					<div>
																						<span style={{ textDecoration: "line-through", color: "#FE4D00" }}>${ele?.price?.amount}</span>{" "}
																						<span>${ele?.salePrice?.amount}</span>
																					</div>
																					<div>
																						<span className="fa fa-map-marker" style={{ color: "#FE4D00" }}></span>{" "}
																						<span>{ele?.locationName}</span>
																					</div>
																				</div>
																			</a>
																		</div>
																	</div>
																))}
															</OwlCarousel>
														)}
													</div>

												</div>
												<div className="slider-main  deals">
													<div className="slider-heading">
														<h3> Deals on <img src={yelpnew} height="24px" width="58px" alt=''/></h3>
													</div>
													<div id="ProductSlide-audio2">
													{deal.length>0 &&<OwlCarousel {...settings}>
															{
																deal && deal?.map((ele) =>
																	<div data-aos="zoom-in-right" className="testimonial-block product">
																		<div className="slider-block">
																			<a href={ele?.url}>
																				<img src={ele?.image_url} alt=''/></a>
																			<div className="slider-content">
																				<a href={ele?.url}><h3>{ele?.name}</h3></a>
																				<div className="stars">

																					<p><i className="fa fa-star" aria-hidden="true"></i>
																						<i className="fa fa-star" aria-hidden="true"></i>
																						<i className="fa fa-star" aria-hidden="true"></i>
																						<i className="fa fa-star" aria-hidden="true"></i></p>
																					<p>({ele?.review_count} reviews)</p>
																				</div>
																				<p>{ele?.location?.address1 ? ele?.location?.address1 : ele?.location?.display_address[0]}</p>
																			</div>
																			<div className="comedy-detail">
																				<ul>
																					<li><i className="fa fa-map-marker" aria-hidden="true"></i>{ele?.location?.display_address}</li>
																					<li><i className="fa fa-phone" aria-hidden="true"></i>{ele?.display_phone}</li>
																				</ul>
																			</div>
																		</div>
																	</div>
																)
															}
														</OwlCarousel>}
													</div>
												</div>
												<div className="testimonial-section products where_say_sec">
													<div className="slider-heading">
														<h3>{`Winery in ${place.replace(/\b\w/g, x => x.toUpperCase())}`}</h3>
													</div>
													{!loader ? (
														<div className="row">
															{popularDeal && popularDeal.map((ele, index) => (
																<div key={ele.id || index} className="col-12 col-sm-6 col-md-4 col-lg-3">
																	<a href={ele?.url}>
																		<div className="slider-block dig" data-aos="zoom-in-left">
																			<img src={ele?.image_url} alt=''/>
																			<div className="slider-content">
																				<h3>{ele?.name}</h3>
																			</div>
																			<div className="stars">
																				<ul>
																					<li>
																						<Rate allowHalf defaultValue={ele?.rating} disabled />
																					</li>
																				</ul>
																				<p>{ele?.review_count} Reviews</p>
																			</div>
																		</div>
																	</a>
																</div>
															))}
															<div className="view-tag clickable">
																<a ref={BtnMore} onClick={loadMore}>
																	{loading ? 'Loading...' : 'Load More'}
																</a>
															</div>
														</div>
													) : (
														<DataLoader />
													)}
												</div>

											</section>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<AppPromo />
		</div>
	)
}
export default Brewery
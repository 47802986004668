import React, { Fragment, useState, useEffect } from "react";

import { apiData } from "../../../../actions";
// import { UsePlaceAuth } from "../../../../AppContext/AppContext";
// import DataLoader from "../../../../Utils/DataLoader";
import { useSelector } from "react-redux";

function TourTravel() {
	const place = useSelector(state => state.place)
	const [tourData, setTourData] = useState("");
	const [loader, setLoader] = useState(false);

	const GetTourTravel = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.ToursTravel(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setTourData(apiRes?.data?.data);
			// console.log("TOUR", apiRes?.data?.data);
			setLoader(false);
		}
	};


	useEffect(() => {
		//script for Tours and travel
		document.getElementById("ProductSlide").innerHTML = ""
		let script = document.createElement('script');
		script.src = tourData.split('"')[1]
		document.getElementById("ProductSlide").appendChild(script);
		GetTourTravel();
	}, [place, tourData]);
	return (
		<Fragment>
			<div className="flight-sec Things-sec">
				<div className="container">
					<div className="row">
						<div className="col-12 col-md-12 col-lg-12">
							<div data-aos="zoom-in-right" className="slider-sec">
								<h2>Tours & Travel</h2>
								<p>Enjoy the scenic views of National Parks</p>
							</div>
						</div>
						<div className="col-12 col-md-12 col-lg-12">
							{/* <div className="small-blog"> */}
							<div  className="blog-main">
								<div data-aos="zoom-in-right"className="" id="ProductSlide">

								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	);
}
export default TourTravel;

import React from 'react'
import { useEffect } from 'react';

const HotelsHomeBanner = () => {

    useEffect(() => {
		let script = document.createElement('script');
    script.src="https://tp.media/content?trs=26480&shmarker=130544&show_hotels=false&powered_by=false&locale=en&currency=usd&searchUrl=travel.mysittivacations.com/hotels&color_button=%23196AE1&color_icons=%23196AE1&secondary=%23FFFFFF&dark=%23262626&light=%23FFFFFF&special=%23C4C4C4&color_focused=%23196ae1&border_radius=5&plain=true&promo_id=7873&campaign_id=101"
		document.getElementById("hotelSearch").appendChild(script)

	  }, [])
  return (
    <div>
    <div id="hotelSearch">
	  </div>
    </div>
  )
}
export default HotelsHomeBanner
import { Rate } from "antd";
import React, { Fragment } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function SixthSlider({ sliderData }) {
  var settings = {
    items: 3,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  return (
    <Fragment>
      <div className="col-lg-12 col-12">
        <div className="slider-restaurent">
          <div data-aos="zoom-in-left" className="slider-main">
            <div className="slider-heading">
              <h3>Breakfast</h3>
            </div>
            <div className="slide-restaurent3" id="slide-restaurent">
              <OwlCarousel {...settings}>
                {sliderData &&
                  sliderData?.map((ele) => {
                    return (
                      <div
                        data-aos="zoom-in-right"
                        
                        key={ele?.id}
                      >
                        <a href={ele?.url}>
                          <div className="slider-block">
                            <img src={ele?.image_url} alt="" />
                            <div className="slider-content restaurant">
                              <h3>{ele?.name}</h3>
                              <div className="stars">
                                <ul>
                                  <li>
                                    <Rate
                                      allowHalf
                                      defaultValue={ele?.rating}
                                      disabled
                                    />
                                  </li>
                                </ul>
                                <p>({ele?.review_count} Reviews)</p>
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    );
                  })}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default SixthSlider;

import React, { Fragment, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import { UsePlaceAuth } from "../../../../AppContext/AppContext";
import { useLocation, useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import { setPlace } from "../../../../Redux/Action/Action";

function BannerSec() {
	let [selectedplace, setSelectedPlace] =useState("")
	const place = useSelector(state=>state.place)
	const location=useLocation()
	let navigate=useNavigate()
    let dispatch=useDispatch()
	const { dealsTabActive } = UsePlaceAuth()
   const handleGooglePlaceSelect= (city)=>{
		
		// if(dealsTabActive=="All"){
		// 	navigate(`/deals/${(city.formatted_address.split(',')[0]).toLowerCase()}`)
		//   }
		if(location.pathname.includes("/deals/flights")){
			navigate(`/deals/flights/${(city?.formatted_address?.split(',')[0])?.toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/deals/hotels")){
			navigate(`/deals/hotels/${(city?.formatted_address?.split(',')[0])?.toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/deals/vacations")){
			navigate(`/deals/vacations/${(city?.formatted_address?.split(',')[0])?.toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/deals/tours")){
			navigate(`/deals/tours/${(city?.formatted_address?.split(',')[0])?.toLowerCase()}`)
		  }

		  else if(location.pathname.includes("/deals/cruises")){
			navigate(`/deals/cruises/${(city?.formatted_address?.split(',')[0])?.toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/deals/adventures")){
			navigate(`/deals/adventures/${(city?.formatted_address?.split(',')[0])?.toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/family")){
			  navigate(`/family/${(city?.formatted_address?.split(',')[0])?.toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/performing-arts")){
			  navigate(`/performing-arts/${(city.formatted_address.split(',')[0]).toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/brewery")){
			  navigate(`/brewery/${(city.formatted_address.split(',')[0]).toLowerCase()}`)
		  }
		  else if(location.pathname.includes("/comedy")){
			  navigate(`/comedy/${(city.formatted_address.split(',')[0]).toLowerCase()}`)
		  }
		  dispatch(setPlace((city.formatted_address.split(',')[0]).toLowerCase()));
   }
	const handleSearch=(e)=>{
		if(e.key.toLowerCase() == "enter"){
			e.preventDefault()
			 if(location.pathname.includes("/deals/flights")){
				navigate(`/deals/flights/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/deals/hotels")){
				navigate(`/deals/hotels/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/deals/vacations")){
				navigate(`/deals/vacations/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/deals/tours")){
				navigate(`/deals/tours/${e.target.value.toLowerCase()}`)
			  }
	
			  else if(location.pathname.includes("/deals/cruises")){
				navigate(`/deals/cruises/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/deals/adventures")){
				navigate(`/deals/adventures/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/family")){
				  navigate(`/family/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/performing-arts")){
				  navigate(`/performing-arts/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/brewery")){
				  navigate(`/brewery/${e.target.value.toLowerCase()}`)
			  }
			  else if(location.pathname.includes("/comedy")){
				  navigate(`/comedy/${e.target.value.toLowerCase()}`)
			  }
			setPlace();
			dispatch(setPlace(e.target.value.toLowerCase()));
		}
	  }
  return (
    <Fragment>
		<div className="banner-section rest-sec Restaurants">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-12 col-md-12 col-lg-12">
						<div className="tab-content" id="myTabContent">
							<div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
								<div className="select-form restaurent-sec">
									<form>
										<div className="choose-sec">
                                        <Autocomplete
										 className="form-control"
                                         apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
										 placeholder={place}
										 onPlaceSelected={handleGooglePlaceSelect}
										onKeyPress={handleSearch}
                                       />
										</div>
									</form>
								</div>
								{/* <div className="action-sec" >
									<a onClick={()=>{setPlace(selectedplace)}}>Search</a>
								</div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
    </Fragment>
  );
}
export default BannerSec;
import React, { useEffect, useRef, useState } from 'react'
// import { navigate } from 'gatsby';
// import BannerSec from '../BannerSec';
// import AdverSec from '../../adverSec/AdverSec';
import { UsePlaceAuth } from '../../../../../AppContext/AppContext';
import { apiData } from '../../../../../actions';
import DataLoader from '../../../../../Utils/DataLoader';
import { Rate } from 'antd';
// import axios from 'axios';
import Autocomplete from "react-google-autocomplete";
import Modal from 'react-bootstrap/Modal';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom/dist';
import { useDispatch, useSelector } from 'react-redux';
import { Hotel, setPlace } from '../../../../../Redux/Action/Action';
import AdrenalineRush from '../../../thingsToDo/adrenalineRush/AdrenalineRush';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
const Hotel_Go_to = () => {
	// let location = useLocation()
	let BtnMore = useRef()
	const [comedyDeal, setcomedyDeal] = useState([])
	const [familyDeal, setFamilyDeal] = useState([])
	const [escapeDeal, setEscapeDeal] = useState([])
	const [wineryDeal, setWineryDeal] = useState([])
	const [beautifulCityAll, setBeautifulCityAll] = useState("")
	const [beautifulCity, setBeautifulCity] = useState("")
	const [seeMoreModal, setSeeMoreModal] = useState(false);
	const [stay_hotel, setStay_Hotel] = useState([])
	const [viewLink, setViewLink] = useState("")
	const [eatPlace, setEatPlace] = useState([])
	const [tour, setTour] = useState([])
	const place = useSelector(state => state.place)
	const [loader, setLoader] = useState(false);
	const [Fine_dining, setFine_dining] = useState("");
	// const { active, setActive } = UsePlaceAuth()
	const dispatch = useDispatch();
	// let [selectedplace, setSelectedPlace] = useState("")
	const [selectedPlace, setSelectedPlace] = useState('');
	const { BannerPic, setBannerPic } = UsePlaceAuth()
	const [BannerAdv, setBannerAdv] = useState("");
	let navigate = useNavigate()

	const BannerImage = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.Ban_Image(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setBannerPic(apiRes?.data?.hits[0]?.largeImageURL);
			setLoader(false);
			// }
		}
	}

	var settings1 = {
		items: 4,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items:4
            }
        }
	};
	var settings2 = {
		items: 4,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items: 4
            }
        }
	};
	
	const options = {
		items: 4,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items: 4
            }
        }
	};
	var settings3 = {
		items: 4,
        loop: true,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        nav: true,
        dots: false,
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 2
            },
            1024: {
                items: 4
            }
        }
	};
	const Comedy = async () => {
		let payload = {
			city: place,
			keyword: "comedy",
			limit: 12
		};

		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setcomedyDeal(apiRes?.data?.data?.businesses);
		}
	}
	const Family_Top_Attractions = async () => {
		let payload = {
			city: place,
			keyword: "family attractions",
			limit: 12
		};
		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setFamilyDeal(apiRes?.data?.data?.businesses);
			// }
		}
	}
	const Escape_Room = async () => {
		setLoader(true);
		let payload = {
			city: place,
			keyword: "escape-20-room",
			limit: 12
		};
		if (place) {
			let apiRes = await apiData.getYelpDeals(payload);
			if (apiRes?.status >= 200 && apiRes?.status <= 399) {
				setEscapeDeal(apiRes?.data?.data?.businesses);
				setLoader(false);
			}
		}
	}
	const winery = async () => {
		let payload = {
			city: place,
			keyword: "Winery",
			limit: 12
		};
		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setWineryDeal(apiRes?.data?.data?.businesses);
			setLoader(false);
		}
	}
	const Beautiful_city_All = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.ToursTravel(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setBeautifulCityAll(apiRes?.data?.data);
			// console.log("Tours",apiRes?.data?.data)
		}
	}
	const Beautiful_city = async () => {
		let payload = {
			city: place,
		};
		let apiRes = await apiData.getBeautifulCity(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setBeautifulCity(apiRes?.data?.data);
			// console.log("Tours",apiRes?.data?.data)
		}
	}
	// const Beautiful_city = async () => {
	// 	setLoader(true);
	// 	let payload = {
	// 		city: place,
	// 	};
	// 	let apiRes = await apiData.beautifulCities(payload);
	// 	if (apiRes?.status >= 200 && apiRes?.status <= 399) {
	// 		setBeautifulCity(apiRes?.data?.data);
	// 		setLoader(false);
	// 	}
	// }
	const Specific_Hotel = async () => {
		let payload = {
			city: place,
		};
		let apiRes = await apiData.get_Specific_Hotel(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setStay_Hotel(apiRes?.data?.data[0].content.split('"')[1]);
			// }
		}
	}
	const getTourDeals = async () => {
		let payload = {
			city: place,
		};
		let apiRes = await apiData.get_Tour(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setTour(apiRes?.data?.data?.data?.travelExperienceProducts?.resultList);
		}
	}
	const GetHotelView = async () => {
		let payload = {
			city: place,
		};
		let apiRes = await apiData.get_Hotel_View(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setViewLink(apiRes?.data?.data);
		}
	}
	const GetEat = async () => {
		let payload = {
			city: place,
		};
		let apiRes = await apiData.get_to_eat(payload);
		// console.log("apiRes",apiRes);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setEatPlace(apiRes?.data?.data);
		}
	}

	const GetBanners = async () => {
		let payload = {
			city: place,
		};
		let apiRes = await apiData.getBanners(payload);
		// console.log("apiResasassasas>",apiRes);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setBannerAdv(apiRes?.data);
		}
	}
	const Get_Fine_Dining = async () => {
		let payload = {
			city: place,
			keyword: "fine dining",
			limit: 12
		};
		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setFine_dining(apiRes?.data?.data?.businesses);
			setLoader(false);
		}
	};
	useEffect(() => {
		if (place) {
			Beautiful_city_All()
			Beautiful_city()
		}

	}, [place])

	const executeLeadboxScript = (place) => {
		// alert(place);
		// Ensure that place is defined and has a valid value
		if (!place) {
			console.error("Invalid place:", place);
			return;
		}

		// Create script elements
		let script = document.createElement("script");
		script.id = "main50";
		script.src = "https://embed.lpcontent.net/leadboxes/current/embed.js";
		script.async = true;

		let script1 = document.createElement("script");
		script1.id = "main55";

		// Set the appropriate script based on the place
		let leadboxScript = "";
		switch (place) {
			case 'chicago':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('DdVYqhyrUsMGj372WLCkgJ',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'philadelphia':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('7dHSEChCByvyBmXwANXU6D',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'myrtle beach':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('2e3TzWy6tz2h8UBCPcnLih',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'los angeles':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('nssDq8vjb42dD5G2KQ4fFL',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'surinam':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('7u2TFKXGRziBUQZcao2z5a',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'falkland':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('RKFUMtkCBM5XhQvApR5wiZ',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'trinidad':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('Q6Ksy2r6J5YwYH6kiyL7PM',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'port of spain':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('Q6Ksy2r6J5YwYH6kiyL7PM',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'aruba':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('gW4XLuKqro8uZsTcE5qGTU',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case '78280 guyancourt':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('t8tJXE2EJs6uEbpDTWXFS7',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'brazil':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('RZXurokfptL9JYKdmwC5gF',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'argentina':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('L9nx4DDSrSgHoshhce5Qg3',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'peru':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('5t9iye2s39oZ7WekHVMRQX',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'chile chico':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('36TNYuw9Q5UUn2mQhUzbPN',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case '65000 colombia':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('oQz8Dpb8umtMkdqw2LKZKS',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'ecuador':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('d8fYNJvryX8w22tskLf54Y',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'venezuela':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('snKvZa2yVHrdatDwHNvYwB',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'uruguay':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('BZLhib8iUNfgpcTQJYfSNB',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'bolivia':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('iapbaB2z5ivuhjwwFXHUhZ',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'paraguay chico':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('ih3mm8nxzHPApZ6KRkP79Y',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'san antonio':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('8L6fYoA3d4GAW5KGG5D9PE',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'paris':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('XYGFSGRfbFHF3JixPpq7NA',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'boston':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('CByjSC2DE5rWazjY5bX7En',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'vienna':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('NA54x2bNR5Wxgh8gSMstfN',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'barcelona':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('oubq2DmkYt8WvdVuUfxUtW',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'i%CC%87stanbul':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('qzRKZhYaFcfQA4v7SZ5rhH',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'rome':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('BEMyVw8ohDaRCUPdyiyjAY',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'prague':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('SsCTS4SpawvgmdTTtuLYNa',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			case 'amsterdam':
				leadboxScript = "window.addEventListener('LPLeadboxesReady',function(){LPLeadboxes.addDelayedLeadbox('cQj5PjSKjKC5S5zGTtWDag',{delay:'0s',views:0,dontShowFor:'0d',domain:'mysittivacations.lpages.co'});})";
				break;
			default:
				console.error("Unsupported place:", place);
				return;
		}

		// Set the innerHTML of the script1 element
		script1.innerHTML = leadboxScript;

		// Append script elements to the body
		document.body.appendChild(script);
		document.body.appendChild(script1);

		// Cleanup function
		return () => {
			document.getElementById("main55")?.remove();
			document.querySelector(".lp-popup__iframe-wrapper")?.remove();
		};
	};
	useEffect(() => {
		// Define a function to execute the Leadbox script based on the place
		const executeScriptBasedOnPlace = () => {
			// Call executeLeadboxScript with a specific place
			const cleanupFunction = executeLeadboxScript(place);
			// Return a cleanup function to remove the script element when the component unmounts
			return cleanupFunction;
		};
		// Execute the script based on the current place
		const cleanupFunction = executeScriptBasedOnPlace();
		// Return a cleanup function to remove the script element when the component unmounts
		return cleanupFunction;
	}, [place]);
	const handlePlaceSelected = async (place) => {
		const selectedPlace = place.formatted_address.split(',')[0].toLowerCase();
		setSelectedPlace(selectedPlace);
		await dispatch(setPlace(selectedPlace));
		navigate(`/hotels/${selectedPlace}`);
	};
	const handleSearch = (e) => {
		if (e.key === "Enter") {
			e.preventDefault();
			navigate(`/hotels/${selectedPlace}`);
			dispatch(setPlace(selectedPlace));
		}
	};

	useEffect(() => {
		dispatch(Hotel())
		// document.getElementById("hotel_stay").innerHTML = ""
		// let script = document.createElement('script');
		setLoader(true)
		// script.src = stay_hotel
		// document.getElementById("hotel_stay").appendChild(script);
		// setLoader(false)
		document.getElementById("tour").innerHTML = "";
		let script = document?.createElement('script');
		script.src = beautifulCity?.split('"')[1];
		document.getElementById("tour")?.appendChild(script);
		Specific_Hotel()
		Comedy()
		Family_Top_Attractions()
		Escape_Room()
		winery()
		// Beautiful_city()
		getTourDeals()
		GetHotelView()
		GetEat()
		Get_Fine_Dining()
		BannerImage()
		GetBanners()
	}, [place, stay_hotel, beautifulCity])
	useEffect(() => {
		// setTimeout(function(){
		// 	// Display the div containing the class "bottomdiv"
		//      document.querySelector(".beautifulBtn").style.display="block"
		//  }, 8000);
	}, [])
	const [loadMoreLoader, setLoadMoreLoader] = useState(false);
	const [isLoader, setIsLoader] = useState(true);
	const loadMore = async () => {
		setLoadMoreLoader(true);
		setTimeout(function () {
			document.getElementById("tourModal").innerHTML = "";
			let script = document?.createElement('script');
			script.src = beautifulCityAll?.split('"')[1];
			document.getElementById("tourModal")?.appendChild(script);
			setLoadMoreLoader(false);
			setIsLoader(false);
		}, 500)
		// let payload = {
		//     city: place,
		//     keyword: "Vacations",
		//     limit: 50
		// };
		// let apiRes = await apiData.getRandomDeals(payload);
		// if (apiRes?.status >= 200 && apiRes?.status <= 399) {
		//     setDealsData(
		//         apiRes?.data?.data?.data?.travelExperienceProducts?.resultList
		//     );
		//     console.log("hello", apiRes?.data?.data)
		//     BtnMore.current.style.display = "none"
		// }
		// if (apiRes?.status >= 400 && apiRes?.status <= 500) {
		//     console.log("error");
		// }
	}

	return (
		<div>
			<div className="banner-section rest-sec Restaurants" style={{ backgroundImage: `url(${BannerPic})` }}>
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12">
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
									<div className="select-form restaurent-sec">
										<form>
											<div className="choose-sec">
												<Autocomplete
													className="form-control"
													apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
													placeholder={place.replace(/\b\w/g, x => x.toUpperCase())}
													onPlaceSelected={handlePlaceSelected}
													onKeyPress={handleSearch}
												/>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="adver-sec">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12">
							{BannerAdv?.data && <div dangerouslySetInnerHTML={{ __html: BannerAdv?.data }} />}

						</div>
					</div>
				</div>
			</div>
			<div className="flight-sec Things-sec audio">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div className="white-box-area inner rest-deals">
								<div className="row">
									<div className="col-lg-12 col-12">
										<section className="client-sec comedy">
											{/* <div className="testimonial-section products client-sec comedy family-sec where_say_sec">
												<div className="head-yelp">
													<h3>Where to stay</h3>
													<p>Experience the ultimate in affordable luxury, comfort, and pet-friendly accommodations in our hotel
														rooms, where elegance seamlessly combines with affordability to provide an exceptional stay at
														the lowest prices possible.</p>
												</div>
												{
													!loader ? "" : <div style={{ marginTop: "60vh" }}>
														<DataLoader />
													</div>
												}
												<div id="hotel_stay">
												</div>
												<div className="view-tag">
													<a href={viewLink}>View All</a>
												</div>
											</div> */}
											{/* <div className="flight-sec Things-sec audio">
												<div className="container">
													<div className="row"> */}
											<div className="col-12 col-md-12 col-lg-12">
												<div data-aos="zoom-in-right" className="slider-main">
													<div className="slider-sec">
														<h2>{`See Beautiful ${place?.replace(/\b\w/g, x => x?.toUpperCase())}`}</h2>
														<p>Get set for an awesome vacation! Explore sightseeing tours, bus excursions, and exclusive passes. Uncover hidden gems, create memorable moments, and elevate your trip. Time to make the most of it – book your tours now and let the adventure begin!</p>
													</div>
													<div id="tour">
													</div>
												</div>											
												{beautifulCity && beautifulCity && !loadMoreLoader ? (
													<div className="view-tag clickable">
														<a id="beautifulBtn" ref={BtnMore} onClick={() => { setSeeMoreModal(true); loadMore() }} className='m-4'>
															See All
														</a>
													</div>
												) : (
													<div className="view-tag clickable">Loading...</div>
												)}

												{/* <div className="slider-section discount-section stay-sec comn-div">
													<div className="container">
														<div className="row">
															<Slider {...settings}>
																{
																	beautifulCity && beautifulCity?.map((ele) => {
																		return (
																			<div className="col-12 col-sm-6 col-md-4 col-lg-4 mb-0">
																				<div className="discount-block">
																					<a href={ele?.link}>
																						<img src={ele?.image_link} />
																						<div className="discount-content">
																							<h3>{ele?.title}</h3>
																						</div>
																					</a>
																				</div>
																			</div>
																		)
																	})
																}
															</Slider>

														</div>
													</div>
												</div> */}
											</div>
											<AdrenalineRush />
											<div className="flight-sec exitment-sec tour-slider">
												<div className="slider-sec">
													<h3>{`${place?.replace(/\b\w/g, x => x?.toUpperCase())} Tours`}</h3>
													<p>Save big on thrilling tours and sightseeing adventures! We've found Groupon deals for helicopter rides, bus tours, and more. Experience the best while keeping your wallet happy.</p>

													<div className="slider-section discount-section stay-sec comn-div">
														<div className="container">
															<div className="row">
																<div className="col-12 col-md-12 col-lg-12">
																{tour.length>0 && <OwlCarousel {...settings1}>
																		{
																			tour && tour?.map((ele) => {
																				return (
																					// <div className="col-12 col-md-12 col-lg-12">
																					<div data-aos="zoom-in-right" className="slider-block">
																						<a href={ele?.link}>
																							<img src={ele?.imageLink} alt=''/>
																							<div className="slider-content">
																								<div className="slider-content-inner">
																									<h3>{ele?.title.slice(0,18)}..</h3>
																									<p>{ele?.description.slice(0, 42)}..</p>
																								</div>
																								<div className="discount-action hotels purple-bg city-price">
																									<div className="review-sec">
																										{/* <p><span>Price for 1 night</span></p> */}
																										<p><span className="old-price">${ele?.price?.amount}</span> <span className="new-price">${ele?.salePrice?.amount}</span> <span className="dis-percent">-{Math.round(((Number(ele?.salePrice?.amount) - Number(ele?.price?.amount)) / Number(ele?.salePrice?.amount)) * 100)}%</span></p>
																										<a className="siti-btn" >Book Now</a>
																									</div>
																									{/* <a className="siti-btn" >Book Now</a> */}
																								</div>
																							</div>
																						</a>
																					</div>
																					// </div>
																				)
																			})
																		}

																	</OwlCarousel>}
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="flight-sec exitment-sec tour-slider">
												<div className="slider-sec">
													<h3>{`Escape Room ${place?.replace(/\b\w/g, x => x?.toUpperCase())}`}</h3>
													<p>Unleash your inner detective in our thrilling escape room! Solve puzzles, decode clues, and unlock secrets in a race against time. Can you escape?</p>

													{
														!loader ? <div data-aos="zoom-in-left" className="slider-main">
															<div className="container">
																<div className="row">
																	{escapeDeal.length > 0 && <OwlCarousel {...settings3}>
																		{
																			escapeDeal && escapeDeal?.map((ele) => {
																				return (
																					<div className="col-12 col-md-12 col-lg-12">
																						<div className="slider-block">
																							<a href={ele?.url}>
																								<img src={ele?.image_url} alt='' />
																								<div className="slider-content">
																									<h3>{ele?.name.slice(0, 18)}...</h3>
																									<div className="escape-room">
																										<p className='time-formate'>
																											<img src="/images/location.svg" alt=''/>
																											{ele?.location?.city}
																										</p>


																										{/* <div className="star_rate"> */}
																										<Rate
																											allowHalf
																											defaultValue={ele?.rating ? ele?.rating : 4}
																											disabled
																										/>
																										<p style={{ cursor: "pointer" }} data-toggle="modal" data-target="#myModal-review" data-id="X8ZS-dgiMIJvhwf9SaDnjw">
																											{ele?.review_count}
																										</p>
																										{/* </div> */}
																									</div>
																									<div className="review-sec">
																										<a className="siti-btn full">
																											{ele?.categories ? ele?.categories[0]?.title : "Escape Games"}
																										</a>
																									</div>
																								</div>
																							</a>
																						</div>
																					</div>
																				)
																			})
																		}
																	</OwlCarousel>}
																</div>

															</div>
														</div>
															:
															<DataLoader />
													}
												</div>
											</div>
											<div className="flight-sec exitment-sec new1">
												<div className="slider-sec">
													<h3>{`Family Top Attractions in ${place?.replace(/\b\w/g, x => x?.toUpperCase())}`}</h3>

													<div data-aos="zoom-in-right" className="slider-main">
														<div className="container">
															<div className="row">
																{familyDeal.length>0 && <OwlCarousel {...settings1}>
																	{
																		familyDeal && familyDeal?.map((ele) => {
																			return (
																				<div data-aos="zoom-in-right" className="slider-block">
																					<div className="slider-block">
																						<img src={ele?.image_url} alt=''/>
																						<div className="slider-content">
																							<h3>{ele?.name}</h3>
																							<p>
																								<i className="fa fa-map-marker" aria-hidden="true"></i>
																								{ele?.location?.city}
																							</p>
																						</div>
																						<div className="escape-room">
																							<a href={ele?.url}>{ele?.categories[0]?.title}</a>
																							<div className="review-sec">
																								<span className="fa fa-star checked"></span>
																								<span className="fa fa-star checked"></span>
																								<span className="fa fa-star checked"></span>
																								<span className="fa fa-star checked"></span>
																								<span className="fa fa-star checked"></span>
																								<span className="reviews yelpuser-review" style={{ cursor: "pointer" }} data-toggle="modal" data-target="#myModal-review" data-id="X8ZS-dgiMIJvhwf9SaDnjw">
																									({ele?.review_count})
																								</span>
																							</div>
																						</div>
																					</div>
																				</div>
																			)
																		})
																	}
																</OwlCarousel>}
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="adver-sec">
												<div className="container">
													<div className="row">
														<div className="col-12 col-sm-12 col-md-12 col-lg-12">
															<a href="https://www.dpbolvw.net/click-8265264-13167950" target="_top">
																<img src="https://www.lduhtrp.net/image-8265264-13167950" width="728" height="90" alt="" border="0" /></a>
														</div>
													</div>
												</div>
											</div>

											<div className="flight-sec exitment-sec tour-slider new2">
												<div className="slider-sec">
													<h3>Winery</h3>
													<div className="container">
														<div className="row">

														{wineryDeal.length>0 &&	<OwlCarousel  {...options}>
																{
																	wineryDeal && wineryDeal?.map((ele) => {
																		return (
																			<div data-aos="zoom-in-right" className="slider-main">
																				<div key={ele?.id}>
																					<div className="slider-block">
																						<a href={ele?.url} target="_self">
																							<img src={ele?.image_url} alt='' />

																							<div className="slider-content winery-content">
																								<h3>{ele?.name.slice(0, 18)}...</h3>
																								{/* <p><i className="fa fa-map-marker" aria-hidden="true"></i> EXPAPP Venue - NY4</p> */}

																								<div className="escape-room">
																									<p><i className="fa fa-calendar" aria-hidden="true"></i> Sat,12/31/2022</p>
																									<p><i className="fa fa-clock-o" aria-hidden="true"></i> 07:30 PM</p>
																									<p><i className="fa fa-map-marker" aria-hidden="true">
																										</i>{ele?.location?.display_address[0]},
																										{/* {ele?.location?.display_address[1]} */}
																										</p>
																								</div>
																								<div className="review-sec">
																									<a  href="/#"className="siti-btn full">See Ticket </a>
																								</div>
																							</div>
																						</a>
																					</div>
																				</div>
																			</div>
																		)
																	})
																}
															</OwlCarousel>}
														</div>
													</div>
												</div>
											</div>

											<div className="flight-sec exitment-sec tour-slider new3">
												<div className="slider-sec">
													<h2>Comedy</h2>
												{comedyDeal.length>0 && <OwlCarousel  {...settings2}>
														{
															comedyDeal && comedyDeal?.map((ele) => {
																return (
																	<div data-aos="zoom-in-left" className="slider-main">
																		<div className="slider-block">
																			<a href={ele?.url}>
																				<img src={ele?.image_url} />
																				<div className="slider-content">
																					<h3>{ele?.name.slice(0,18)}</h3>
																					<div className="stars">
																						<ul>
																							<li>
																								<Rate
																									allowHalf
																									defaultValue={ele?.rating}
																									disabled
																								/>
																							</li>
																						</ul>
																						<p>({ele?.review_count} reviews)</p>
																					</div>
																					<p>Breweries</p>
																				</div>
																				<div className="escape-room">
																					<ul>
																						<li><i className="fa fa-map-marker" aria-hidden="true"></i>{ele?.location?.display_address[0]}</li>
																						<li><i className="fa fa-phone" aria-hidden="true"></i> {ele?.phone}</li>
																					</ul>
																				</div>
																			</a>
																		</div>
																	</div>
																)
															})
														}
													</OwlCarousel>}

												</div>
											</div>
											{/* <div className="flight-sec exitment-sec tour-slider">
												<div className="slider-sec">
													<h3>Where to eat</h3>
													<p>Experience a culinary adventure while on vacation, eating like the locals and exploring a diverse array of flavors. From vibrant street food to hidden gems with time-honored recipes, indulge in regional specialties that will leave a lasting impression.</p>
												</div>
												<Slider {...settings}>
													{eatPlace && eatPlace.map((ele, index) => (
														<div key={index} className="slider-block" data-aos="zoom-in-right">
															<div className="slider-block">
																<a href={ele.collection.url}>
																	<img src={ele.collection.image_url} alt={ele.collection.title} />
																	<div className="slider-content">
																		<h3>{ele.collection.title}</h3>
																	</div>
																</a>
															</div>
														</div>
													))}
												</Slider>
											</div> */}
											<div className="adver-sec">
												<div className="container">
													<div className="row">
														<div className="col-12 col-sm-12 col-md-12 col-lg-12">
															<a href="https://www.tkqlhce.com/click-8265264-14442529" target="_top">
																<img src="https://www.tqlkg.com/image-8265264-14442529" width="728" height="90" alt="" border="0" /></a>
														</div>
													</div>
												</div>
											</div>
											<div className="flight-sec exitment-sec tour-slider new4">
												<div className="slider-sec">
													<h2>Fine Dining</h2>
													{Fine_dining && <OwlCarousel {...settings1}>
														{Fine_dining &&
															Fine_dining?.map((ele) => {
																return (
																	<div data-aos="zoom-in-right" className="slider-main">
																		<div

																			className="slider-block"
																			key={ele?.id}
																		>
																			<a href={ele?.url}>

																				<img src={ele?.image_url} alt="" />
																				<div className="slider-content ">
																					<h3>{ele?.name.slice(0,15)}</h3>
																					<div className="escape-room">
																						<ul>
																							<li>
																								<Rate
																									allowHalf
																									defaultValue={ele?.rating}
																									disabled
																								/>
																							</li>
																						</ul>
																						<p>({ele?.review_count} Reviews)</p>
																					</div>
																				</div>

																			</a>
																		</div>
																	</div>
																);
															})}
													</OwlCarousel>}

												</div>
											</div>

										</section>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* modal section starts here */}
			<>
				<Modal
					show={seeMoreModal}
					onHide={() => setSeeMoreModal(false)}
					backdrop="static"
					size="xl"
					className='modal_xl see-pop'
					keyboard={false}
					animation={false} // Disable animation temporarily for testing
				>
					<Modal.Header closeButton>
					</Modal.Header>
					<Modal.Body id="tourModal">
						{/* Place your modal content here */}
					</Modal.Body>
				</Modal>
			</>
		</div>
	)
}
export default Hotel_Go_to
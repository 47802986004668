import axios from "axios";
async function restaurantDealsSearch(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}restaurant-deals-search?city=${payload?.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response?.data?.data;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function cityIATA(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}flight-iata-code?city=${payload?.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response?.data?.data;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function restaurantDealsFilterSearch(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}restaurant-deals-filter-search?city=${payload?.city}&keyword=${payload?.keyword}&limit=${payload.limit}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response?.data?.data;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function blog(payload) {
  let getResponse;
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}get-wp-blogs-with-filter?query=${payload.key}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function audioTour(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-izi-travel-apis?city=${payload?.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}

async function getEscapeRoom(payload) {
  let getResponse;
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}get-escape-room/?city=${payload?.city}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getFamilyAttraction(payload) {
  let getResponse;
  await axios

    .get(
      `${process.env.REACT_APP_BASE_URL}get-family-top-attraction/?city=${payload?.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getWinery(payload) {
  let getResponse;
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}get-winery/?city=${payload?.city}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getComedy(payload) {
  let getResponse;
  await axios
    .get(`${process.env.REACT_APP_BASE_URL}get-comedy/?city=${payload.city}`, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getToursTravel(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-tours-travel/?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function ToursTravel(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-sightseeing-tours/?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getBeautifulCity(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-sightseeing-tours-for-home/?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getAdrenalineRush(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-adrenaline-rush/?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getDigInto(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-dig-into/?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}

async function getWhereToStay(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-where-to-stay/?city=${payload?.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        // console.log("avijit",response);
        getResponse = response;
      },
      (error) => {
        console.log(error);

      }
    );
  return getResponse;
}
async function getYelpDeals(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-yelp-deals?city=${payload?.city}&keyword=${payload?.keyword}&limit=${payload?.limit}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log("err",error);
      }
    );
  return getResponse;
}
async function getGroupon(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-groupon?city=${payload?.city} ${payload?.keyword}&limit=12`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function musicFilterSearch(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}category-filter?city=${payload?.city}&keyword=${payload?.keyword}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response?.data;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getRandomDeals(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-random-deals/?flag=${payload?.keyword}&city=${payload?.city}&limit=${payload.limit}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getDealsHome() {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}all-inclusive-deals?city=Caribbean&keyword=resort&limit=80`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getShadowAudio(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-izi-model-data?main_uiid=${payload.audioId}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function RelaxingCities() {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-beach`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getRestaurantDeals(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}all-inclusive-deals?city=${payload?.city}&keyword=restaurant-deals&limit=20`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function beautifulCities(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}beautiful-cities?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getCarRental(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}car-rental?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function get_Specific_Hotel(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}specific-hotels?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function get_Tour(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}all-tours?city==${payload.city}&keyword=tours&limit=10`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function get_Hotel_View(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}hotel-url?city==${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function get_to_eat(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}zomato?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        // console.log("response",response);
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function get_Sports(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}ticketmaster?city=${payload.city}&keyword=${payload.Keyword}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function Sports_Tickets_Things_To_Do(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}yelp_sports-ticket?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function Sports_Side_Bar(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}ticketmasters-sidebar?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function single_Blog(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-wp-single-blog-with-slug/${payload.slug}`,
      {
        headers: {
          "Content-Type": "applicati0on/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}

async function inclusiveDeals(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-guide-tours?city=${payload.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}

async function coolFlightDeals() {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}cool-flight-deals`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function Ban_Image(payload) {
  let getResponse;
  await axios
    .get(
      `https://pixabay.com/api/?key=18114906-9ead1ec1eb416a800cf9c119b&q=${payload.city?payload.city:"chicago"}&image_type=photo`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        getResponse = response;
      },
      (error) => {
        console.log(error);
      }
    );
  return getResponse;
}
async function getBanners(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-big-bus-tours/?city=${payload?.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        // console.log("dsdsdsdsdsdsdsds>",response);
        getResponse = response;
      },
      (error) => {
        console.log(error);

      }
    );
  return getResponse;
}
async function getWigoTour(payload) {
  let getResponse;
  await axios
    .get(
      `${process.env.REACT_APP_BASE_URL}get-wigo-list/?city=${payload?.city}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(
      function (response) {
        // console.log("dsdsdsdsdsdsdsds>",response);
        getResponse = response;
      },
      (error) => {
        console.log(error);

      }
    );
  return getResponse;
}


// eslint-disable-next-line
export default {
  restaurantDealsSearch,
  restaurantDealsFilterSearch,
  blog,
  audioTour,
  getEscapeRoom,
  getFamilyAttraction,
  getWinery,
  getComedy,
  getToursTravel,
  getAdrenalineRush,
  getDigInto,
  getWhereToStay,
  getYelpDeals,
  getGroupon,
  musicFilterSearch,
  getRandomDeals,
  getShadowAudio,
  RelaxingCities,
  beautifulCities,
  getCarRental,
  get_Specific_Hotel,
  get_Tour,
  get_Hotel_View,
  get_to_eat,
  get_Sports,
  Sports_Tickets_Things_To_Do,
  Sports_Side_Bar,
  single_Blog,
  inclusiveDeals,
  coolFlightDeals,
  getRestaurantDeals,
  getDealsHome,
  Ban_Image,
  cityIATA,
  ToursTravel,
  getBeautifulCity,
  getBanners,
  getWigoTour
};

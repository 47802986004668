import React, { Fragment, useState, useEffect } from "react";
// import moment from "moment";
// import InfiniteScroll from "react-infinite-scroller";
import { apiData } from "../../../actions";
// import DataLoader from "../../../Utils/DataLoader";
// import { UsePlaceAuth } from "../../../AppContext/AppContext";
import location from "../../../demo/lcation.png"
import Calendar from "../../../demo/calender.png";
import { Link } from "react-router-dom";
// import useDebounce from "../../../Utils/useDebounceHook";
import { _Blog_ } from "../../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";
function Blog() {
  const [blogs, setBlogs] = useState([]);
  const [keyword, setKeyword] = useState("");
  // const debouncedValue = useDebounce(keyword, 300);
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 6;
  const [hasMoreItems, sethasMoreItems] = useState(true);
  const [records, setrecords] = useState(itemsPerPage);
  // const { active, setActive } = UsePlaceAuth()
  const place = useSelector(state => state.place)
  const dispatch = useDispatch();
  // const showItems = (blogs) => {
  //   var items = [];
  //   for (var i = 0; i < records; i++) {
  //     items.push(<> {blogs[i]}</>);
  //   }
  //   return items;
  // };
  let loadMore = async () => {
    let payload = {
      key: keyword
    }
    let apiRes = await apiData.blog(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setLoader(true);
      setBlogs(apiRes?.data?.data);
      setLoader(false);
      if (records >= apiRes?.data?.data.length) {
        sethasMoreItems(false);
      } else {
        setTimeout(() => {
          setrecords(records + itemsPerPage);
        }, 2000);
      }
    }
    else {
      console.log("server error")
    }

  };
  useEffect(() => {
    dispatch(_Blog_())
    loadMore();
  }, [keyword]);
  return (
    <Fragment>
      <div className="flight-sec Things-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div data-aos="zoom-in-right" className="slider-sec">
                  <div className="slider-header">
                    <h3>Our Featured Blogs</h3>
                    <div className="searcher-sec">
                    <input className="form-control"
                      placeholder="Search ..." onChange={(e) => { setKeyword(e.target.value) }} />
                  </div>
                  </div>
                  <p >
                      Find fun places to see and things to do experience
                      the art, museums, music, zoos
                    </p>
                  <div>
                  </div>
                   <div className="col-12 col-md-12 col-lg-12">
				          	<div className="small-blog">           
                  {blogs && blogs.map((ele) => (
                    <div data-aos="zoom-in-right" className="blog-main" key={ele?.id}>
                      <Link to={`/blog/${ele?.slug}`}>
                          <div className="right_blog_img">
                          <img src={ele?.image} alt="blogpost" />
                          </div>
                              <div className="blog-content">
                              <div className="date-sec">
                            <p >
                              <img className="me-1" src={Calendar} alt="calendar" />{" "}
                              {new Intl.DateTimeFormat('en', { day: "numeric", month: "long", year: "numeric" }).format(new Date(ele?.date))}
                            </p>
                            <p>
                              <img className="me-1"  src={location} alt="location" /> {ele?.name?.slice(0, 10)}
                            </p>
                          </div>

                          {/* <h3>{ele?.post_title}</h3> */}
                          <p className="mb-0" dangerouslySetInnerHTML={{ __html: ele?.post_content.slice(0,50) }}></p>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>            
              </div>  
              </div>  
            </div>
          </div>
        </div>
      </div>
      
    </Fragment>
  );
}
export default Blog;

import React, { Fragment } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import play_image from "../../../../demo/play.png"
import { useState } from "react";
import { apiData } from "../../../../actions";
import { Modal } from "react-bootstrap";

function SecondSlider({ tourData }) {
  const [play, setPlay] = useState(false)
  const [loader, setLoader] = useState(false);
  const [audioData, setAudioData] = useState({});
  var settings = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
      0: {
          items: 2
      },
      768: {
          items: 2
      },
      1024: {
          items: 4
      }
  }
  };
  const handleClick = async (id) => {
    setPlay(true)
    setLoader(true);
    let payload = {
      audioId: id,
    };
    if (id) {
      let apiRes = await apiData.getShadowAudio(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setAudioData(apiRes?.data?.data);
        setLoader(false);
      }
    }
  }
  return (
    <Fragment>
      <div className="testimonial-section products">
        <div className="slider-sec">
          <h3>{tourData?.title}</h3>
          {/* </div> */}
          {/* <div className="owl-carousel owl-theme" id="ProductSlide-audio2"> */}
          {tourData?.imagesData && <OwlCarousel {...settings}>
            {tourData?.imagesData &&
              tourData?.imagesData?.map((ele) => {
                return (
                  <div
                    data-aos="zoom-in-right"
                    className="testimonial-block product"
                    key={ele?.main_uiid}
                  >
                    <div className="discount-block audio">
                      <img src={ele?.image_url} alt="" />
                      <img
                        onClick={() => { handleClick(ele?.main_uiid) }}
                        className="play"
                        src={play_image}
                        alt="play"
                      />
                      <div className="discount-content things">
                        <h3>
                          {ele?.tour_title.split(" ").length > 3
                            ? `${ele?.tour_title
                              .split(" ")
                              .slice(0, 3)
                              .join(" ")}...`
                            : ele?.tour_title.split(" ").slice(0, 3).join(" ")}
                        </h3>
                      </div>
                    </div>
                  </div>
                );
              })}
          </OwlCarousel>}
        </div>
      </div>
      <Modal
        show={play}
        onHide={() => setPlay(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{audioData && audioData?.title}</h3>
            <audio src={audioData && audioData?.audio_url} controls />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: audioData && audioData?.description }}>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
export default SecondSlider;

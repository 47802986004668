import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const AboutusModal=({aboutUsModal,SetAboutUsModal})=>{

  return (
    <>
      <Modal
        show={aboutUsModal}
        onHide={()=>SetAboutUsModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> About us</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Welcome to MySittiVacations – more than just an online travel platform, we’re your ultimate travel companion! Our vision is to revolutionize vacation planning by offering a seamless, one-click experience for flights, accommodations, and activities, all in one place. 

 

But we don’t stop there! Our commitment extends to being the central hub for discovering local music and events. Imagine a live, up-to-date city events page that provides valuable insights into local nightlife. We aim to redefine the way you explore destinations, making your travel journey effortless and memorable. 

 

At MySittiVacations, we’re dedicated to providing not only the lowest prices but also the best local experiences. Simplify your travel planning with our one-stop shop for hotels, flights, and car rentals. Sit back, relax, and let us handle the arrangements for your perfect vacation.  
        </Modal.Body>
      </Modal>
    </>
  );
}
export default AboutusModal
// reducers.js
const initialState = {
active:"",
place:""
};
const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "place":
         return { ...state, place: action.payload };
      case "Hotel":
          return { ...state,active: state.active = "1" };
      case "Flight":
          return { ...state, active: state.active="2" };
      case "Car Rentals":
          return { ...state, active: state.active="3" };
      case "Restaurants":
          return { ...state, active: state.active="4" };
      case "Things to do":
          return { ...state, active: state.active="5" };
      case "Audio Tour":
          return { ...state, active: state.active="6" };
      case "Deals":
          return { ...state, active: state.active="7" };
      case "Blog":
          return { ...state, active: state.active ="8"};
      default:
          return state;
  }
};

export default appReducer;

import React, { Fragment, useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import FirstSlider from "./firstSlider/FirstSlider";
import SecondSlider from "./secondSlider/SecondSlider";
import ThirdSlider from "./thirdSlider/ThirdSlider";
import FourthSlider from "./fourthSlider/FourthSlider";
import FifthSlider from "./fifthSlider/FifthSlider";
import { apiData } from "../../../actions";
import DataLoader from "../../../Utils/DataLoader";
import { UsePlaceAuth } from "../../../AppContext/AppContext";
import { useNavigate } from "react-router-dom/dist";
import { Audio_Tour, setPlace } from "../../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";

function AudioTour() {
  const dispatch = useDispatch();
  // const { setActive } = UsePlaceAuth()
  const place = useSelector(state => state.place)
  const [tourData, setTourData] = useState("");
  const [inclusiveScript, setInclusiveScript] = useState("");
  const [loader, setLoader] = useState(false);
  let [selectedplace, setSelectedPlace] = useState("")
  let navigate = useNavigate()

  const GetTour = async () => {
    setLoader(true);
    let payload = {
      city: place,
    };
    if (place) {
      let apiRes = await apiData.audioTour(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {

        setTourData(apiRes?.data?.data);
        setLoader(false);
      }
    }

  };
  const GetWigoTourScript = async () => {
    let payload = {
      city: place,
    };
    setLoader(true)
    let apiRes = await apiData.getWigoTour(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      // console.log("-------->", apiRes);
      setInclusiveScript(
        apiRes?.data?.data[0]?.content?.split('"')[1]
      );

      setLoader(false);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      console.log("error");
    }
  };

  const handleSearch = (e) => {
    if (e.key.toLowerCase() == "enter") {
      e.preventDefault()
      navigate(`/audio-tour/${e.target.value.toLowerCase()}`)

      setPlace(e.target.value.toLowerCase());
    }
  }
  useEffect(() => {
    document.getElementById("InclusiveScript").innerHTML = ""
    let script = document.createElement('script');
    setLoader(true)
    script.src = inclusiveScript
    // .replace(/(?<=items=)\d+/,"20")
    document.getElementById("InclusiveScript").appendChild(script);
    setLoader(false)




    GetWigoTourScript();
    GetTour();
    dispatch(Audio_Tour())
  }, [place]);
  return (
    <Fragment>
      <div className="banner-section rest-sec audio">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
                  <div className="select-form restaurent-sec">
                    <form>
                      <div className="choose-sec">
                        <Autocomplete
                          className="form-control"
                          apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
                          placeholder={place.replace(/\b\w/g, x => x.toUpperCase())}
                          onPlaceSelected={(city) => {
                            navigate(`/audio-tour/${city.formatted_address.split(',')[0].toLowerCase()}`)
                            setSelectedPlace((city.formatted_address.split(',')[0]).toLowerCase());
                            dispatch(setPlace(city.formatted_address.split(',')[0].toLowerCase()));
                          }}
                          onKeyPress={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                  {/* <div className="action-sec" >
									<a onClick={()=>{setPlace(selectedplace)}}>Search</a>
								</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div  className="flight-sec exitment-sec tour-slider">
      <div data-aos="zoom-in-right" className="slider-main">
        <div id="InclusiveScript">

        </div>
        </div>
        <div className="container">
          <div className="row">
            {!loader ? (
              <section className="client-sec comedy">
                <FirstSlider tourData={tourData[0]} />
                <SecondSlider tourData={tourData[1]} />
                <ThirdSlider tourData={tourData[2]} />
                <FourthSlider tourData={tourData[3]} />
                <FifthSlider tourData={tourData[4]} />
              </section>
            ) : (
              <DataLoader />
            )}
          </div>
        </div>
      </div>


    </Fragment>
  );
}
export default AudioTour;

import React, { Fragment, useState, useEffect } from "react";
import { apiData } from "../../../../actions";
// import { UsePlaceAuth } from "../../../../AppContext/AppContext";
// import location from "../../../../demo/lcation.png"
import { useSelector } from "react-redux";
import OwlCarousel from 'react-owl-carousel';
import { Rate } from "antd";
function AdrenalineRush() {
  const place = useSelector(state => state.place)

  const options = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    slidesToScroll:3,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 4
      }
    }
  };
  const [getAdrenalineRush, setAdrenalineRush] = useState([]);
  const [loader, setLoader] = useState(false);

  const GetAdrenalineRush = async () => {
    setLoader(true);
    let payload = {
      city: place,
    };
    let apiRes = await apiData.getAdrenalineRush(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setAdrenalineRush(apiRes?.data?.adrenalineRush);
      setLoader(false);
    }
  };

  useEffect(() => {
    GetAdrenalineRush();
  }, [place]);

  return (
    <Fragment>

      <div className="flight-sec exitment-sec">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-12">
              <div className="slider-sec">
                <h2>Excitement-seeking</h2>
                <p>Welcome to the world of excitement-seeking! Whether it's the thrill of the unknown, heart-pounding adventures, or electrifying experiences, get ready to satisfy your craving for excitement and stimulation.</p>
                {getAdrenalineRush.length > 0 && (
                  <OwlCarousel {...options}>
                    {getAdrenalineRush.map((ele, index) => (
                      <div key={index} data-aos="zoom-in-right" className="slider-main">
                        <div className="slider-block">
                          <a href={ele.link}><img src={ele.image_link} alt={ele.image_name} /></a>
                          <div className="slider-content">
                            <h3>{ele.title.slice(0, 22)}..</h3>
                            <div className="escape-room">
                              <Rate
                                allowHalf
                                defaultValue={ele?.rating ? ele?.rating : 4}
                                disabled
                              />
                              {/* <p style={{ cursor: "pointer" }} data-toggle="modal" data-target="#myModal-review" data-id="X8ZS-dgiMIJvhwf9SaDnjw">
                                (786)
                              </p> */}
                              <p className="time-formate ms-2"><img src="/images/location.svg" alt=""/>
                                {`${place.replace(/\b\w/g, x => x.toUpperCase())}`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </OwlCarousel>
                )}

              </div>
            </div>
          </div>
        </div>
      </div>



    </Fragment>
  );
}

export default AdrenalineRush;

import React, { Fragment, useState, useEffect } from "react";

import { Rate } from "antd";

import OwlCarousel from 'react-owl-carousel';
import { apiData } from "../../../../actions";
// import { UsePlaceAuth } from "../../../../AppContext/AppContext";
// import DataLoader from "../../../../Utils/DataLoader";
// import location from "../../../../demo/lcation.png"
import { useSelector } from "react-redux";

function EscapeRoom() {
  const place = useSelector(state=>state.place)
  const [loader, setLoader] = useState(false);
  const [escapeRoomData, setEscapeRoomData] = useState([]);
  var settings = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 4
        }
    }
  };
 

  const GetEscapeRoom = async () => {
    let payload = {
      city: place,
    };
    let apiRes = await apiData.getEscapeRoom(payload);

    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setLoader(true);
      setEscapeRoomData(apiRes?.data?.escape?.businesses);
      setLoader(false);
    }

  };

  useEffect(() => {
    GetEscapeRoom();
  }, [place]);

  return (
    <Fragment>
      {
        !loader ?
          <div className="flight-sec exitment-sec tour-slider">
            <div className="slider-sec">
              <h3>{`Escape Room ${place?.replace(/\b\w/g, x => x?.toUpperCase())}`}</h3>
              <p>Unleash your inner detective in our thrilling escape room! Solve puzzles, decode clues, and unlock secrets in a race against time. Can you escape?</p>
           {escapeRoomData.length>0 && <OwlCarousel {...settings}>
                {escapeRoomData &&
                  escapeRoomData?.map((ele) => {
                    return (
                      <div
                        data-aos="zoom-in-left"
                        className="slider-main"
                        key={ele?.id}
                      >
                        <div className="slider-block">
                          <img src={ele?.image_url} alt="" />
                          <div className="slider-content">
                            <h3>{ele?.name.slice(0,20)}...</h3>
                            <div className="escape-room">
                                <p>
                                  <Rate
                                    allowHalf
                                    defaultValue={ele?.rating}
                                    disabled
                                  />
                                </p>
                            
                              <p>({ele?.review_count})</p>
                              {/* <img src="/images/location.svg" alt=""/>
                                <p className="time-formate">{ele?.location?.city}</p>                          */}
                              <div className="location-esc">
                                <img src="/images/location.svg" alt=""/>
                                <p className="time-formate">{ele?.location?.city}</p>
                              </div>
                            </div>
                            <div className="review-sec">
                            <a className="siti-btn full" href={ele?.url}>
                              {ele?.categories[0]?.title}

                            </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </OwlCarousel>}
            </div>
          </div>
          : ""
      }

    </Fragment>
  );
}

export default EscapeRoom;

import React from "react";
import { Outlet } from "react-router-dom";

import Header from "../header/Header";
import Footer from "../footer/Footer";
import { Helmet } from "react-helmet";

function Layout() {
  return (
    <React.Fragment>
      <Helmet>
      <meta
      name="description"
      content="Looking for the best deals travel packages? My Sitti Vacations offers the best & popular vacation packages, flight vacation deals, vacation discounts, and more."
    />
      </Helmet>
      <Header />
      <Outlet />
      <Footer />
    </React.Fragment>
  );
}

export default Layout;

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const PrivacyPolicyModal=({privacyModal,SetPrivacyModal})=>{


  return (
    <>
      <Modal
        show={privacyModal}
        onHide={()=>SetPrivacyModal(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Privacy policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>


<p><span style={{lineHeight:"1.6em"}}>Your privacy is important to us. Mysittivacations.com collects certain information when you visit our site </span><a href="https://www.mysittivacations.com" style={{lineHeight:"1.6em"}}>https://www.mysittivacations.com</a><span style={{lineHeight:"1.6em"}}> (&ldquo;Mysittivacations&rdquo;). Mysittivacations is committed to safeguarding and preserving your privacy when you visit our site; register as a Member or communicate electronically with us. You can visit our site without becoming a Member. However, you cannot access all the features/utilities and use the service provided by us.</span></p>

<p>This Privacy Policy, together with our Terms and Conditions, provides an explanation as to what happens to any personal data that you provide to us, or that we collect from you when you visit our website/register as a Member. The terms used in this Privacy Policy will have the same meaning as defined in the Terms and Conditions document.</p>

<p><strong>Collection of Information</strong></p>

<p>&nbsp;</p>

<p>Mysittivacations is the sole owner of information collected on this website. We will not share, sell, or trade this information to third parties in ways different from what is disclosed in this Privacy Policy. When you register/use our website you agree to the collection and processing of the following information about you:</p>

<ol>
  <li>
  <p>Details of your visits to our website and the resources that you access, including, but not limited to, traffic data, location data, web logs and other communication data.</p>
  </li>
  <li>
  <p>Personal information that you provide on our website when you register for use.</p>
  </li>
  <li>
  <p>Information provided to us when you communicate with us for any reason.</p>
  </li>
</ol>

<p style={{marginLeft:"1.27cm"}}><br />
&nbsp;</p>

<p><strong>Use of Cookies</strong></p>

<p>&nbsp;</p>

<p><br />
&nbsp;</p>

<p>Mysittivacations may gather information about your general internet use by using cookies. Where used, these cookies which are text files, are downloaded to your computer automatically. These cookies are stored on the hard disk of your computer. As cookies contain information that is transferred to your computer&#39;s hard drive, these cookies do not operate once you logoff completely from Mysittivacations.</p>

<p>Mysittivacations may also gather information such as IP address, internal session ID, user&#39;s domain, type of Internet browser, domain of Web site. Such information will not identify you personally: it is statistical data about our visitors and their use of our site. This statistical data does not identify any personal details whatsoever. It is used only to provide statistical information regarding the use of our website, to our advertisers.</p>

<p>All computers have the ability to decline cookies. This can be done by activating the setting on your browser which enables you to decline the cookies. Please note that should you choose to decline cookies, you may be unable to access particular features of our website.</p>

<p>Our advertisers may also use cookies, over which Mysittivacations has no control. Such cookies (if used) would be downloaded once you click on advertisement banners on our website or visit their sites.</p>

<p><strong>Use of Your Information</strong></p>

<p>&nbsp;</p>

<p>The information that Mysittivacations collect and store relating to you is primarily used to enable us to improve our website and the services provided to you. In addition, Mysittivacations may use the information for the following purposes:</p>

<ol>
  <li>
  <p>To provide you with information requested from us, relating to our services. To provide information on other services/products which Mysittivacations feels may be of interest to you, where you have consented to receive such information.</p>
  </li>
  <li>
  <p>To meet our contractual commitments to you.</p>
  </li>
  <li>
  <p>To notify you about any changes to our website, such as improvements or changes that may affect our service.</p>
  </li>
</ol>

<p>Further, Mysittivacations may use your personal information/data, or permit selected third parties to use your data, so that you can be provided with information about unrelated products and services which Mysittivacations consider may be of interest to you. Mysittivacations or third parties may contact you about these goods and services by any of the methods that you consented at the time your information was collected.</p>

<p>If you are a new customer, Mysittivacations will allow third parties to contact you only when you have provided consent and only by those means you provided consent for.</p>

<p>If you do not want us to use your personal data, you will have the opportunity to withhold your consent to this when you provide your details to us on Mysittivacations when you use this site. You can withdraw your consent for disclosure of your personal information to third parties at any time subsequently also.</p>

<p>Please be advised that Mysittivacations do not reveal information about identifiable individuals to our advertisers but Mysittivacations may, on occasion, provide them with aggregate statistical information about our visitors.</p>

<p><strong>Storing and Security of Your Personal Data </strong></p>

<p>&nbsp;</p>

<p>Mysittivacations may transfer data that is collected from you to our server for processing and storing. By submitting your personal data, you agree to this transfer, storing or processing. Mysittivacations will take all reasonable steps to make sure that your data is treated securely without any loss, misuse or alteration of information and in agreement with this Privacy Policy.</p>

<p>The data collected from you by Mysittivacations is stored in MySQL database with reasonable and appropriate physical, electronic and managerial procedures to safeguard and secure the information. While you can terminate your account at any time, your information may remain stored in archive on our servers even after the deletion or termination of your account. However, we will not sell, transfer, or use the information relating to the terminated account of an individual in any way.</p>

<p>The transmission of information via the internet is not completely secure and therefore Mysittivacations cannot guarantee the security of data sent to us electronically and transmission of such data is therefore entirely at your own risk.</p>

<p><strong>Disclosing Your Information</strong></p>

<p>&nbsp;</p>

<p>Where applicable, Mysittivacations may disclose your personal information to any member of our group or third parties in order to carry out the services agreed upon.</p>

<p>Mysittivacations may also disclose your personal information to third parties:</p>

<ol>
  <li>
  <p>Where Mysittivacations sells any or all of its business and/or our assets to a third party.</p>
  </li>
  <li>
  <p>Where Mysittivacations is legally required to disclose your information.</p>
  </li>
  <li>
  <p>To assist fraud protection and minimise credit risk.</p>
  </li>
</ol>

<p>You agree to the above disclosures while registering as a Member.</p>

<p><br />
&nbsp;</p>

<p><strong>Third Party Links</strong></p>

<p>&nbsp;</p>

<p>You will find links to our advertisers&rsquo; websites on our website. They are provided for your convenience. These websites have their own privacy policies which are applicable when you visit those sites. Please read and understand their policies when you visit those sites. Mysittivacations does not accept any responsibility or liability for their policies whatsoever, or the consequences of visiting such sites through the links provided in our site, as Mysittivacations has no control over them.</p>

<p><br />
&nbsp;</p>

<p><strong>Modification of Information</strong></p>

<p>&nbsp;</p>

<p>You have the right to access your personal information that Mysittivacations hold and correct or update the same using the feature - &quot;My Account/Edit profile&quot; provided by Mysittivacations. However, you cannot modify the e-mail address and the country and region. You can delete the e-mail address only by terminating the Membership. Should you wish to receive details that Mysittivacations hold about you, please contact us by sending an email or by using the contact details below.</p>

<p><strong>Communication Policy </strong></p>

<p>&nbsp;</p>

<p>Mysittivacations gives you the option of receiving notifications, offers and Newsletters via emails. You may at any time exercise your option not to receive such notifications.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p><strong>Notification of Changes</strong></p>

<p><br />
&nbsp;</p>

<p>Mysittivacations do update/amend this Policy from time to time. We may notify you by posting a prominent announcement on our web pages. However, please do check this Policy regularly to ensure you are happy with the changes.</p>

<p><strong>Contacting Us</strong></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>Mysittivacations welcomes any queries, comments or requests you may have regarding this Privacy Policy. Please do not hesitate to contact us by sending an email to: <a href="mailto:info@mysittivacations.com">info@mysittivacations.com</a>.
&nbsp;</p>

<p>&nbsp;</p>


        </Modal.Body>
      </Modal>
    </>
  );
}
export default PrivacyPolicyModal
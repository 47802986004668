import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const TermModal=({termModal,SetTermModal})=>{
  return (
    <>
      <Modal
        show={termModal}
        onHide={()=>SetTermModal(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Terms and conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p><span style={{lineHeight:"1.6em"}}>This website </span><a href="https://www.mysittivacations.com" style={{lineHeight: "1.6em"}}><strong>www.mysittivacations.com</strong></a><span style={{lineHeight:"1.6em"}}> (the &ldquo;</span><strong style={{lineHeight:"1.6em"}}>Site</strong><span style={{lineHeight:"1.6em"}}>&rdquo;) and the services are owned and made available by mysittivacations (together with its affiliates, successors, and assigns hereinafter referred to as &ldquo;Our&rdquo;, &ldquo;We,&rdquo; or &ldquo;Us&rdquo;).</span></p>

<p>&nbsp;</p>

<p>Please read the Terms and Conditions carefully before you start using the Site.&nbsp;<strong>By using the Website, or b</strong><strong>y placing an order for any service through our site</strong>, you warrant that you are legally capable of entering into binding contracts, and <strong>you accept and agree to be bound and abide by these Terms and Conditions, and our&nbsp;<a href="https://www.mysittivacations.com/policy.php">Privacy Policy</a>&nbsp;and <a href="https://www.mysittivacations.com/copyright.php">Copyright Policy</a>, incorporated herein by reference; and </strong>our specific Terms and Conditions applicable to certain services you may opt to use (all of which are incorporated herein and collectively referred to as the &ldquo;Terms and Conditions&rdquo;). These Terms and Conditions govern your access to and use of our website, including any content, functionality and services offered on or through our websites, and set forth the entire agreement between you and mysittivacations and supersede any prior Terms and Conditions.&nbsp;If you are representing a Company or any other entity, you represent that you have the necessary authority to bind such entity.</p>

<p>&nbsp;</p>

<p>We reserve the right to amend these Terms and Conditions, discontinue or terminate any service or the Site from time to time in our sole discretion. All changes are effective immediately when we post them and apply to all access to and use of the Website thereafter. Your continued use of the Website following the posting of revised Terms and Conditions means that you accept and agree to the changes. You are expected to check this page each time you access the Website so you are aware of any changes, as they are binding on you. Please note that you will be subject to the Terms and Conditions in force at the time that you ordered a service from us, unless any change to these Terms and Conditions is required to be made by law or governmental authority (in which case it will apply to orders previously placed by you).</p>

<p>&nbsp;</p>

<p>Persons who are 18 years of age or older may only use our Site as some Site content may be harmful to or inappropriate for minors. Accordingly, you agree that you are 18 years of age or older or possess legal parental or guardian consent, and are fully able and competent to enter into the terms, conditions, representations and warranties set forth in these Terms and Conditions; otherwise, please exit the Site.</p>

<p>&nbsp;</p>

<p>IF YOU DO NOT AGREE TO THESE TERMS AND CONDITIONS, DO NOT USE THE SITE OR SERVICES.</p>

<p>&nbsp;</p>

<p><strong>1. Agreement</strong></p>

<p>&nbsp;</p>

<p>You agree that the information that you have given us or give to us in the future is correct and is in no way misleading, offensive or against any Country&rsquo;s Law. You will maintain secrecy of your pass words and will be solely responsible for any unauthorized access to your account by any person.</p>

<p>&nbsp;</p>

<p><strong>2. Services</strong></p>

<p>&nbsp;</p>

<p>The Website contents including copyrights, logos, slogans, trademarks, and service marks are proprietary rights of mysittivacations or its respective licensors. All User Content are subject to the licenses granted in these Terms and Conditions. Any unauthorised use or theft of these contents will lead to infringement and consequent legal action.</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p><strong>3. License</strong></p>

<p>&nbsp;</p>

<p>Subject to these Terms and Conditions and only to the extent expressly permitted by the functionality of the Site and Services, you are hereby granted a limited, non-exclusive, revocable, non-assignable, and non-transferable right to use the Site and Services and the Content. The Site and Site Content is for your personal and non-commercial use only except with respect to certain Services that provide for or facilitate commercial activities as per the Terms and Conditions of such Services.</p>

<p>&nbsp;</p>

<p>Except as expressly permitted herein (i) no Site Content may be modified, copied, distributed, framed, reproduced, republished, downloaded, displayed, posted, transmitted, sold, or otherwise used in any form or by any means, in whole or in part, and (ii) nothing herein should be construed as granting any license or right to use any Site Content, including trademarks, service marks, logos, of the Site or third parties, or any software or code relating to the Site or Services.</p>

<p>&nbsp;</p>

<p><strong>4. Restrictions</strong></p>

<p>&nbsp;</p>

<ol>
  <li>
  <p>You can use the Site Downloads only for personal, non-commercial use;</p>
  </li>
  <li>
  <p>By accessing Site Downloads you do not secure any commercial or promotional use rights in the Site Downloads;</p>
  </li>
  <li>
  <p>You may not transfer or license your rights in any Site Download to any other person;</p>
  </li>
  <li>
  <p>You cannot alter, modify, or otherwise create any derivative work of any Site Downloads;</p>
  </li>
  <li>
  <p>You may copy and store Site Downloads in connection with an unlimited number of devices as reasonably necessary but you cannot use a Site Download as a phone ringtone or ring-back tone. Any use of a Site Download other than in accordance with these Terms may constitute copyright infringement.</p>
  </li>
</ol>

<p>&nbsp;</p>

<p><strong>5. User Conduct/Lawful Use</strong></p>

<p>&nbsp;</p>

<p>You agree that use of the Site is subject to all applicable International, National, Federal, state, and local laws and regulations. You agree to abide by these laws and is solely responsible for the content of its communications through the Service. You agree to use Site for lawful purposes only.</p>

<p>&nbsp;</p>

<p>You agree:</p>

<ol>
  <li>
  <p>to comply with all applicable laws;</p>
  </li>
  <li>
  <p>not to use the Site for illegal purposes;</p>
  </li>
  <li>
  <p>not to interfere or disrupt networks connected to the Site.</p>
  </li>
  <li>
  <p>to comply with all regulations, policies and procedures of networks connected to the Site;</p>
  </li>
  <li>
  <p>not to resell or transfer your access to the service to any third party;</p>
  </li>
  <li>
  <p>not to restrict any other visitor from using the service;</p>
  </li>
  <li>
  <p>to act responsibly, treat other website users with respect and not violate their rights;</p>
  </li>
  <li>
  <p>not to modify, adapt, sublicense, translate, sell, reverse engineer, decompile, or disassemble any portion of the Service or any part of the Site;</p>
  </li>
  <li>
  <p>not to harvest or collect information about Users of the service without their express consent;</p>
  </li>
  <li>
  <p>not to solicit personal information from any one under the age of 18;</p>
  </li>
  <li>
  <p>not to upload contents that contain restricted or password only access pages or hidden pages or images (those not linked to or from another accessible page);</p>
  </li>
  <li>
  <p>not to display unauthorized commercial advertisement.</p>
  </li>
</ol>

<p>&nbsp;</p>

<p>You agree that &ndash; you will not distribute or facilitate distribution of any content, including but not limited to photos, videos, images, sounds that: (1) is unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another&#39;s privacy, tortuous, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals) or provide links to adult contents elsewhere, or otherwise violates mysittivacations&#39;s rules or policies; (2) victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability; (3) infringes on any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party; (4) constitutes unauthorized or unsolicited advertising; (5) impersonates any person or entity, including any of our employees or representatives.</p>

<p>&nbsp;</p>

<p>Mysittivacations does not monitor the contents uploaded by the users and depend on users flagging (reporting) content that breaches Terms and Conditions. However, if you are found to be involved in any of the afore-mentioned acts of using the Site/Service unlawfully, or if it is brought to our notice by another user or the copyright owner, you will be advised to remove the materials immediately. Failure to immediately remove the content will result in a cancellation of your account and forfeiture of any fees provided to mysittivacations. Mysittivacations also reserves the right to remove such prohibited material without warning or notification to the User.</p>

<p>&nbsp;</p>

<p>If you encounter such prohibited material you waive your right to any damages related to such exposure. Such material should be immediately reported to <a href="mailto:info@mysittivacations.com">info@mysittivacations.com</a>.</p>

<p>&nbsp;</p>

<p>In case of any violation of these terms, Mysittivacations reserves the right to seek all remedies available by law and in equity for such violations.</p>

<p>&nbsp;</p>

<p><strong>Mysittivacations Services</strong></p>

<p>Mysittivacations provides the following Servives:</p>

<ul>
  <li>
  <p>Digital Distribution</p>
  </li>
  <li>
  <p>FanReach</p>
  </li>
  <li>
  <p>MobileApp</p>
  </li>
  <li>
  <p>Promote It</p>
  </li>
  <li>
  <p>Digital eCommerce</p>
  </li>
  <li>
  <p>Site Builder</p>
  </li>
</ul>

<p>&nbsp;</p>

<p>In addition to specific Terms and Conditions applicable to each of the above Services, these General Terms and Conditions are applicable to all the above Services. In case of any conflict of Terms, specific Terms applicable to a Service will overrule these General Terms and Conditions.</p>

<p>&nbsp;</p>

<p><strong>6. Registration</strong></p>

<p>&nbsp;</p>

<p>In order to access the Site or Service, you will have to register and create an account/become a member. You must submit a valid email address; select a username and password during the registration process. You can also register using some of the social networking sites like Facebook, Twitter, YouTube, etc. (&ldquo;Third-Party Account&rdquo;). When you register using a Third Party Account, you allow us to access, store, and use such Registration Information relating to your Third-Party Account. We reserve the right to accept or reject your user registration.</p>

<p>&nbsp;</p>

<p>By electing to use these services; (a) you warrant that all information you submit while registering is current, true and accurate; (b) you agree to update this information; (c) You will not create more than one personal profile: (d) If we disable your account, you will not create another one without our permission; (e).you agree not to use the Site/Service for any unlawful purpose.</p>

<p>You agree to notify Mysittivacations immediately if you suspect any unauthorized use of your account. You are solely responsible for the security of your registration information, and any and all use of your account by any third party.</p>

<p>&nbsp;</p>

<p>You also represent that you are not a person barred from receiving the Services under the laws of applicable jurisdiction. If you provide any information that is untrue, inaccurate, not current or incomplete, or if Mysittivacations has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, We have the right to suspend or terminate your account and refuse any and all current or future use of the Site/Services (or any portion thereof).</p>

<p>&nbsp;</p>

<p><strong>7. </strong><strong>User Content/Submissions </strong></p>

<p>&nbsp;</p>

<p>As a registered User, you may submit music, sound recordings, musical works, compositions, videos, photographs, pictures, graphics, names, likenesses, images, copyrights, trademarks, service marks, logos, biographical information, chat, messages, files, code, data, metadata, text, software, links, your Registration Information, content from your Third-Party Account, and any other information or materials to mysittivacations, collectively referred to as &quot;User Content&rdquo; or &ldquo;User Submissions.&quot; You must submit User Submissions to the Site and Services in compliance with our submission specifications. As a user, you understand and accept that you are solely responsible for your own User Submissions and the consequences of posting, displaying, distributing or publishing them.</p>

<p>&nbsp;</p>

<p>Mysittivacations hosts, shares, and/or publishes such User Submissions. Mysittivacations does not guarantee any confidentiality with respect to any User submissions despite it not being published. Mysittivacations is responsible for any User Submissions that may be lost or unrecoverable through your use of the Site or Services.</p>

<p>&nbsp;</p>

<p>You authorize Mysittivacations to use all patent, trademark, trade secret, copyright or other proprietary rights in and to any and all User Submissions to enable inclusion and use of the User Submissions in the manner contemplated by these Terms and Conditions.</p>

<p>&nbsp;</p>

<p>By posting User Submissions to mysittivacations, you hereby grant Mysittivacations (and its successors) a worldwide, non-exclusive, royalty-free, perpetual, irrevocable, fully sub-licensable (including any moral right) and transferable license to use, modify, adapt, publish, translate, create derivative works from, reproduce, distribute, derive revenue or other remuneration from, communicate to the public, perform and display the contents of such Submissions (in whole or in part), and/or incorporate it in other works throughout the world in any form, media, or technology now known or later developed, for the full term of any rights that may exist in such content. You grant Mysittivacations the right to use the name that you submit in connection with such Submissions, if we choose. You also permit any subscriber to access, display, view, store, and reproduce such content for personal use, as permitted by the Site and under these Terms and Conditions of Use.</p>

<p>&nbsp;</p>

<p>You also hereby grant Mysittivacations the right to use User Submissions for promoting the Mysittivacations site (and derivative works thereof) through any media channels. You also hereby grant Mysittivacations and each user of mysittivacations a non-exclusive license to access your User Submissions through Mysittivacations and to use, reproduce, distribute and display such User Submissions as permitted through the functionality of Mysittivacations and under these Terms, in social networks, websites, blogs etc, as a promotional activity. You understand that these functionalities may include downloading your User Submission to a computer, Iphone, iPad, android tablet or other device. The above licenses granted by you terminate within a commercially reasonable time after you terminate your account/membership.</p>

<p>&nbsp;</p>

<p>Mysittivacations reserves the right to decide whether a User Submission is appropriate and complies with these Terms for violations other than copyright infringement. Mysittivacations may advise User to remove such material immediately or may at any time, without prior notice and at its sole discretion remove such User Submissions and/or terminate a User&#39;s access for uploading such material which is in violation of these Terms. You acknowledge that Mysittivacations may preserve your Submissions and may also disclose them if required to do so by law or in the good faith belief that such preservation or disclosure is reasonably necessary to: (a) comply with a legal process, (b) enforce the Terms of Use, or (c) respond to any claims that the content of any Submission violates the rights of third parties.</p>

<p>&nbsp;</p>

<p>Without limiting the foregoing, Mysittivacations reserves the right to use Submissions as it deems appropriate, including, without limitation, deleting, editing, modifying, rejecting, or refusing to post it. Mysittivacations is under no obligation to offer you any payment for Submissions or the opportunity to edit, delete or otherwise modify Content once it has been submitted to mysittivacations, without the permission of mysittivacations.</p>

<p>&nbsp;</p>

<p>Mysittivacations does not endorse any Submissions that may appear on the Site and makes no warranties, express or implied, as to their accuracy and reliability. Mysittivacations expressly disclaims any and all liability in connection with any and all Submissions.</p>

<p>&nbsp;</p>

<p>Representations and Warranties</p>

<p>By submitting User Content you acknowledge, represent, and warrant that:</p>

<p>(i) you either own or has obtained necessary license from the owner, to control all necessary rights in and to the User Content, and you have the full right and authority to grant the rights, licenses, and permissions in these Terms and Conditions;</p>

<p>(ii) the exercise of the rights granted by you herein does not violate any laws or infringe upon the rights of any person or entity, including copyright, trademark, privacy, publicity, or other personal or proprietary rights;</p>

<p>(iii) the exercise of the rights granted by you herein shall be free of any royalty or other types of payment (except as expressly provided in connection with any Service);</p>

<p>(iv) to the extent that you receive payments from us in connection with any Service, you acknowledge and agree that any such payments to you are inclusive of all payments otherwise due to any person or entity who/which owns the rights</p>

<p>&nbsp;</p>

<p><strong>8. Termination and Consequences</strong></p>

<p>&nbsp;</p>

<p>You agree that we may terminate your right to use our Site/Services if the information that you provided initially to register for our Services or subsequently modified, contains false or misleading information, or conceals or omits any information we would likely consider material.</p>

<p>&nbsp;</p>

<p>Any attempt to undermine or cause harm to Mysittivacations server or its customers, is strictly prohibited and will be subject to automatic account/membership termination. This includes Spamming, transmission of - malware, viruses, trojan horses; or by linking to sites and files that contain or distribute malware, viruses and trojan horses.</p>

<p>&nbsp;</p>

<p>Mysittivacations may terminate user&rsquo;s account/membership and forfeit any fee at any time without prior notice, if you are in breach of the terms of this Agreement including payment of fee. Mysittivacations will be the sole arbiter as to what constitutes a violation of the Agreement.</p>

<p>&nbsp;</p>

<p>You may terminate these Terms and Conditions with respect to any Service at any time, provided you have paid all applicable Service Fees.</p>

<p>&nbsp;</p>

<p>Consequent to termination of your account for any reason, we may delete any of your User Content, information, or other data from the Site or Services, and we have no obligation to maintain copies of any deleted information.</p>

<p>&nbsp;</p>

<p>Any provisions of these Terms and Conditions that by their nature should continue after termination of your use of the Site or any Services shall survive the expiration or termination of these Terms and Conditions.</p>

<p>&nbsp;</p>

<p><strong>9. Suspension of services</strong></p>

<p>&nbsp;</p>

<p>We reserve the right to suspend all or any of the services that we have given to you, including those that have been already paid for should any of these terms not be met.</p>

<p>&nbsp;</p>

<p><strong>10. Payments and Refund Policy</strong></p>

<p>&nbsp;</p>

<p><strong>(i)</strong> Certain functionality of the Site may expressly permit you to access, use, download or copy Site Content (&ldquo;Site Downloads&rdquo;). Some of these Services are available for free, and some Services are available only for a price (&ldquo;Priced Downloads&rdquo;). Applicable fee for some of the Services and prices for Priced Downloads are subject to changes. The total price for a Priced Download will include applicable sales tax in effect at the time you purchase of the Priced Download. You are responsible for payment of all applicable &ldquo;Service Fees&rdquo; (based on the fee schedule displayed on the Site) incurred by your account. &ldquo;Service Fees&rdquo; includes all applicable fees for the Services availed by you which may include monthly subscription fees, annual subscription fees, one-time product purchase fees, recurring fees, annual renewal fees, third-party fees, applicable taxes, charge-back fees, resubmission fees, late payment fees, takedown fees, change request fees, and reinstatement fees.</p>

<p>&nbsp;</p>

<p><strong>(ii) Payment and Charges</strong></p>

<p>Payment under this agreement will be as stipulated under the respective Service and as agreed; and can be made in any of the following modes: Paypal, Credit Card, Debit card, etc. You are required to notify us in case of change in your payment method. If we are unable to receive payment through the payment method indicated by you, we may discontinue the Services to you.</p>

<p>&nbsp;</p>

<p><a name="_GoBack"></a> We will charge you for any administration costs and loss of work that have occurred for any payment not being met for whatever reason or that has not been honored by your bank or any method of payment that has been made by you to us. Dishonor of checks, ACH reversals, and credit card chargeback shall be subject to a fine of $100, payable immediately upon notice by the Mysittivacations to you.</p>

<p>&nbsp;</p>

<p>Should this debt have to be recovered in the Court additional charges including all legal and recovery costs will be charged to you. As a shareholder, director, partner or owner of your business you also give us your personal guarantee that all monies owed to us if your business is unable to meet them will be paid by you.</p>

<p>&nbsp;</p>

<p><strong>(iii) Refund Policy</strong></p>

<p>&nbsp;</p>

<p>Priced Downloads may be downloaded only once. No refund will be made once the transaction is complete and download has been done. Prices for Priced Downloads may change at any time. No refund will be made in the event of subsequent price reduction or promotional offering. We will only refund the money paid by you or replace the download if a Priced Download becomes unavailable following a transaction but prior to download, or if technical problems prevent or unreasonably delay delivery of your Priced Download.</p>

<p>&nbsp;</p>

<p>We provide opportunities for limited periods to try some of the Services before you purchase them. If at any time in the service rendering process you are not satisfied, you may opt to cancel the Service. No payment need be made if you cancel the Service before completion of the Trial Period. However, no refund will be made if you cancel the Service after the Trial Period. But, you may choose not to pay any further payments. In general we will not refund payments on services rendered. If a customer contravenes Mysittivacations terms of service, a refund will not be issued in the event of cancellation.</p>

<p>&nbsp;</p>

<p>Refunds are only possible if (a) we are unable to render the services to you; (b) there is any malfunction of Product/Service and the same is reported when the Service is active and this will be determined by our sole discretion: (c) there is any error in billing.</p>

<p>&nbsp;</p>

<p>You are not entitled to a refund of any of our services if you:</p>

<p>Find our charges to be higher compared to others and you can&#39;t afford the services;</p>

<p>Choose the wrong specifications for the service;</p>

<p>Knew about the particular fault prior to purchase; and/or</p>

<p>Were responsible for causing the fault.</p>

<p>&nbsp;</p>

<p><strong>11. Copyright</strong></p>

<p>&nbsp;</p>

<p>If you are a copyright holder and believe that any of the Site content infringes your work, you should notify us. Instructions regarding the required content of this notice are available in our <a href="https://www.mysittivacations.com/copyright.php">Copyright Policy</a>. Upon our receipt of your notice reporting alleged copyright infringement as per the requirements set forth in the Digital Millennium Copyright Act (&ldquo;DMCA&rdquo;), we will respond promptly to remove, or disable access to, the material in accordance with the DMCA.</p>

<p>&nbsp;</p>

<p><strong>12. Privacy</strong></p>

<p>&nbsp;</p>

<p>Mysittivacations respects privacy of its clients. Mysittivacations will not monitor, edit, or disclose any personal information about your accounts, including hosted content, without your prior consent, unless Mysittivacations has a good faith belief that such action is necessary to: (a) comply with legal process or other legal requirements of any relevant authority; (b) protect and defend the rights or property of mysittivacations; (c) enforce the terms of this Agreement or these Terms and Conditions; or (d) protect the interests of users of mysittivacations&rsquo;s Services. You can see as to how your information is collected and used by Mysittivacations by using the link <a href="https://www.mysittivacations.com/policy.php">www.mysittivacations.com/policy</a>.</p>

<p>&nbsp;</p>

<p><strong>13. Confidentiality and Proprietary rights</strong></p>

<p>&nbsp;</p>

<p>&quot;Confidential Information&quot; herein shall mean any and all technical or business information, including third party information, furnished in connection with this Agreement, in whatever tangible form or medium, or disclosed by you to Mysittivacations (including, but not limited to, product/service specifications, computer files and programs, marketing plans, financial data and personnel statistics).</p>

<p>&nbsp;</p>

<p>Notwithstanding the termination, expiration or cancellation of this Agreement Mysittivacations agrees to treat such Confidential Information as confidential for a period of 5 years from the date of receipt of same unless otherwise agreed to in writing by you.</p>

<p>&nbsp;</p>

<p>You agree to keep the details of the Agreement and any such information which you may learn about mysittivacations, our business, technology, practices and our clientele, strictly confidential and agree not to disclose it to any third party.</p>

<p>&nbsp;</p>

<p>Upon termination, cancellation or expiration of this Agreement for any reason or upon request of the disclosing party, all Confidential Information, together with any copies of same as may be authorized herein, shall be returned.</p>

<p>&nbsp;</p>

<p>You agree that if Mysittivacations suffers any loss or there is a failure from your end to meet any of these obligations above, you agree to pay us directly and immediately for any proven losses that may occur.</p>

<p>&nbsp;</p>

<p>The obligations imposed in this Article shall not apply to any information that: (a) is known to the public (through no act or omission of Mysittivacations in violation of this Agreement); (b) is lawfully acquired by Mysittivacations from an independent source having no obligation to maintain the confidentiality of such information; (c) was known to Mysittivacations prior to its disclosure under this Agreement; (d) was independently developed by the mysittivacations; or (e) is required to be disclosed by governmental or judicial order.</p>

<p>&nbsp;</p>

<p>The requirements of use and confidentiality set forth herein shall survive the expiration, termination or cancellation of this Agreement.</p>

<p>&nbsp;</p>

<p><strong>14. Limitation of liability</strong></p>

<p>&nbsp;</p>

<p>(a) IN NO EVENT MYSITTIVACATIONS SHALL BE LIABLE TO YOU FOR ANY LOSS OR DAMAGE [INCLUDING BUT NOT LIMITED TO LOSS OF DATA, LOSS OF PROFITS/SALES, WEBSITE DOWNTIME, LOSS OF BUSINESS OR STAFF OR MANAGEMENT TIME INCURRED] CAUSED OR ARISING DIRECTLY OR INDIRECTLY OUT OF MYSITTIVACATIONS&rsquo;S SERVICES PROVIDED UNDER THIS AGREEMENT [EXCEPT TO THE EXTENT TO WHICH IT IS UNLAWFUL TO EXCLUDE SUCH LIABILITY UNDER US LAW]. (b) NOTWITHSTANDING THE GENERALITY OF (a) Above, (i) MYSITTIVACATIONS EXPRESSLY EXCLUDES LIABILITY FOR ANY INDIRECT, SPECIAL, CONSEQUENTIAL OR INCIDENTAL LOSS OR DAMAGE WHICH MAY ARISE IN RESPECT OF THE SERVICES TO BE PROVIDED UNDER THIS AGREEMENT. (ii) IN THE EVENT THAT ANY EXCLUSION CONTAINED IN THIS AGREEMENT SHALL BE HELD TO BE INVALID FOR ANY REASON AND MYSITTIVACATIONS BECOMES LIABLE FOR LOSS OR DAMAGE THAT MAY LAWFULLY BE LIMITED, SUCH LIABILITY INCLUDING ATTORNEY AND EXPERT FEE AND LEGAL COSTS SHALL BE LIMITED TO THE WHOLE AMOUNT PAID BY YOU DURING THE PRECEDING INITIAL PERIOD OR RENEWAL PERIOD, AS THE CASE MAY BE. OR WHICHEVER IS LESSER; (iii) MYSITTIVACATIONS DOES NOT EXCLUDE LIABILITY FOR DEATH OR PERSONAL INJURY TO THE EXTENT ONLY THAT THE SAME ARISES AS A RESULT OF THE NEGLIGENCE OF THE MYSITTIVACATIONS, ITS EMPLOYEES, AGENTS OR AUTHORIZED REPRESENTATIVES.</p>

<p>We will not be liable to make any payment to you in any events that are beyond our control such as our computers, technology in general, telecommunication equipment including satellite and internet suppliers.</p>

<p>&nbsp;</p>

<p>We are liable to you to cover the contract and services provided to you as long as none of the other points in this contract are broken by you.</p>

<p>&nbsp;</p>

<p><strong>15. Indemnity</strong></p>

<p>&nbsp;</p>

<p>You agree to use all our services and Products at your own risk and agree to defend, indemnify, save and hold Mysittivacations harmless from any and all demands, liabilities, losses, costs and claims, including reasonable Attorney&rsquo;s fees asserted against mysittivacations, its agents, its customers, officers and employees, that may arise or result from any services provided or performed or agreed to be performed or any product sold by you, your agent, employees or assigns. You agree to defend, indemnify and hold harmless Mysittivacations against liabilities arising out of: (a) any injury to person or property caused by any products sold or otherwise distributed in connection with Mysittivacations server; (b) any material supplied by you infringing or allegedly infringing on the proprietary rights of a third party; (c) copyright infringement; (d) any defective products sold to customers from Mysittivacations server and (e) claims arising from omission to inform or implement the updates needed for the site.</p>

<p>&nbsp;</p>

<p>Without limitation, you agree to reimburse us and any of our Associates and other Related Parties on demand for any payments made in resolution of any liability or claim that is subject to indemnification under this Section. We shall promptly notify you of any such claim, and you may assume control of the defense of such claim with counsel subject to our reasonable approval, and we shall have the right in all events to participate in the defense thereof.</p>

<p>&nbsp;</p>

<p>You agree that this indemnification extends to all aspects of the project, including but not limited to any specific Service.</p>

<p>&nbsp;</p>

<p><strong>16. Warranty Disclaimer</strong></p>

<p>&nbsp;</p>

<p>THE SITE, THE SERVICES, AND THE CONTENT THEREON ARE PROVIDED ON AN &ldquo;AS-IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS.<strong> </strong>MYSITTIVACATIONS MAKES NO WARRANTIES WITH REGARD TO THE SITE AND ITS CONTENTS. MYSITTIVACATIONS CANNOT GUARANTEE THE FUNCTIONALITY OR OPERATIONS OF THEIR WEBSITE OR THAT IT WILL BE UNINTERRUPTED OR ERROR FREE, NOR DOES IT WARRANT THAT THE CONTENTS ARE CURRENT, ACCURATE OR COMPLETE.</p>

<p>&nbsp;</p>

<p>Mysittivacations is not responsible for any content published on your website that infringes any law, copyright or compliance. You are solely responsible for the security, confidentiality and integrity of all the content and messages received, transmitted through or stored on the web/server hosting service.</p>

<p>&nbsp;</p>

<p>Mysittivacations will not be responsible for any damages or losses your business may suffer. Mysittivacations makes no warranties of any kind, express or implied for services it provides. Mysittivacations disclaims any warranty or merchantability or fitness for a particular purpose. This includes loss of data resulting from delays, non-deliveries, wrong delivery, and any and all services interruptions caused by Mysittivacations and its employees. Mysittivacations reserves the right to revise its policies at any time.</p>

<p>&nbsp;</p>

<p>Your interactions with organizations and/or individuals found on or through the Site or Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You agree that Mysittivacations shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings. Any dispute between you and a third party shall be resolved by you. Mysittivacations is under no obligation to intervene and resolve such disputes.</p>

<p>&nbsp;</p>

<p>The Site and the Services may be temporarily unavailable from time to time for maintenance or other reasons. We are not responsible for any problems or technical malfunction of any communication network or equipments, including injury or damage to any person&#39;s computer related to or resulting from participating or downloading materials in connection with the Site or the Services. Under no circumstances will Mysittivacations be responsible for any loss or damage, including personal injury or death, resulting from anyone&#39;s use of the Services, the Site, or any Site Content.</p>

<p>&nbsp;</p>

<p>By using the Site and/or Services, you hereby release Mysittivacations from claims, demands, and damages (actual and consequential) of every kind or nature, known or unknown, suspected and unsuspected, disclosed and undisclosed, arising out of or in any way related to your use of the Site and/or Services.</p>

<p>&nbsp;</p>

<p>You agree that Mysittivacations is not liable for any failure to carry out services for reasons beyond its control, including but not limited to acts of God, telecommunication problems, software failure, hardware failure, third party interference, Government, emergency on a major scale or any social disturbance of extreme nature such as industrial strike, riot, terrorism and war or any act or omission of any third party services.</p>

<p>&nbsp;</p>

<p><strong>17. Notice</strong></p>

<p>&nbsp;</p>

<p>All notices must be in writing. Notices to Mysittivacations must be sent by e-mail or addressed and delivered to <strong>Mysittivacations </strong> or such other address as is advised by mysittivacations. Notices to User will be in writing and sent to your e-mail ID or address on our records. Any notice which is not in accordance with the above shall not produce any effect nor be binding against the party to whom it is delivered.</p>

<p>&nbsp;</p>

<p><strong>18. General</strong></p>

<p>&nbsp;</p>

<p>You may not, upon agreeing to these Terms and Conditions and for a period of six (6) months from the completion of Services, or from the pre-termination of the Agreement and full payment of all your obligations hereunder, entice any of mysittivacations&rsquo;s employees or subcontractors to resign from, or terminate its/his/her contract with, mysittivacations.</p>

<p>&nbsp;</p>

<p>To maintain the quality of our service to our clients we reserve the right to change these terms and conditions and the nature of our services, which we have agreed to provide to you by giving you a written notice. This notice will appear on your website.</p>

<p>&nbsp;</p>

<p>You may not transfer any of your rights or obligations under this Agreement to anyone else without our prior or written agreement.</p>

<p>&nbsp;</p>

<p>These Terms and Conditions do not, and shall not be deemed to, constitute a partnership or joint venture between you and us. Nothing in these Terms and Conditions shall be deemed to confer any third-party rights or benefits.</p>

<p>&nbsp;</p>

<p>These terms and conditions together with the order schedule, work sheets and any additional attachments form the whole of our agreement.</p>

<p>&nbsp;</p>

<p>Assignment: You may not assign this Agreement or any of its rights or obligations hereunder to any third party without the prior written consent of mysittivacations. Any assignment in violation of this provision shall produce no effect and shall not be binding on mysittivacations.</p>

<p>&nbsp;</p>

<p>Mysittivacations reserves the right to assign any portion of the work covered by this Agreement to ensure completion of the project within the period agreed upon.</p>

<p>&nbsp;</p>

<p>Severability: In the event that any provision of the Terms shall, in whole or in part, be determined to be invalid, unenforceable or void for any reason, such determination shall affect only the portion of such provision determined to be invalid, unenforceable or void, and shall not affect in any way the remainder of such provision or any other provision of the Terms. No waiver of any rights Mysittivacations has under this Agreement shall be deemed from any failure by Mysittivacations to enforce any part of this Agreement.</p>

<p>&nbsp;</p>

<p><strong>19. Governing Law</strong></p>

<p>&nbsp;</p>

<p>This Agreement and Terms shall be governed by and subject to the laws of the State of Tennessee, USA, without regard to its rules concerning conflicts of law and, as to matters affecting copyrights, trademarks and patents, by U.S. federal law. Those who use the Site or Services from other jurisdictions do so at their own volition and are responsible for compliance with local laws. Any dispute or claim arising out of, or in connection with, these Terms and Conditions shall be finally settled by binding arbitration. Arbitration proceedings shall be held at mysittivacations, and shall be conducted in English Language, as per the Rules of American Arbitration Association. Judgment on the award rendered by the arbitrator may be confirmed, reduced to judgment and entered in any court of competent jurisdiction. In the event that the forgoing arbitration provision is held invalid or unenforceable, the parties hereto irrevocably consent that such disputes shall be brought and heard by Tennessee court. The Parties consent to the exclusive jurisdiction of the courts of Tennessee to the exclusion of all other courts.&nbsp;</p>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TermModal
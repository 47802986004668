const CuisineModalData=[
    {
       type:"Restaurants"
    },
    {
       type:"Delis"
    },
    {
       type:"Caribbean"
    },
    {
       type:"Food Stands"
    },
    {
       type:"Food"
    },
    {
       type:"Barbeque"
    },
    {
       type:"Pubs"
    },
    {
       type:"Beer, Wine & Spirits"
    },
    {
       type:"Mexican"
    },
    {
       type:"Tacos"
    },
    {
       type:"Diners"
    },
    {
       type:"Polish"
    },
    {
       type:"Ramen"
    },
    {
       type:"Hot Dogs"
    },
    {
       type:"Sandwiches"
    },
    {
       type:"Nightlife"
    },
    {
       type:"Thai"
    },
    {
       type:"Beer Bar"
    },
    {
       type:"Bubble Tea"
    },
    {
       type:"Asian Fusion"
    },
    {
       type:"Food Trucks"
    },
    {
       type:"African"
    },
    {
       type:"Buffets"
    },
    {
       type:"Fast Food"
    },
    {
       type:"Event Planning & Services"
    },
    {
       type:"Specialty Food"
    },
    {
       type:"Buffets"
    },
    {
       type:"Gastropubs"
    },
    {
       type:"Halal"
    },
    {
       type:"Sports Bars"
    },
    {
       type:"Italian"
    },
    {
       type:"Vegetarian"
    },
    {
       type:"Southern"
    },
    {
       type:"Pop-Up Restaurants"
    },
    {
       type:"Chicken Wings"
    },
    {
       type:"Chicken Shop"
    },
    {
       type:"Tapas/Small Plates"
    },
    {
       type:"Waffles"
    },
    {
       type:"Chinese"
    },
    {
       type:"Wine Bars"
    },
    {
       type:"Coffee & Tea"
    },
    {
       type:"Steakhouses"
    },
    {
       type:"Pakistani"
    },
    {
       type:"Donuts"
    },
    {
       type:"Seafood"
    },
    {
       type:"French"
    },
    {
       type:"Cantonese"
    },
    {
       type:"Food Delivery Services"
    },
    {
       type:"Middle Eastern"
    },
    {
       type:"Caterers"
    },
    {
       type:"Empanadas"
    },
    {
       type:"Cafes"
    },
    {
       type:"Soup"
    },
    {
       type:"Gluten-Free"
    },
    {
       type:"Food Court"
    },
    {
       type:"Cocktail Bars"
    },
    {
       type:"Vegan"
    },
    {
       type:"Korean"
    },
    {
       type:"Noodles"
    },
    {
       type:"Japanese"
    },
    {
       type:"Mediterranean"
    },
    {
       type:"Vietnamese"
    },
    {
       type:"Cajun/Creole"
    },
    {
       type:"New Mexican Cuisine"
    },
    {
       type:"Cuban"
    },
    {
       type:"Falafel"
    }
]
export default CuisineModalData
import React from 'react'
import { Link } from 'react-router-dom'
import Header from './header/Header'
import { Helmet } from 'react-helmet'
const ErrorPage = () => {
  return (
    <>
    		<Helmet>
		<meta
         name="description"
         content="Looking for the best deals travel packages? My Sitti Vacations offers the best & popular vacation packages, flight vacation deals, vacation discounts, and more."
        />
		</Helmet>
        <Header  />
    <div style={{textAlign:"center",marginTop:"35vh"}}>
        <h1 className='err-text'>
                Requested page not available
        </h1>
    </div>
    <div style={{display:"flex",alignItems:"center",justifyContent:"space-around"}}>
        <Link to="/">
            <button style={{backgroundColor:"orange",borderRadius:"40px"}}>
            Home
            </button>
        </Link>
        <Link to="contact">
            <button style={{backgroundColor:"orange",borderRadius:"40px"}}>
            Contact
            </button>
        </Link>
    </div>
    </>

  )
}

export default ErrorPage
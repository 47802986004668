import React, { Fragment } from "react";
import { Link } from "react-router-dom";

// import Slider from "react-slick";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { UsePlaceAuth } from "../../../../AppContext/AppContext";
import { useSelector } from "react-redux";

function PopularEntertainment() {
  const place = useSelector(state => state.place)
  let { setPopular_Entertainment_Click } = UsePlaceAuth()

  var settings = {
    slidesToShow: 4, // Number of items to display in the carousel
    loop: true, // Infinite loop
    autoplay: true, // Autoplay enabled
    autoplayTimeout: 2000, // Autoplay interval in milliseconds
    autoplayHoverPause: true, // Pause autoplay on hover
    nav: true, // Display navigation arrows
    dots: false, // Hide navigation dots
    responsive: { // Responsive breakpoints
      0: { // Breakpoint from 0px and up
        items: 1 // Display 1 item
      },
      600: { // Breakpoint from 600px and up
        items: 2 // Display 2 items
      },
      1000: { // Breakpoint from 1000px and up
        items: 4 // Display 4 items
      }
    }
  };
  return (
    <Fragment>
      <div className="flight-sec exitment-sec">
        <div className="slider-sec">
          <h2>Popular Entertainment</h2>
          <p>Tons of exciting things for entertainment</p>

          {/* <div className="owl-carousel owl-theme" id="ProductSlide-audio3"> */}
          <OwlCarousel  {...settings}>
            <div data-aos="zoom-in-right" className="local slider-main" onClick={() => { setPopular_Entertainment_Click("family") }}>
              <div className="slider-block">
                <Link to={`/family/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/entertainment2.png`} alt="Family" /></Link>
                <div className="slider-content">
                  <Link to={`/family/${place}`}><h3>Family</h3></Link>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-right" className="local slider-main" onClick={() => { setPopular_Entertainment_Click("performing") }}>
              <div className="slider-block">
                <Link to={`/performing-arts/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/entertainment3.png`} alt="Performing Arts" /></Link>
                <div className="slider-content">
                  <Link to={`/performing-arts/${place}`}><h3>Performing Arts</h3></Link>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-right" className="local slider-main" onClick={() => { setPopular_Entertainment_Click("winery") }}>
              <div className="slider-block">
                <Link to={`/brewery/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/entertainment4.png`}
                  alt="brewery" /></Link>
                <div className="slider-content">
                  <Link to={`/brewery/${place}`}><h3>Winery/Brewery</h3></Link>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-right" className="local slider-main" onClick={() => { setPopular_Entertainment_Click("comedy") }}>
              <div className="slider-block">
                <Link to={`/comedy/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/comedy.png`} alt="comedy" /></Link>
                <div className="slider-content">
                  <Link to={`/comedy/${place}`}><h3>Comedy</h3></Link>
              </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </Fragment>
  );
}

export default PopularEntertainment;
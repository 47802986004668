const ComedyMenu = [
    {label:"Arts and Theatere"},
    {label:"Broadway"},
    {label:"Off-Broadway"},
    {label:"Ballet and Dance"},
    {label:"Classical"},
    {label:"Rock Music"},
    {label:"Comedy"},
    {label:"Film Festivals"},
    {label:"Museums and Exhibits"},
    {label:"Musicals"},
    {label:"Opera"},
    {label:"Plays"},
    {label:"More Arts and Theater"}
  ];

  export default ComedyMenu;
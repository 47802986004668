import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const DMCAPolicyModal=({DMCAModal,SetDMCAModal})=>{


  return (
    <>
      <Modal
        show={DMCAModal}
        onHide={()=>SetDMCAModal(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>DMCA policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h3><span style={{fontSize:"13px", lineHeight:"1.6em"}}>Mysittivacations.com(thereinafter referred to as &ldquo;OUR&rdquo;, &ldquo;WE&rdquo;, or &ldquo;US&rdquo;) have adopted this Copyright Policy and DMCA Notification Process (the &ldquo;Copyright Policy&rdquo;) in accordance with the&nbsp;</span><a href="http://www.copyright.gov/legislation/dmca.pdf" style={{fontSize: "13px", lineHeight: "1.6em"}} target="_blank">Digital Millennium Copyright Act of 1998</a><span style={{fontSize:"13px", lineHeight:"1.6em"}}>, including the Online Copyright Infringement Liability Limitation Act (the &ldquo;DMCA&rdquo;).</span></h3>

<p>We respect the intellectual property rights of our users and others, and adhere to the provisions of the DMCA. The Copyright Policy applies to the website <a href="https://www.mysittivacations.com/" target="_blank">www.Mysittivacations.com</a>&nbsp;(the &ldquo;Site&rdquo;) to address claims of infringement pertaining to copyrighted material owned or controlled by a third party.</p>

<p><strong>COPYRIGHT&nbsp;</strong></p>

<p>Copyright is a form of legal protection given to authors of original works such as literary, dramatic, musical, artistic, graphic, photographic, software and certain other intellectual works. The copyright owner has the exclusive right to reproduce the work, make new works based on the work, distribute copies, and perform or display the work, during the duration of copyright protection, subject to very limited fair use rights of others to use only such amount as necessary to exercise fair use rights. You should assume all third party content is protected by copyright unless you have determined otherwise with the assistance of legal counsel and you should not rely on fair use except pursuant to advice of legal counsel. You can, however, seek a permission or license to use third party copyright protected content and once obtained will have the right to do so.</p>

<p><strong>POLICY</strong></p>

<p>Pursuant to the&nbsp;<a href="http://www.reverbnation.com/termsandconditions#general" target="_blank">General Terms and Conditions</a>&nbsp;of the Site each Site user is responsible for ensuring that the materials they upload to the Site do not infringe any third party copyright. Additionally, the&nbsp;<a href="http://www.reverbnation.com/termsandconditions#general" target="_blank">General Terms and Conditions</a>&nbsp;prohibit Site users from using the site to infringe the intellectual property rights of any person or entity.</p>

<p>If you are a copyright holder who believes that any of the material(s) on the Site violates your copyright rights, please follow the guidelines and procedures below to provide us with appropriate notice. We will respond to clear written notices alleging infringement that comply with, or substantially comply with, the DMCA and other applicable laws.</p>

<p>Mysittivacation will remove or disable access to allegedly infringing material from the Site upon receipt of a valid DMCA notice as required by the DMCA and will provide the allegedly infringing party with a copy of the DMCA notice alleging infringement. If the party who posted the allegedly infringing information files a valid counter-notification, the materials will be reposted unless you file a claim for relief as further set forth in the DMCA.</p>

<p>All notices must be provided to our Designated Agent &nbsp;(e.g.,through U.S. mail, e-mail, or facsimile) as described below. The Company does not accept notices or counter-notifications under the DMCA submitted by telephone or electronically via Company websites or mobile applications. You may wish to consult with legal counsel to assist you with assessing your claim(s) and understanding your rights and liabilities,&nbsp;including, without limitation, your potential liability for filing a false claim.</p>

<p>Mysittivacation does not act as an arbiter or judge of disputes about intellectual property rights. We do not adjudicate the substance of the copyright claim: we do not declare winners and losers. Your copyright in an item is determined in the real world, by real-world processes including the DMCA. The DMCA process allows users of an online service to resolve copyright disputes using the adjudication systems available in the real world. By disabling access to or removing content, as a prudential matter, Mysittivacation is not endorsing or validating a claim of infringement.</p>

<p>&nbsp;<strong>INFRINGEMENT POLICY</strong></p>

<p>Pursuant to the&nbsp;<a href="http://www.reverbnation.com/termsandconditions#general" target="_blank">General Terms and Conditions</a>, if a user engages in &ldquo;repeat infringement&rdquo; we may terminate the user&rsquo;s registration and Site account without notice, and the user will no longer be permitted access to the Site or the Services. &ldquo;Repeat infringement&rdquo; shall be defined as two (2) or more instances, as determined by us in our reasonable discretion, where a user has infringed the copyright rights of another person. In the event a user&rsquo;s materials are removed due to a DMCA notice and then subsequently restored due to the filing of a DMCA counter-notification, we will treat the underlying DMCA notice as withdrawn. We reserve the right to terminate Site accounts that are the subject of fewer than two (2) instances of infringement of the copyright rights of another person in appropriate circumstances, such as when the user has a history of violating or willfully disregarding the&nbsp;<a href="http://www.reverbnation.com/termsandconditions#general" target="_blank">General Terms and Conditions</a>.<strong>&nbsp;</strong></p>

<p><strong>DMCA (Digital Millennium Copyright Act)&nbsp;NOTICE PROCESS</strong></p>

<p>The DMCA provides a process for a copyright owner to give notification to an online service provider concerning alleged copyright infringement. When a valid DMCA notification is received, the service provider responds under this process by taking down the offending content. Upon taking down content pursuant to the DMCA, the online service provider will take reasonable steps to contact the owner of the removed content so that a counter-notification may be filed. On receiving a valid counter-notification, the online service provider generally restores the content in question, unless it receives notice from the notification provider that a legal action has been filed seeking a court order to restrain the alleged infringer from engaging in the infringing activity.</p>

<p><strong>FILING A DMCA NOTICE TO REMOVE COPYRIGHTED CONTENT&nbsp;</strong></p>

<p>If you believe that your work has been copied in a way that constitutes copyright infringement, please provide Mysittivacation with a completed&nbsp;<a href="http://www.reverbnation.com/DMCANoticeForm.pdf" target="_blank">DMCA NOTICE FORM</a>, or a written notice containing the following information:</p>

<p><strong>(1)</strong>&nbsp;Your legal name, mailing address, telephone number, and email address (if any);<br />
<strong>(2)</strong>&nbsp;A description of the copyrighted work that you claim has been infringed, and the name of the owner of the copyrighted work;<br />
<strong>(3)</strong>&nbsp;A description of where on the Site the material that you claim is infringing the copyright may be found, sufficient for Mysittivacation to locate the material (e.g., the URL);<br />
<strong>(4)</strong>&nbsp;A statement that you have a good faith belief that the use of the copyrighted work is not authorized by the copyright owner, its agent, or the law;<br />
<strong>(5)</strong>&nbsp;A statement by you&nbsp;<strong>UNDER PENALTY OF PERJURY</strong>&nbsp;that the information in your notice is accurate and that you are the copyright owner or authorized to act on the copyright owner&#39;s behalf;<br />
<strong>(6)</strong>&nbsp;Your electronic or physical signature.</p>

<p>Completed DMCA Notice Forms may be submitted to our Designated Agent, identified below, via: U.S. Mail; Email; or Facsimile.</p>

<p>Please note that we may disclose any complete DMCA Notices and any communications concerning DMCA notices or other intellectual property complaints with third parties, including the users who have posted the allegedly infringing material.</p>

<p><strong>FILING A DMCA COUNTER-NOTIFICATION TO RESTORE REMOVED CONTENT&nbsp;</strong></p>

<p>If you believe that your material has been removed by mistake or misidentification, please provide Mysittivacation with a written counter-notification containing all of the following information:</p>

<p><strong>(1)</strong>&nbsp;Your legal name, mailing address, telephone number, and email (if any);<br />
<strong>(2)</strong>&nbsp;A description of the material that was removed and the location on the Site (e.g., the URL) where it previously appeared;<br />
<strong>(3)</strong>&nbsp;A statement&nbsp;<strong>UNDER PENALTY OF PERJURY</strong>&nbsp;that you have a good faith belief that the material was removed or disabled as a result of mistake or misidentification;<br />
<strong>(4)</strong>&nbsp;A statement that you consent to the jurisdiction of the Federal District Court for the judicial district in which your address is located.<br />
<strong>(5)</strong>&nbsp;Your electronic or physical signature.</p>

<p>Completed DMCA counter-notifications may be submitted to our Designated Agent, identified below, via: U.S. Mail; Email; or Facsimile.</p>

<p>Please note that Mysittivacation will send any complete counter-notifications Mysittivacation receive to the person who submitted the original DMCA notice. That person may elect to file a lawsuit against you for copyright infringement. If we do not receive notice that a lawsuit has been filed within ten (10) business days after we provide notice of a counter-notification, Mysittivacations will restore the removed materials. Until that time, the materials will remain removed.</p>

<p><strong>WARNING</strong></p>

<p><strong>UNDER SECTION 512(f) OF THE COPYRIGHT ACT, 17 U.S.C. &sect; 512(f), ANY PERSON WHO KNOWINGLY MATERIALLY MISREPRESENTS THAT MATERIAL OR ACTIVITY IS INFRINGING OR WAS REMOVED OR DISABLED BY MISTAKE OR MISIDENTIFICATION MAY BE SUBJECT TO LIABILITY.</strong></p>

<p>If you have questions about the legal requirements of a DMCA notice, please contact an attorney or see Section 512(c)(3) of the U.S. Copyright Act, 17 U.S.C. &sect; 512(c)(3), for more information. If you have questions about the legal requirements of a DMCA counter-notification, please contact an attorney or see Section 512(g)(3) of the U.S. Copyright Act, 17 U.S.C. &sect; 512(g)(3), for more information. For the full text of the DMCA, click&nbsp;<a href="http://www.copyright.gov/legislation/pl105-304.pdf" target="_blank">here</a>.</p>

<p><strong>DESIGNATED COPYRIGHT AGENT</strong></p>

<p>&nbsp;</p>

<p>You can send your notifications and counter-notifications of claimed infringement to Mysittivacations.com&lsquo;s Designated Copyright Agent who can be reached as below:</p>

<p>&nbsp;</p>

<p>Attention: Copyright Agent</p>

<p>Mysittivacations.com [INSERT ADDRESS]</p>

<p>Email: [INSERT EMAIL ADDRESS]</p>

<p>You acknowledge that if you fail to comply with all of the requirements, your DMCA notification may not be valid.</p>

<p>&nbsp;</p>

        </Modal.Body>
      </Modal>
    </>
  );
}
export default DMCAPolicyModal
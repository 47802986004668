import React, { Fragment, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { apiData } from '../../../../actions';
import play_image from "../../../../demo/play.png"

function FirstSlider({ tourData }) {
  const [play, setPlay] = useState(false)
  const [loader, setLoader] = useState(false);
  const [audioData, setAudioData] = useState({});


  var settings = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
        0: {
            items: 2
        },
        768: {
            items: 2
        },
        1024: {
            items: 4
        }
    }
  };
  const handleClick = async (id) => {
    setPlay(true)
    setLoader(true);
    let payload = {
      audioId: id,
    };
    if (id) {
      let apiRes = await apiData.getShadowAudio(payload);
      if (apiRes?.status >= 200 && apiRes?.status <= 399) {
        setAudioData(apiRes?.data?.data);
        setLoader(false);
      }
    }
  }
  return (
    <Fragment>
      <div className="testimonial-section products">
        <div className="slider-sec">
          <h2>{tourData?.title}</h2>
          {tourData?.imagesData && <OwlCarousel {...settings}>
            {tourData?.imagesData &&
              tourData?.imagesData?.map((ele) => {
                return (
                  <div data-aos="zoom-in-left" className="slider-main">
                    <div className="testimonial-block product" key={ele?.main_uiid}
                    >
                      <div className="discount-block">
                        <img src={ele?.image_url} alt="" />
                        <img
                          onClick={() => { handleClick(ele?.main_uiid) }}
                          className="play"
                          src={play_image}
                          alt="play"
                        />
                        <div className="discount-content things">
                          <h3>
                            {ele?.tour_title.split(" ").length > 3
                              ? `${ele?.tour_title
                                .split(" ")
                                .slice(0, 3)
                                .join(" ")}...`
                              : ele?.tour_title.split(" ").slice(0, 3).join(" ")}
                          </h3>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </OwlCarousel>}
        </div>
      </div>
      <Modal
        show={play}
        onHide={() => setPlay(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h3>{audioData && audioData?.title}</h3>
            <audio src={audioData && audioData?.audio_url} controls />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body dangerouslySetInnerHTML={{ __html: audioData && audioData?.description }}>
        </Modal.Body>

      </Modal>
    </Fragment>
  );
}

export default FirstSlider;

import React, { Fragment, useEffect, useState } from "react";
import Autocomplete from "react-google-autocomplete";
import AppPromo from "./appPromo/AppPromo";
// import AdverSec from "./adverSec/AdverSec";
import FoodFilteration from "./foodFilteration/FoodFilteration";
// import { UsePlaceAuth } from "../../../AppContext/AppContext";
import { useNavigate } from "react-router-dom/dist";
import { useDispatch, useSelector } from "react-redux";
import { Restaurants, setPlace } from "../../../Redux/Action/Action";
function Restaurent() {
//   const { setActive } = UsePlaceAuth();
  let [selectedplace, setSelectedPlace] =useState("");

	let navigate=useNavigate()
	const dispatch = useDispatch();
  useEffect(() => {
    dispatch(Restaurants())
  })
  const place = useSelector(state=>state.place)
  console.log("city",place)

// .......handle onclick search functionlities...//

  const handleSearch=(e)=>{
	
	if(e.key.toLowerCase() == "enter"){
		e.preventDefault()
		navigate(`/restaurant/${e.target.value.toLowerCase()}`)
		// setPlace(e.target.value.toLowerCase());
		dispatch(setPlace(e.target.value.toLowerCase()));
	}
  }

  return (
          <Fragment>
		  <div className="banner-section rest-sec Restaurants">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-12 col-md-12 col-lg-12">
						<div className="tab-content" id="myTabContent">
						<div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
									<div className="select-form restaurent-sec">
										<form>
											<div className="choose-sec">
												<Autocomplete
													className="form-control"
													apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"

													placeholder={place.replace(/\b\w/g, x => x.toUpperCase())}
													onPlaceSelected={async (city) => {

														await dispatch(setPlace((city.formatted_address.split(',')[0]).toLowerCase()));
														navigate(`/restaurant/${(city.formatted_address.split(',')[0]).toLowerCase()}`)
														window.location.reload();

													}}
													onKeyPress={handleSearch}
												/>
											</div>
											{/* <div className="choose-sec">
												<Autocomplete
													className="form-control"
													apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
													placeholder={place?.replace(/\b\w/g, x => x?.toUpperCase())}
													onPlaceSelected={async (city) => {
														await dispatch(setPlace((city.formatted_address.split(',')[0]).toLowerCase()));
														dispatch(setPlace((city?.formatted_address?.split(',')[0]).toLowerCase()));
													}}
													onKeyPress={handleSearch}
												/>
											</div> */}
										</form>
									</div>
									{/* <div className="action-sec" >
										<a onClick={() => { setPlace(selectedplace) }}>Search</a>
									</div> */}
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	    <div className="adver-sec">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-12 col-md-12 col-lg-12">
					<a href="https://www.anrdoezrs.net/click-8265264-11454023" target="_top">
                    <img src="https://www.lduhtrp.net/image-8265264-11454023" width="728" height="120" alt="728x90 Get Quote"border="0"/></a>
					</div>
				</div>
			</div>
		</div>
      <FoodFilteration />
      <AppPromo/>
    </Fragment>
  );
}
export default Restaurent;
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import OwlCarousel from 'react-owl-carousel';
import { apiData } from '../../../../../actions'
import { UsePlaceAuth } from '../../../../../AppContext/AppContext'
import DataLoader from '../../../../../Utils/DataLoader'
import AdverSec from '../../../restaurent/adverSec/AdverSec'
import AppPromo from '../../../restaurent/appPromo/AppPromo'
import BannerSec from '../../../restaurent/bannerSec/BannerSec'
import MusicMenu from '../MusicMenuData'
import JazzMusicBanner from '../JazzMusicBanner'
import ad1 from "../../../../../demo/ad1-performing arts.gif"
import ad2 from "../../../../../demo/ad2-performing arts.gif"
import yelpnew from "../../../../../demo/yelpnew.png"
import rest9 from "../../../../../demo/rest9.png"
import rest10 from "../../../../../demo/rest10.png"
import rest11 from "../../../../../demo/rest11.png"
import rest12 from "../../../../../demo/rest12.png"
import { useSelector } from 'react-redux'
const Jazz = () => {
  const [deal, setDeal] = useState([])
  const [groupon, setGroupon] = useState([])
  const [musicType, setMusicType] = useState("Jazz")
  const [musicData, setMusicData] = useState([])
  const [loader, setLoader] = useState(false)
  const place = useSelector(state => state.place)
  const DataJson = MusicMenu ?? [];
  const [testing, setTesting] = useState(["Jazz"]);
  const [scrollComponent, setScrollComponent] = useState(false);
  var settings = {
    items: 3,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  const GetDeals = async () => {
    setLoader(true);
    let payload = {
      city: place,
      keyword: "jazz",
      limit: 12
    };
    let apiRes = await apiData.getYelpDeals(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setDeal(apiRes?.data?.data?.businesses);
      setLoader(false);
    }
  };
  const GetGroupon = async () => {
    setLoader(true);
    let payload = {
      city: place,
      keyword: "jazz music"
    };
    let apiRes = await apiData.getGroupon(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setGroupon(apiRes?.data?.data?.data?.travelExperienceProducts?.resultList);
      setLoader(false);
    }
  };
  const GetMusic = () => {
    setLoader(true);
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}category-filter?city=${place}&keyword=${musicType}`,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(
        function (response) {

          setMusicData(response?.data?.data?._embedded?.events)
          setLoader(false);
        },
        (error) => {
          console.log(error);
        }
      );
  }
  useEffect(() => {
    GetDeals();
    GetGroupon();
    GetMusic()
  }, [place, musicType])

  const handleDeliveryType = (e) => {
    const { checked } = e.target;
    setScrollComponent(checked);

    if (checked) {
      const element = document.getElementById("family_ticketMaster");
      element.scrollIntoView({ behavior: "smooth" });
    }
    const getValue = e.target.value;
    setMusicType(getValue);
    setTesting((prevState) => {
      return checked
        ? [prevState, getValue]
        : prevState?.filter((item) => item !== getValue);
    });
  };
  useEffect(() => {
    if (window.screen.width < 990) {
      document.getElementById("MenuIcon").style.display = "block"
      document.getElementById("MenuBar").style.display = "none"
    }
    else {
      document.getElementById("MenuIcon").style.display = "none"
      document.getElementById("MenuBar").style.display = "block"
    }
  }, [])

  const handleMenuIcon = () => {
    if (window.screen.width < 990) {

      if (document.getElementById("MenuBar").style.display === "none") {
        document.getElementById("MenuBar").style.display = "block"
      }
      else {
        document.getElementById("MenuBar").style.display = "none"
      }
    }
  }

  const handleMobileMenuClick = () => {
    if (window.screen.width < 990) {
      document.getElementById("MenuBar").style.display = "none"
    }
  }
  return (
    <div>
      <JazzMusicBanner />
      <AdverSec />
      <div className="itemBox">
        <div className="rind-the-right-section comedy-sec">
          <div className="container">
            <div className="row">
              <button onClick={handleMenuIcon} id="MenuIcon" style={{ display: "none" }}>
                <i className="fa fa-bars" aria-hidden="true"> Catagory</i>
              </button>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-3" id="MenuBar">
                <div className="sidebar-listing">
                  <div className="specialities-checkbox">
                    <div className="searcher-sec">
                      <label className="custom-control-label">Search By Name</label>
                      <div className="form-group">
                        <input type="Name" readOnly className="form-control" id="exampleFormControlInput1" placeholder={place.replace(/\b\w/g, x => x.toUpperCase())}/>
                      </div>
                    </div>
                  </div>
                  <div className="specialities-checkbox">
                    <div className='listing-check'>
                      <h4>CATEGORY</h4>
                      {
                        DataJson.map((ele) =>
                          <>
                            <div className="custom-control custom-checkbox">
                              <input type="checkbox" className="custom-control-input"
                                id={ele?.label}
                                value={ele?.label}
                                checked={testing.includes(ele?.label)}
                                onChange={(e) => {
                                  handleDeliveryType(e);
                                  GetMusic();
                                }}
                                onClick={handleMobileMenuClick}
                              />
                              <label className="custom-control-label" htmlFor={ele?.label}>{ele?.label}</label>
                            </div>
                          </>
                        )
                      }
                    </div>
                    <div className="listing-check">
                      <img src={ad1} style={{ width: "100%" }} alt=''/>
                    </div>
                    <div className="listing-check">
                      <img src={ad2} style={{ width: "100%" }} alt=''/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <div className="white-box-area inner rest-deals">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="heading-content">                 
                      </div>
                    </div>
                    <div className="col-lg-12 col-12">
                      <section className="slider-restaurent">
                        <div className="slider-main">
                          <div className="slider-heading">
                            <h3>Concert Deals </h3>
                          </div>
                          <div id="slide-restaurent">
                          {groupon && <OwlCarousel {...settings}>
                              {
                                groupon && groupon?.map((ele) =>
                                  <div data-aos="zoom-in-left" className="testimonial-block product">
                                    <a href={ele?.link}><div className="slider-block">
                                      <img src={ele?.imageLink} alt=''/>
                                      <div className="slider-content">
                                        <h3>{ele?.title?.length >= 20 ? `${ele?.title?.slice(0, 20)}...` : ele?.title}</h3>
                                        <div className="stars">
                                          <p><i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i></p>
                                          <p>(339 Reviews)</p>
                                        </div>
                                      </div>
                                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div><span style={{ textDecoration: "line-through", color: "#FE4D00" }}>${ele?.price?.amount}</span> <span>${ele?.salePrice?.amount}</span></div>
                                        <div ><span className="fa fa-map-marker" style={{ color: "#FE4D00" }}></span> <span>{ele?.locationName}</span></div>
                                      </div>
                                    </div></a>
                                  </div>)
                              }
                            </OwlCarousel> } 
                          </div>
                        </div>
                        <div className="slider-main">
                          <div className="slider-heading">
                            <h3> Deals on <img src={yelpnew} height="24px" width="58px" alt=''/></h3>
                          </div>
                          <div id="slide-restaurent">
                          {deal.length>0 && <OwlCarousel {...settings}>
                              {
                                deal && deal?.map((ele) =>
                                  <div data-aos="zoom-in-right" className="testimonial-block product">
                                    <div className="slider-block">
                                      <a href={ele?.url}><img src={ele?.image_url} alt=''/></a>
                                      <div className="slider-content">
                                        <a href={ele?.url}><h3>{ele?.name}</h3></a>
                                        <div className="stars">
                                          <p><i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i>
                                            <i className="fa fa-star" aria-hidden="true"></i></p>
                                          <p>({ele?.review_count} reviews)</p>
                                        </div>
                                        <p>{ele?.location?.address1 ? ele?.location?.address1 : ele?.location?.display_address[0]}</p>
                                      </div>
                                      <div className="comedy-detail">
                                        <ul>
                                          <li><i className="fa fa-map-marker" aria-hidden="true"></i>{ele?.location?.display_address}</li>
                                          <li><i className="fa fa-phone" aria-hidden="true"></i>{ele?.display_phone}</li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )
                              }
                            </OwlCarousel>}
                          </div>
                        </div>
                        <div className="slider-main">
                          <div className="slider-heading">
                            <h3>Fine Dining</h3>
                          </div>
                          <div id="ProductSlide-audio3">
                            <OwlCarousel {...settings}>
                              <div data-aos="zoom-in-right" className="slide-restaurent">
                                <div className="slider-block">
                                  <img src={rest9} alt=''/>
                                  <div className="slider-content">
                                    <h3>Manhatta</h3>
                                    <div className="stars">
                                      <p><i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i></p>
                                      <p>(339 Reviews)</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-aos="zoom-in-right" className="slide-restaurent">
                                <div className="slider-block">
                                  <img src={rest10} alt=''/>
                                  <div className="slider-content">
                                    <h3>Beauty & Essex</h3>
                                    <div className="stars">
                                      <p><i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i></p>
                                      <p>(339 Reviews)</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-aos="zoom-in-right" className="slide-restaurent">
                                <div className="slider-block">
                                  <img src={rest11} alt=''/>
                                  <div className="slider-content">
                                    <h3>Momofuku Ko</h3>
                                    <div className="stars">
                                      <p><i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i></p>
                                      <p>(339 Reviews)</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div data-aos="zoom-in-right" className="testimonial-block product">
                                <div className="slider-block">
                                  <img src={rest12.png} alt=''/>
                                  <div className="slider-content">
                                    <h3>Thailicious</h3>
                                    <div className="stars">
                                      <p><i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i>
                                        <i className="fa fa-star" aria-hidden="true"></i></p>
                                      <p>(339 Reviews)</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </OwlCarousel>

                          </div>
                        </div>
                        <div className="slider-main">
                          <div className="slider-heading">
                            <h3>{`Popular shows in ${place?.replace(/\b\w/g, x => x.toUpperCase())}`}</h3>
                          </div>
                          {
                            !loader ?
                              <div data-aos="zoom-in-left" id="family_ticketMaster">
                                <OwlCarousel {...settings}>
                                  {
                                    musicData && musicData?.map((ele) =>
                                      <div className="">
                                        <div className="slider-block">
                                          <a href={ele?._embedded.venues.map((i) => i.url)}>                                  
                                            <img src={ele?.images[0].url} alt=''/>
                                            <div className="slider-content winery-content">
                                              <h3>{ele?.name.slice(0,20)}...</h3> <span><i className="fa fa-map-marker" aria-hidden="true"></i> {ele?._embedded.venues.map((i) => i.name)}</span>

                                              <div className="escape-room">
                                                <p><i className="fa fa-map-marker" aria-hidden="true"></i>{ele?._embedded.venues?.map((i) => i?.address?.line1)}</p>
                                                <p><i className="fa fa-calendar" aria-hidden="true"></i>{moment(ele?.dates?.start?.localDate).format(
                                                  "MMMM Do YYYY"
                                                )}</p>
                                                <p><i className="fa fa-clock-o" aria-hidden="true"></i> {moment(ele?.dates?.start?.localDate, "HH:mm:ss").format("hh:mm A")}</p>
                                              </div>
                                            </div>
                                          </a>

                                          <div className="review-sec">
                                            <a className="siti-btn full" href={ele?._embedded?.venues?.map((i) => i?.url)} target="_blank">See Ticket </a>
                                          </div>
                                        </div>
                                      </div>


                                    )
                                  }
                                </OwlCarousel>
                              </div>

                              : <DataLoader />
                          }
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AppPromo />
    </div>
  )
}

export default Jazz
import React, { useState } from "react";
import arrows from "../../images/right.svg";
import AboutusModal from "../Modals/AboutusModal";
import DMCAPolicyModal from "../Modals/DMCAPolicyModal";
import TermModal from "../Modals/TermModal";
import PrivacyPolicyModal from "../Modals/PrivacyPolicyModal";
import OtherModal from "../Modals/OtherModal";
import ContactModal from "../Modals/ContactModal";
// import { UsePlaceAuth } from "../../AppContext/AppContext";
import { useSelector } from "react-redux";
function Footer() {
  // const place = useSelector(state => state.place)
  // const handleModeChange = () => {
  //   document.body.classList.toggle("dark-mode");
  // };
  const handleScroll = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  const [aboutUsModal, SetAboutUsModal] = useState(false);
  const [DMCAModal, SetDMCAModal] = useState(false);
  const [termModal, SetTermModal] = useState(false);
  const [privacyModal, SetPrivacyModal] = useState(false);
  const [otherModal, SetOtherModal] = useState(false);
  const [contactModal, SetContactModal] = useState(false);

  return (
    <React.Fragment>
      <footer className="footer-section">
    <div className="flight-sec blue-back subscription">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="newsletter-main">
                                <div className="newsletter-sec">
                                    <div className="newsletter-content">
                                        <h2 data-aos="zoom-in-right">Your Travel Journey Starts Here</h2>
                                        <p data-aos="zoom-in-left">Sign up and we`'ll send the best deals to you</p>
                                        <div data-aos="zoom-in-right" className="search-bar">
                                            <input type="text" id="name" name="name" className="form-control" placeholder="Your Email" required="" />
                                            <img className="mail" src="/images/Message.svg" alt="" />
                                            <a href="#">subscribe <img src="/images/Send.svg" alt="" /></a>
                                        </div>
                                    </div>
                                    <div data-aos="zoom-in-left" className="pic-newsletter"><img className="subscribe" src="/images/newsletter.png" alt="" /></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="footer-sec">
                                <div className="footer-logo">
                                    <a href="#"><img src="/images/logo.svg" /></a>
                                </div>
                                <div className="footer content"><p>We can help you plan the perfect vacation. Our travel website makes it easy to find the ideal trip and book one today! Check out our deals on flights, hotels, cruises, adventure tours, car rentals, tours, and more. We have partnered with more than 700+ airlines, over 500,000+ hotel locations, and thousands of travel sites worldwide. With so much to see and do, you want to ensure you've got the best travel plans. That's why we created our site: to help you find a great vacation package you can't find anywhere else.</p></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <div className="container-fluid">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-12">
                    <div className="footer-links">
                <ul >
                  <li className="clickable">
                    <a onClick={() => SetAboutUsModal(true)}>About Us</a>
                  </li>
                  <li className="clickable">
                    <a onClick={() => SetDMCAModal(true)}>DMCA Policy</a>
                  </li>
                  <li className="clickable">
                    <a onClick={() => SetTermModal(true)}>Term & Conditions</a>
                  </li>
                  <li className="clickable">
                    <a onClick={() => SetPrivacyModal(true)}>Privacy Policy</a>
                  </li>
                  <li className="clickable">
                    <a onClick={() => SetOtherModal(true)}>
                      Other Term & Conditions
                    </a>
                  </li>
                  <li className="clickable">
                    <a onClick={() => SetContactModal(true)}>Contact Us</a>
                  </li>
                </ul>
               
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 ">
              <p className="text-center">© 2024 mysittivacations.com</p>
            </div>
          </div>
        </div>
         {/* <div className="switch__tab">
          <span className="mode__switch" onClick={handleModeChange}>
            <span></span>
          </span>
        </div> */}
        <div className="scrollup" onClick={handleScroll}>
          <a>
            <img src={arrows} />
          </a>
        </div> 
      </footer>
      {aboutUsModal && (
        <AboutusModal
          aboutUsModal={aboutUsModal}
          SetAboutUsModal={SetAboutUsModal}
        />
      )}
      {DMCAModal && (
        <DMCAPolicyModal DMCAModal={DMCAModal} SetDMCAModal={SetDMCAModal} />
      )}
      {termModal && (
        <TermModal termModal={termModal} SetTermModal={SetTermModal} />
      )}
      {privacyModal && (
        <PrivacyPolicyModal
          privacyModal={privacyModal}
          SetPrivacyModal={SetPrivacyModal}
        />
      )}
      {otherModal && (
        <OtherModal otherModal={otherModal} SetOtherModal={SetOtherModal} />
      )}
      {contactModal && (
        <ContactModal
          contactModal={contactModal}
          SetContactModal={SetContactModal}
        />
      )}
    </React.Fragment>
  );
}
export default Footer;

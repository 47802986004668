import React, { Fragment, useState, useEffect } from "react";


import OwlCarousel from 'react-owl-carousel';
import { apiData } from "../../../../actions";
// import { UsePlaceAuth } from "../../../../AppContext/AppContext";
import DataLoader from "../../../../Utils/DataLoader";
import { useSelector } from "react-redux";

function Winery() {
  const place = useSelector(state => state.place)
  const [loader, setLoader] = useState(false);

  var settings = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 4
        }
    }
  };
  const [wineryData, setWineryData] = useState([]);

  const GetWinery = async () => {
    setLoader(true);
    let payload = {
      city: place,
    };

    let apiRes = await apiData.getWinery(payload);
    setWineryData(apiRes?.data?.winery?.businesses);
    setLoader(false);
  };
  useEffect(() => {
    GetWinery();
  }, [place]);
  return (
    <Fragment>
      {
        !loader ?
          <div className="flight-sec exitment-sec tour-slider new3">
            <div className="slider-sec">
              <h3>Winery</h3>
              <OwlCarousel {...settings}>
                {wineryData.map((ele, index) => (
                  <div key={index} data-aos="zoom-in-right" className="slider-main local">
                    <div className="slider-block">
                      <a href={ele?.url}><img src={ele?.image_url} alt="" /></a>
                      <div className="slider-content">
                        <a href={ele?.url}><h3>{ele?.name.slice(0,10)}</h3></a>
                        <a>
                          {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                        </a>
                      </div>
                    </div>
                  </div>
                ))}
              </OwlCarousel>
            </div>

          </div> :
          <DataLoader />
      }

    </Fragment>
  );
}

export default Winery;

import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { toast } from "react-toastify";
import { useForm } from 'react-hook-form';
import axios from 'axios';
const ContactModal=({contactModal,SetContactModal})=>{
  const [details, setDetails] = useState({
  FirstName:"",
  LastName:"",
  Email:"",
  Phone:"",
  Message:""
  })
  const [isvalid, setIsvalid] = useState({
  FirstName:false,
  LastName:false,
  Email:false,
  Phone:false,
  Message:false
  })
  //  const handleSubmit=()=>{
  //   console.log("data-form",details)
  //   if(!details.FirstName){
  //     setIsvalid({
  //       ...isvalid,FirstName:true
  //     })
  //    }
  //    else if(!details.LastName){
  //     setIsvalid({
  //       ...isvalid,LastName:true
  //     })
  //    }
  //    else if(!details.Email){
  //     setIsvalid({
  //       ...isvalid,Email:true
  //     })
  //    }
  //    else if(!details.Phone){
  //     setIsvalid({
  //       ...isvalid,Phone:true
  //     })
  //    }
  //    else if(!details.Message){
  //     setIsvalid({
  //       ...isvalid,Message:true
  //     })
  //    }
  //    toast.success("Thank you,for reaching us",{autoClose:2000});
  //    SetContactModal(false)
  //  }
   let {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <>
      <Modal
        show={contactModal}
        onHide={()=>SetContactModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill the form to reach you</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form
        onSubmit={handleSubmit((data) =>{toast.success("Thank you,for reaching us",{autoClose:2000});SetContactModal(false);
        axios.post(`${process.env.REACT_APP_BASE_URL}contact-us`, {
        fname:data.firstName,
        lname:data.lastName,
        email:data.email,
        phone:data.phone,
        text:data.message
        })
  .then(function (response) {
    console.log(response);
  })
  .catch(function (error) {
    console.log(error);
  });})}>
        <div className='d-flex'>
          <input className='inpt_box'{...register('firstName',{ required: true })} placeholder='FirstName'type="Text"/>
           {errors.firstName && <p className="sap-error-val m-1"></p>}
          <input {...register('lastName')} className='inpt_box ms-3' type="Text"placeholder='LastName'/>
          {errors.lastName && <p className="sap-error-val m-1"></p>}
        </div>
        <div className='d-flex mt-3'>
        <input className='inpt_box' {...register('email',{ required: true,validate: {
        maxLength: (v) =>
          v.length <= 50 || "The email should have at most 50 characters",
        matchPattern: (v) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v),
      },})}placeholder='Email'/>
      {errors.email && <p className="sap-error-val m-1"></p>}
        <input className='inpt_box ms-3' {...register('phone',{ required: true, pattern: /\d{10}/ })}type="phone"placeholder='Phone'/>
      {errors.phone && <p className='sap-error-val m-1'></p>}
        </div>
        <input className='inpt_box_lst mt-3 mb-3 pb-2' {...register('message',{required: true})}type="text"placeholder='Message'/>
        {errors.message && <p className='sap-error-val m-1'></p>}
        <div  className="review-sec">
        <input  className="btn btn-primary" type="submit" />
        </div >
       </form>
            {/* className='sap-error-val' */}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ContactModal
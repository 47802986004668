import React, { Fragment, memo, useEffect } from "react";
import { Rate } from "antd";

const DynamicDataFilteration = ({
  selectedCheckbox,
  filterData,
}) => {
  // Define settings for the carousel
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };

  
  useEffect(() => {
    // You can remove this empty useEffect if it's not serving any purpose
  }, [])

  return (
    <Fragment>
      <div className="col-lg-12 col-12">
        <div className="slider-restaurent">
          {/* Advertisement */}
          <div className="adver-sec">
            <a href="https://www.anrdoezrs.net/click-8265264-15520591" target="_top">
              <img src="https://www.lduhtrp.net/image-8265264-15520591" width="728" height="90" alt="" border="0" />
            </a>
          </div>
          <div className="slider-main">
            <div className="slider-heading">
              <h3>{`${selectedCheckbox}`}</h3>
            </div>
            {/* Carousel */}
            <div id="ProductSlide-audio">
              {/* Map through filterData to generate carousel items */}
              {filterData &&
                filterData.map((ele) => (
                  <div key={ele.id} className="slider-block">
                    <a href={ele.url}>
                      <img src={ele.image_url} alt={ele.name} />
                    </a>
                    <div className="slider-content">
                      <h3><a href={ele.url}>{ele.name}</a></h3>
                      <div className="restro_btns">
                        <div className="restro_deals">
                          <a className="restro_btn">VIEW DEAL</a>
                          <a className="restro_btn">ORDER ONLINE</a>
                        </div>
                        
                        <div className="star">
                          <ul>
                            <li>
                              <Rate allowHalf defaultValue={ele.rating} disabled />
                              <p>({ele.review_count} Reviews)</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Memoize the component to prevent unnecessary re-renders
export default memo(DynamicDataFilteration);

const MusicMenu = [
    {label:"All Genre"},
    {label:"Alternative"},
    {label:"Ballads/Romantic"},
    {label:"Blues"},
    {label:"Chanson Francaise"},
    {label:"Rock Music"},
    {label:"Children's Music"},
    {label:"Classical"},
    {label:"Country"},
    {label:"Dance/Electronic"},
    {label:"Folk"},
    {label:"Hip-Hop/Rap"},
    {label:"Holiday"},
    {label:"Jazz"},
    {label:"Metal"},
    {label:"New Age"},
    {label:"Other"}
  ];

  export default MusicMenu;
//To show comedy section in things to do
import React, { Fragment, useState, useEffect, useReducer } from "react";
import OwlCarousel from 'react-owl-carousel';
import { apiData } from "../../../../actions";
import { Rate } from "antd";
import { UsePlaceAuth } from "../../../../AppContext/AppContext";
import DataLoader from "../../../../Utils/DataLoader";
import { useSelector } from "react-redux";
const initialState = {
  loading: true,
  data: null,
  error: false
}
const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS":
      return {
        loading: false,
        data: action.payload,
        error: false
      };

    case "ERROR":
      return {
        loading: false,
        data: null,
        error: true
      };

    default:
      return state;
  }
}
function Comedy() {
  const [state, dispatch] = useReducer(reducer, initialState)

  const place = useSelector(state => state.place)
  const [loader, setLoader] = useState(false);
  const [getComedyData, setComedyData] = useState([]);
  const settings = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      992: {
        items: 4
      }
    }
  };
  const GetComedy = async () => {
    // setLoader(true);
    let payload = {
      city: place,
    };
    let apiRes = await apiData.getComedy(payload);
    dispatch({ type: "SUCCESS", payload: apiRes?.data?.comedy?.businesses })
    setLoader(false);
  };
  useEffect(() => {
    GetComedy();
  }, [place])

  return (
    <Fragment>
      {!loader ?
        <div className="flight-sec exitment-sec tour-slider new3">
          <div className="slider-sec">
            <h3>Comedy</h3>
            {state?.data && (
              <OwlCarousel {...settings}>
                {state.data.map((ele, index) => (
                  <a key={index} href={ele.url}>
                    <div data-aos="zoom-in-right" className="slider-main local">
                      <div className="slider-block">
                        <img src={ele.image_url} alt="" />
                        <div className="slider-content">
                          <h3>{ele.name.slice(0,18)}...</h3>
                          <p>{ele.location?.display_address[0]}</p>
                          <p>{ele.location?.display_address[1]}</p>
                          <p>{ele.location?.phone}</p>
                          <a>
                            {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                          </a>
                        </div>
                      </div>
                    </div>
                  </a>
                ))}
              </OwlCarousel>
            )}
          </div>
        </div>
        // </div>
        : ""
      }
    </Fragment>
  );
}

export default Comedy;

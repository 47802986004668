const jsonData = [
    {
      heading: "ESTABLISHMENT TYPE",
      data: [
        { label: "Restaurant" },
        { label: "Quick Bites" },
        { label: "Dessert" },
        { label: "Coffee & Tea" },
      ],
    },
    {
      heading: "RESTAURANT FEATURES",
      data: [
        { label: "Delivery" },
        { label: "Takeout" },
        { label: "Gift Cards Available" },
        { label: "Table Service" },
        { label: "Accepts Credit Cards" },
        { label: "Family style" },
        { label: "Free Wifi" },
      ],
    },
    {
      heading: "MEALS",
      data: [
        { label: "Breakfast" },
        { label: "Brunch" },
        { label: "Lunch" },
        { label: "Dinner" },
      ],
    },
    {
      heading: "CUISINE",
      data: [
        { label: "American" },
        { label: "Asian" },
        { label: "Chinese" },
        { label: "Australian" },
        { label: "Italian" },
        { label: "Indian" },
        { label: "French" },
        { label: "Japanese" },
        { more: "View more" },
      ],
    },
    {
      heading: "PRICE",
      data: [
        { label: "Cheap Eats" },
        { label: "Mid-range" },
        { label: "Fine Dining" },
      ],
    },
    {
      heading: "DISHES",
      data: [
        { label: "Salad" },
        { label: "Fish" },
        { label: "Beef" },
        { label: "Pork" },
      ],
    },
    {
      heading: "GOOD FOR",
      data: [
        { label: "Child-friendly" },
        { label: "Kids" },
        { label: "Bar Scene" },
        { label: "Groups" },
      ],
    },
    {
      heading: "DIETARY RESTRICTIONS",
      data: [
        { label: "Vegetarian Friendly" },
        { label: "Vegan Options" },
        { label: "Halal" },
        { label: "Kosher" },
      ],
    },
    {
      heading: "NEIGHBOURHOODS",
      data: [
        { label: "Downtown New York" },
        { label: "Soma" },
        { label: "Mission District" },
        { label: "Theater District" },
      ],
    },
  ];
  export default jsonData;
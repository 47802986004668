import React, { Fragment } from "react";
import promo from "../../../../images/H3-images-2.jpg";
function AppPromo() {
  return (
    <Fragment>
       <div className="flight-sec blue-back gk-sec">
                <img data-aos="zoom-in-right" className="gk-back" src="/images/gk.png" alt="" />
                <img data-aos="zoom-in-right" className="plan" src="/images/H3-fly-3.png" alt="" />
                <img data-aos="zoom-in-right" className="clock" src="/images/clock.png" alt="" />
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-12">
                            <div className="about-sec">
                                <div className="slider-header">
                                    <p data-aos="zoom-in-left">Rev up your excitement for the ultimate adventure! Start your journey with our website, where we not only understand the chaos of life but also guarantee the lowest prices. Picture this – snagging top hotel rooms at prices 20-30 dollars less per night compared to other travel sites. That’s not all – our flight prices? The most competitive, saving you a cool 25-50 dollars on the exact same flights offered by other online travel sites. Explore our site, unlock unbeatable deals, and let the thrill of savings fuel your dream getaway! 🌟💸🚀</p>
                                    <div data-aos="zoom-in-right" className="pic-collage"> <img className="gk-rotate" src={promo} alt="" /> <img className="gk-rotate2" src="/images/H3-images-3.jpg" alt="" /></div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </Fragment>
  );
}

export default AppPromo;

import React, { useEffect, useState } from 'react'
import { UsePlaceAuth } from '../../../AppContext/AppContext';
import { apiData } from '../../../actions';
import DataLoader from '../../../Utils/DataLoader';
import BannerSec from '../restaurent/bannerSec/BannerSec';
import { Fragment } from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { _Deals_, setPlace } from '../../../Redux/Action/Action';
import { useDispatch, useSelector } from 'react-redux';
const NewDeal = () => {
  const place = useSelector(state=>state.place)
  
  const [loader, setLoader] = useState(false);
  const [dealsData, setDealsData] = useState([]);
  let [selectedplace, setSelectedPlace] = useState("");
  const { active, setActive } = UsePlaceAuth()
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const GetDeals = async () => {
    setLoader(true)
    let apiRes = await apiData.getDealsHome();
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setDealsData(
        apiRes?.data?.data?.data?.travelExperienceProducts?.resultList
      );
      setLoader(false);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
      console.log("error");
    }
  };
  useEffect(() => {
    GetDeals()
    dispatch(_Deals_())
  }, [])
  return (
    <Fragment>
      <Fragment>
        <Helmet>
          <title>Find Exclusive Deals for your destination - MySittiVacations</title>
          <meta name="description" content="Looking for the best travel deals? Find exclusive offers for your destination with our expert guide. Discover amazing discounts on flights, hotels, and activities to help you save big on your next trip."></meta>
        </Helmet>
        <div className="banner-section rest-sec">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                <div className="tab-content" id="myTabContent">
                  <div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
                    <div className="select-form restaurent-sec">
                      <form>
                        <div className="choose-sec">
                          <ReactGoogleAutocomplete
                            className="form-control"
                            apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
                            placeholder={place}
                            onPlaceSelected={(city) => {
                              setSelectedPlace((city.formatted_address.split(',')[0]).toLowerCase());
                            }}
                          />
                        </div>
                      </form>
                    </div>
                    <div className="action-sec" onLoad={() => { dispatch(setPlace(selectedplace)) }}>
                      <a onClick={() => { navigate(`/deals/${selectedplace}`) }}>Search</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
      <div className="itemBox">
        <div className="rind-the-right-section comedy-sec">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="white-box-area inner rest-deals">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <section className="client-sec comedy">
                        {!loader ? (
                          <div data-aos="zoom-in-right" className="testimonial-section products">
                            <div className="head-yelp">
                              <h3>Our Featured Deals</h3>
                              <p>
                                Find exclusive deals for you destination
                              </p>
                            </div>
                            <div className="row feat-blogs-head">
                              {dealsData &&
                                dealsData?.map((ele) => {
                                  return (
                                    <div
                                    data-aos="zoom-in-right"
                                      className="col-12 col-sm-6 col-md-4 col-lg-4 mb-4"
                                      key={ele?.id}
                                    >
                                      <a href={ele?.link}>
                                        <div className="cities">
                                          <img src={ele?.imageLink} alt="deals" />
                                          <div className="offer-details">
                                            <h6>{ele?.title}</h6>
                                            <p>{ele?.description?.slice(0, 100)}</p>
                                          </div>
                                          <div className="offer-action">
                                            <a >
                                              Book Now
                                            </a>
                                            <a href="#/">T&C’s Apply</a>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        ) : (
                          <DataLoader />
                        )}
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
export default NewDeal
import React from 'react'
// import { UsePlaceAuth } from '../../../../AppContext/AppContext'
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from 'react-router-dom/dist';
import { useDispatch, useSelector } from 'react-redux';
import { setPlace } from '../../../../Redux/Action/Action';

const LocalMusicBanner = () => {
	// let [selectedplace, setSelectedPlace] = useState("")
	const place = useSelector(state => state.place)
	let navigate = useNavigate()
	const dispatch = useDispatch();
	const handleSearch = (e) => {
		if (e.key.toLowerCase() == "enter") {
			e.preventDefault()
			navigate(`/things-to-do/concerts/${e.target.value.toLowerCase()}`)

			dispatch(setPlace(e.target.value.toLowerCase()));
		}
	}
	return (
		<div>
			<div className="banner-section rest-sec concert">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12">
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
									<div className="select-form restaurent-sec">
										<form>
											<div className="choose-sec">
												<Autocomplete
													className="form-control"
													apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
													placeholder={place.replace(/\b\w/g, x => x.toUpperCase())}
													onPlaceSelected={async (city) => {
														await setPlace((city.formatted_address.split(',')[0]).toLowerCase());
														navigate(`/things-to-do/concerts/${(city.formatted_address.split(',')[0]).toLowerCase()}`)

													}}
													onKeyPress={handleSearch}
												/>
											</div>
										</form>
									</div>
									{/* <div className="action-sec" >
									<a onClick={()=>{dispatch(setPlace(selectedplace))}}>Search</a>
								</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default LocalMusicBanner
import React,{Fragment} from 'react'
import advertisement from "../../../../demo/adv.png"
function AdverSec() {
  return (
    <Fragment>
        	<div className="adver-sec">
			<div className="container">
				<div className="row">
					<div className="col-12 col-sm-12 col-md-12 col-lg-12">
						<img src={advertisement} alt=''/>
					</div>
				</div>
			</div>
		</div>
    </Fragment>
  )
}
export default AdverSec
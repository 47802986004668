import React, { useRef, useState } from 'react'
import AdverSec from '../restaurent/adverSec/AdverSec'
import AppPromo from '../restaurent/appPromo/AppPromo'
import { UsePlaceAuth } from '../../../AppContext/AppContext'
import { useEffect } from 'react'
import Slider from 'react-slick'
import OwlCarousel from 'react-owl-carousel';
import { apiData } from '../../../actions'
import wifi from "../../../demo/wifi.png"
import { useLocation } from 'react-router-dom'
import DataLoader from '../../../Utils/DataLoader'
import { _Flight_ } from '../../../Redux/Action/Action'
import { useDispatch, useSelector } from 'react-redux'
import Footer from '../../footer/Footer'
const Flight = () => {
	let location = useLocation();
	const place = useSelector(state => state.place)
	const { active, setActive } = UsePlaceAuth()
	const [CoolDeals, setCoolDeals] = useState([]);
	const [loader, setLoader] = useState(false);
	const SearchRef = useRef();
	const [dealsData, setDealsData] = useState([]);
	const [relaxingData, setRelaxingData] = useState([]);
	const [viewLink, setViewLink] = useState("")
	const [stay_hotel, setStay_Hotel] = useState([])
	const [Dig_offers, setDig_offers] = useState("")
	const [CityCode, setCityCode] = useState("CHI")
	const dispatch = useDispatch();

	const CoolFlightDeals = async () => {
		setLoader(true)
		let apiRes = await apiData.coolFlightDeals();
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setCoolDeals(
				apiRes?.data?.data
			);
			setLoader(false);
		}
		if (apiRes?.status >= 400 && apiRes?.status <= 500) {
			console.log("error");
		}
	};
	const GetCities = async () => {
		setLoader(true);
		let apiRes = await apiData.RelaxingCities();
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setRelaxingData(apiRes?.data?.data);
			setLoader(false);
		}
		if (apiRes?.status >= 400 && apiRes?.status <= 500) {
			console.log("error");
		}
	};
	const GetDeals = async () => {
		let payload = {
			city: place,
			keyword: "All-Inclusive",
			limit: 12
		};
		setLoader(true)
		let apiRes = await apiData.getRandomDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setDealsData(
				apiRes?.data?.data?.data?.travelExperienceProducts?.resultList
			);
			setLoader(false);
		}
		if (apiRes?.status >= 400 && apiRes?.status <= 500) {
			console.log("error");
		}
	};
	const Specific_Hotel = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.get_Specific_Hotel(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setStay_Hotel(apiRes?.data?.data[0].content.split('"')[1]);
			setLoader(false);
			// }
		}
	}
	const DigInto = async () => {
		setLoader(true);
		let payload = {
			city: place ? place : "chicago",
		};
		let apiRes = await apiData.getDigInto(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setDig_offers(apiRes?.data?.digInto[0].content.split('"')[1]);
			setLoader(false);
			// }
		}
	}
	const GetHotelView = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		if (place) {
			let apiRes = await apiData.get_Hotel_View(payload);
			if (apiRes?.status >= 200 && apiRes?.status <= 399) {
				setViewLink(apiRes?.data?.data);
				setLoader(false);
				// }
			}
		}


	}
	const GetCityCode = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.cityIATA(payload);
		setCityCode(apiRes);
		setLoader(false);
		// }

	}

	useEffect(() => {
		GetCities();
		GetDeals();
		CoolFlightDeals();
		GetHotelView();
		DigInto();
	}, [])
	useEffect(() => {
		setTimeout(() => {
			GetCityCode()
		}, 1000);
	}, [place])

	const settings1 = {
		loop: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		nav: true,
		dots: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 499,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};
	useEffect(() => {
		dispatch(_Flight_())

		document.getElementById("flightSearch").innerHTML = ""
		let script = document.createElement('script');
		script.src = `https://tp.media/content?currency=usd&trs=26480&shmarker=130544&combine_promos=101_7873&show_hotels=false&powered_by=false&locale=en&searchUrl=travel.mysittivacations.com%2Fflights&primary_override=%23196ae1&color_button=%23196AE1&color_icons=%23196AE1&dark=%23262626&light=%23FFFFFF&secondary=%23FFFFFF&special=%23C4C4C4&color_focused=%23196ae1&border_radius=0&no_labels=true&plain=true&promo_id=7879&campaign_id=100`
		document.getElementById("flightSearch").appendChild(script)
		Specific_Hotel()
	}, [CityCode])
	useEffect(() => {
		// document.getElementById("hotel_stay").innerHTML = ""
		// let stay_script = document.createElement('script');
		// setLoader(true)
		// stay_script.src = stay_hotel
		// document.getElementById("hotel_stay").appendChild(stay_script);

		document.getElementById("dig_place").innerHTML = ""
		let dig_script = document.createElement('script');
		setLoader(true)
		dig_script.src = Dig_offers
		document.getElementById("dig_place").appendChild(dig_script);
		setLoader(false)
	}, [stay_hotel, Dig_offers])

	return (
		<>
			<div className="flight-banner-section rest-sec">
				<div id="flightSearch" >
				</div>
			</div>
			<AdverSec />
			<div className="offer-section  car-rental-content">
				<div className="container">
					<div className="row">
					
						<div data-aos="zoom-in-right" className="slider-sec">
							<h2>Flight Specials</h2>

						
					
						<div className="col-12 col-lg-12">
							<div className="slider-main">
							
								{CoolDeals.length > 0 && <OwlCarousel {...settings1}>
									{
										CoolDeals && CoolDeals?.map((ele) => {
											return (
												<div data-aos="zoom-in-right" className="testimonial-block product">
													<a href={ele.url}>
														<div className="discount-block">
															<img src={ele.image_url} alt="cool flight deals" />										
															<h3>{ele.title}</h3>													
														</div>
													</a>
												</div>
											)
										})
									}
								</OwlCarousel>}
							</div>
						</div>
						</div>
					</div>
				</div>
			</div>
			<div className="offer-section">
				<div className="container">
					<div className="row">
						{/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4">
										<div className="header-sec">
										<h2>Where to stay</h2>
													<p>Experience the ultimate in affordable luxury, comfort, and pet-friendly accommodations in our hotel
														rooms, where elegance seamlessly combines with affordability to provide an exceptional stay at
														the lowest prices possible.</p>
										</div>
									</div>
									<div className="col-12 col-lg-12">
										<div className="testimonial-section products client-sec comedy family-sec where_say_sec">
											<div className="owl-carousel owl-theme" id="ProductSlide">
											{
													!loader ? "" : <div style={{ marginTop: "60vh" }}>
														<DataLoader />
													</div>
												}
												<div id="hotel_stay">
												</div>
												<div className="view-tag">
													<a href={viewLink}>View All</a>
												</div>
											</div>
										</div>
									</div> */}
					</div>
				</div>
			</div>
			<div className="offer-section">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-4">
							<div  data-aos="zoom-in-right" className="slider-sec">
								<h3>{`Dig Into ${place.replace(/\b\w/g, x => x.toUpperCase())}`}</h3>
								<p>Stories, tips, and guides</p>
							</div>
						</div>
						<div className="col-12 col-lg-12">
							<div data-aos="zoom-in-right" className="testimonial-section products client-sec comedy family-sec where_say_sec">
							
								{
									!loader ? "" : <div style={{ marginTop: "60vh" }}>
										<DataLoader />
									</div>
								}
								<div id="dig_place">
								</div>
								
							</div>
						</div>
					</div>
				</div>
			</div>


			<AppPromo />
			{/* <Footer/> */}
		</>
	)
}

export default Flight
import { Route, Routes, useLocation } from "react-router-dom";
import Layout from "../components/layout/Layout";
import Home from "../components/dashboard/home/Home";
import Deals from "../components/dashboard/deals/Deals";
import AudioTour from "../components/dashboard/audioTour/AudioTour";
import Restaurent from "../components/dashboard/restaurent/Restaurent";
import ThingsToDo from "../components/dashboard/thingsToDo/ThingsToDo";
import Trip from "../components/dashboard/Trip/Trip";
import ErrorPage from "../components/ErrorPage";
import Rock from "../components/dashboard/thingsToDo/localMusic/Rock/Rock";
import Blues from "../components/dashboard/thingsToDo/localMusic/Blues/Blues";
import Jazz from "../components/dashboard/thingsToDo/localMusic/Jazz/Jazz";
import Country from "../components/dashboard/thingsToDo/localMusic/Country/Country";
import Concerts from "../components/dashboard/thingsToDo/localMusic/Concerts/Concerts";
import PerformingArts from "../components/dashboard/thingsToDo/popularEntertainment/Performing Arts/Performing Arts";
import Brewery from "../components/dashboard/thingsToDo/popularEntertainment/Brewery/Brewery";
import Family from "../components/dashboard/thingsToDo/popularEntertainment/Family/Family";
import ComedyEntertainment from "../components/dashboard/thingsToDo/popularEntertainment/Comedy/ComedyEntertainment";
import CarRental from "../components/dashboard/CarRental/CarRental";
import Flight from "../components/dashboard/Flight/Flight";
import Hotels from "../components/dashboard/restaurent/bannerSec/hotels/Hotels";
import FlightDeals from "../components/dashboard/deals/FlightDeals";
import HotelsDeals from "../components/dashboard/deals/HotelsDeals";
import ToursDeals from "../components/dashboard/deals/ToursDeals";
import VacationsDeals from "../components/dashboard/deals/VacationsDeals";
import CruisesDeals from "../components/dashboard/deals/CruisesDeals";
import AdventureDeals from "../components/dashboard/deals/AdventureDeals";
import Hotel_Go_to from "../components/dashboard/restaurent/bannerSec/hotels/Hotel_Go_to";
import { useEffect } from "react";
import Sports from "../components/dashboard/thingsToDo/Sports/Sports";
import SingleBlog from "../components/dashboard/blog/SingleBlog";
import NewDeal from "../components/dashboard/deals/NewDeal";
import BlogMain from "../components/dashboard/blog/BlogMain";
import { useDispatch, useSelector } from "react-redux";
import { setPlace } from "../Redux/Action/Action";
import AOS from "aos";
import "aos/dist/aos.css";
const CreateRoutes = () =>{
    const place = useSelector(state=>state.place)
    const location=useLocation()
    const dispatch = useDispatch();
   


useEffect(() => {
    if(!location.pathname.includes("/blog")){
        var count=0;
        for (var i = 0; i < location.pathname.length; i++) {
            if (location.pathname[i] === "/") {
              count++;
            }
          }
        if(count<1){
            dispatch(setPlace(""))
        }
        if(count>1){
                dispatch(setPlace(location.pathname.split("/")[location.pathname.split("/").length-1].replace(/%20/g," ")))
        }
    }

    else{dispatch(setPlace(place))}

}, [location.pathname])

useEffect(() => {
    AOS.init();
    // Refresh AOS whenever location changes
    AOS.refresh();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
    return (
        <>
                <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/:sub" element={<Home />} />
                <Route path="/blog/:sub" element={<SingleBlog />} />
                    <Route element={<Layout />}>
                        <Route path="/restaurant" element={<Restaurent />} />
                        <Route path="/restaurant/:sub" element={<Restaurent />} />
                        <Route path="/sports" element={<Sports />} />
                        <Route path="/sports/:sub" element={<Sports />} />
                        <Route path="/things-to-do/" element={<ThingsToDo />} />
                        <Route path="/things-to-do/:sub" element={<ThingsToDo />} />
                        <Route path="/blog" element={<BlogMain />} />
                        <Route path="/audio-tour" element={<AudioTour />} />
                        <Route path="/audio-tour/:sub" element={<AudioTour />} />
                        <Route path="/deals/:sub" element={<Deals />} />
                        <Route path="/deals" element={<Deals />} />
                        <Route path="/deals/flights" element={<FlightDeals />} />
                        <Route path="/deals/flights/:sub" element={<FlightDeals />} />
                        <Route path="/deals/hotels" element={<HotelsDeals />} />
                        <Route path="/deals/hotels/:sub" element={<HotelsDeals />} />
                        <Route path="/deals/vacations" element={<VacationsDeals />} />
                        <Route path="/deals/vacations/:sub" element={<VacationsDeals />} />
                        <Route path="/deals/tours" element={<ToursDeals />} />
                        <Route path="/deals/tours/:sub" element={<ToursDeals />} />
                        <Route path="/deals/cruises" element={<CruisesDeals />} />
                        <Route path="/deals/cruises/:sub" element={<CruisesDeals />} />
                        <Route path="/deals/adventures" element={<AdventureDeals />} />
                        <Route path="/deals/adventures/:sub" element={<AdventureDeals />} />
                        <Route path="/deal" element={<NewDeal />} />
                        <Route path="/deal/:sub" element={<NewDeal />} />
                        <Route path="/trip" element={<Trip />} />
                        <Route path="/rock" element={<Rock />}/>
                        <Route path="/blues" element={<Blues />}/>
                        <Route path="/jazz" element={<Jazz />}/>
                        <Route path="/country" element={<Country/>}/>
                        <Route path="concerts" element={<Concerts/>}/>
                        <Route path="/performing-arts" element={<PerformingArts/>}/>
                        <Route path="/brewery" element={<Brewery/>}/>
                        <Route path="/comedy" element={<ComedyEntertainment/>}/>
                        <Route path="/family" element={<Family/>}/>
                        <Route path="/things-to-do/rock/:sub" element={<Rock />}/>
                        <Route path="/things-to-do/blues/:sub" element={<Blues />}/>
                        <Route path="/things-to-do/jazz/:sub" element={<Jazz />}/>
                        <Route path="/things-to-do/country/:sub" element={<Country/>}/>
                        <Route path="/things-to-do/concerts/:sub" element={<Concerts/>}/>
                        <Route path="/performing-arts/:sub" element={<PerformingArts/>}/>
                        <Route path="/brewery/:sub" element={<Brewery/>}/>
                        <Route path="/comedy/:sub" element={<ComedyEntertainment/>}/>
                        <Route path="/family/:sub" element={<Family/>}/>
                        <Route path="/car-rental/:sub" element={<CarRental/>}/>
                        {/* <Route path="/car-rental" element={<CarRental/>}/> */}

                        <Route path="/flight" element={<Flight/>}/>
                        <Route path="/flight/:sub" element={<Flight/>}/>
                        <Route path="/hotel" element={<Hotels/>}/>
                        <Route path="/hotel/:sub" element={<Hotels/>}/>
                        {/* new hotel page */}
                        <Route path="/hotels/:sub" element={<Hotel_Go_to/>}/>
                    </Route>
                    <Route path="*" element={<ErrorPage/>} />
                </Routes>
        </>
    )
}
export default CreateRoutes;

import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from 'react-owl-carousel';
import { useSelector } from "react-redux";

function LocalMusic() {
  const place = useSelector(state=>state.place)
  var settings = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
        0: {
            items: 1
        },
        768: {
            items: 2
        },
        992: {
            items: 4
        }
    }
  };
  return (
    <Fragment>
      <div className="flight-sec exitment-sec">
        <div className="slider-sec">
          <h2>Local Music</h2>
          <p>Enjoy the music of New York local artist</p>
          <OwlCarousel {...settings}>
            <div data-aos="zoom-in-right " className=" slider-main local">
              <div className="slider-block">
              <Link to={`/things-to-do/rock/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/local-music4.jpg`} alt="Rock" /></Link>
                <div className="slider-content">
                  <Link to={`/things-to-do/rock/${place}`}><h3>Rock</h3></Link>
                  <a>
                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                  </a>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-right" className=" slider-main local">
              <div className="slider-block">
              <Link to={`/things-to-do/blues/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/local-music3.jpg`} alt="Blues" /></Link>
                <div className="slider-content">
                <Link to={`/things-to-do/blues/${place}`}><h3>Blues</h3></Link>
                  <a>
                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                  </a>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-right" className=" slider-main local">
              <div className="slider-block">
              <Link to={`/things-to-do/country/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/local-music1.jpg`} alt="Country" /></Link>
                <div className="slider-content">
                <Link to={`/things-to-do/country/${place}`}><h3>Country</h3></Link>
                  <a>
                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                  </a>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-right" className=" slider-main local">
              <div className="slider-block">
              <Link to={`/things-to-do/jazz/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/local-music2.jpg`} alt="Jazz" /></Link>
                <div className="slider-content ">
                <Link to={`/things-to-do/jazz/${place}`}><h3>Jazz</h3></Link>
                  <a>
                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                  </a>
                </div>
              </div>
            </div>
            <div data-aos="zoom-in-right" className=" slider-main local">
              <div className="slider-block">
              <Link to={`/things-to-do/concerts/${place}`}><img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/concert.png`} alt="Concerts" /></Link>
                <div className="slider-content">
                <Link to={`/things-to-do/concerts/${place}`}><h3>Concerts</h3></Link>
                  <a>
                    {/* <i className="fa fa-star-o" aria-hidden="true"></i> */}
                  </a>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </Fragment>
  );
}
export default LocalMusic;

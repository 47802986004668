import React, { Fragment } from "react";

import Autocomplete from "react-google-autocomplete";
import Winery from "./winery/Winery";
import Comedy from "./comedy/Comedy";
// import DigInto from "./digInto/DigInto";
import LocalMusic from "./localMusic/LocalMusic";
import TourTravel from "./tourTravel/TourTravel";
import EscapeRoom from "./escapeRoom/EscapeRoom";
// import WhereToStay from "./whereToStay/WhereToStay";
// import PopularThings from "./popularThings/PopularThings";
import AdrenalineRush from "./adrenalineRush/AdrenalineRush";
import FamilyAttraction from "./familyAttraction/FamilyAttraction";
import PopularEntertainment from "./popularEntertainment/PopularEntertainment";
// import BannerSec from "../restaurent/bannerSec/BannerSec";
import { UsePlaceAuth } from "../../../AppContext/AppContext";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { apiData } from "../../../actions";
import DataLoader from "../../../Utils/DataLoader";
import { Link, useNavigate } from "react-router-dom";
import { Rate } from "antd";
import { Things_to_do, setPlace } from "../../../Redux/Action/Action";
import { useDispatch, useSelector } from "react-redux";
import OwlCarousel from 'react-owl-carousel';
// import dig from "../../../../src/demo/dig.png"

function ThingsToDo() {
  const dispatch = useDispatch();
  // const { active, setActive } = UsePlaceAuth()
  const { setGameCatagory } = UsePlaceAuth()
  const TopDealsRef = useRef(null);
  const [popularDeal, setPopularDeal] = useState([])
  const [sportsFont, setSportsFont] = useState([])
  const [sportsFontAll, setSportsFontAll] = useState()
  const [loader, setLoader] = useState(false);
  const place = useSelector(state => state.place)
  const [keyword, setKeyword] = useState("")
  // let [selectedplace, setSelectedPlace] = useState("")
  let { SportsKeyword, setSportsKeyword } = UsePlaceAuth()
  const { BannerPic, setBannerPic } = UsePlaceAuth()

  let navigate = useNavigate();
  const options123 = {
    items: 4,
    loop: true,
    autoplay: true,
    autoplayTimeout: 2000,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1
      },
      768: {
        items: 2
      },
      1024: {
        items: 4
      }
    }
  };
  const settings = {
    slidesToShow: 4, // Number of items to display in the carousel
    loop: true, // Infinite loop
    autoplay: false, // Autoplay enabled
    autoplayTimeout: 2000, // Autoplay interval in milliseconds
    autoplayHoverPause: true, // Pause autoplay on hover
    nav: true, // Display navigation arrows
    dots: false, // Hide navigation dots
    responsive: { // Responsive breakpoints
      0: { // Breakpoint from 0px and up
        items: 1 // Display 1 item
      },
      600: { // Breakpoint from 600px and up
        items: 2 // Display 2 items
      },
      1000: { // Breakpoint from 1000px and up
        items: 4 // Display 4 items
      }
    }
  };

  const BannerImage = async () => {
    setLoader(true);
    let payload = {
      city: place,
    };
    let apiRes = await apiData.Ban_Image(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setBannerPic(apiRes?.data?.hits[0]?.largeImageURL);
      setLoader(false);
    }
  }
  const handlePopularDealClick = async (e, keyword) => {
    setLoader(true);
    let payload = {
      city: place,
      keyword: e.target.alt,
      limit: 12
    };
    let apiRes = await apiData.getYelpDeals(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setPopularDeal(apiRes?.data?.data?.businesses);
      setLoader(false);
    }
  }
  const handleScroll = () => {
		TopDealsRef.current.scrollIntoView({ behavior: "smooth" })
	}
  const GetTickets = async () => {
    let payload = {
      city: place,
    };

    let apiRes = await apiData.Sports_Tickets_Things_To_Do(payload);
    // console.log("apiRes?.data?.data[0]---",apiRes?.data?.data)
    if (apiRes?.status >= 200 && apiRes?.status <= 399 && apiRes?.data?.data?.length > 0) {
      setLoader(true)
      setSportsFont(
        Object.keys(apiRes?.data?.data[0])
      );
      // console.log("apiRes?.data?.data[0]",apiRes?.data?.data[0])
      setSportsFontAll(
        apiRes?.data?.data[0]
      );
      let res = Object.keys(apiRes?.data?.data[0]);
      setLoader(false);
    }
    if (apiRes?.statu0s >= 400 && apiRes?.status <= 500) {
      console.log("error");
    }
  };

  const handleSearch = (e) => {
    if (e.key.toLowerCase() === "enter") {
      e.preventDefault();
      navigate(`/things-to-do/${e.target.value.toLowerCase()}`)

      dispatch(setPlace(e.target.value.toLowerCase()));
    }
  }

  useEffect(() => {
    dispatch(Things_to_do())
    if (place) {
      GetTickets()
    }
    BannerImage()
  }, [place])
  return (

    <Fragment>

      <div className="banner-section rest-sec things" style={{ backgroundImage: BannerPic ? `url(${BannerPic})` : "" }}>
        <div className="container">

          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
                  <div className="select-form restaurent-sec">
                    <form>
                      <div className="choose-sec">
                        <Autocomplete
                          className="form-control"
                          apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"

                          placeholder={place.replace(/\b\w/g, x => x.toUpperCase())}
                          onPlaceSelected={async (city) => {
                            await dispatch(setPlace((city.formatted_address.split(',')[0]).toLowerCase()));
                            navigate(`/things-to-do/${(city.formatted_address.split(',')[0]).toLowerCase()}`)
                          }}
                          onKeyPress={handleSearch}
                        />
                      </div>
                    </form>
                  </div>
                  {/* <div className="action-sec" >
                    <a onClick={() => { setPlace(selectedplace) }}>Search</a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="adver-sec">
        <div className="container">
          <div className="row">
            <div className="">
              <a href="https://www.anrdoezrs.net/click-8265264-13820699" target="_top">
                <img src="https://www.lduhtrp.net/image-8265264-13820699" width="728" height="90" alt="Vrbo" border="0" /></a>
            </div>
          </div>
        </div>
      </div>
      <div className="itemBox" style={{ marginTop: "10px", marginBottom: "10px" }}>
        <div className="rind-the-right-section comedy-sec">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                <div className="white-box-area inner rest-deals">
                  <div className="row">
                    <div className="col-lg-12 col-12">
                      <section className="client-sec comedy">

                        <Fragment>
                          <TourTravel />
                          <AdrenalineRush />
                        </Fragment>
                        <div className="flight-sec exitment-sec">
                          <div className="container">
                            <div className="slider-sec">
                              <h2>Sports Tickets</h2>
                              <p>Enjoy the local pass time</p>
                              {sportsFontAll && (
                                <div className="slider-main">
                                  <OwlCarousel {...options123}>
                                    {Object.keys(sportsFontAll)?.map((ele) => {
                                      return (
                                        <div key={ele} data-aos="zoom-in-right" className="slide-things2">
                                          <Link to={place ? `/sports/${place}` : `/sports`}>
                                            <div className="slider-block" onClick={(e) => {
                                              setSportsKeyword(sportsFontAll[ele]);
                                              setGameCatagory(sportsFontAll[ele]);
                                              console.log("Clicked on:", ele); // Check if onClick event is triggered
                                            }}>
                                              {ele === "MLB" && <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/mlb.jpg`} alt="MLB" />}
                                              {ele === "NBA" && <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/nba.jpg`} alt="NBA" />}
                                              {ele === "NFL" && <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/nfl.jpg`} alt="NFL" />}
                                              {ele === "NHL" && <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/nhl.jpg`} alt="NHL" />}
                                              {ele === "MLS" && <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/mls.jpg`} alt="MLS" />}
                                              {ele === "CFL" && <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/cfl.jpg`} alt="CFL" />}
                                              <div className="slider-content local">
                                                <h3>{ele}</h3>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                      );
                                    })}
                                  </OwlCarousel>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="flight-sec exitment-sec">
                          <div className="slider-sec">
                            <h3>Popular things to do</h3>
                            <p>
                              Find fun places to see and things to do experience the art, museums,
                              music, zoo
                            </p>
                            <div className="col-12 col-md-12 col-lg-12">
                              <OwlCarousel {...settings}>
                                <div data-aos="zoom-in-right" className="slider-main">
                                  {/* <a> */}
                                    <div className="slider-block clickable" onClick={(e) => { setKeyword("Museum"); handlePopularDealClick(e); handleScroll(); }}>
                                      <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/things1.png`} alt="Museum" />
                                      <div className="slider-content">
                                        <h3>Museum</h3>
                                      </div>
                                    </div>
                                  {/* </a> */}
                                </div>
                                <div data-aos="zoom-in-right " className="slider-main">
                                  <div className="slider-block clickable" onClick={(e) => { setKeyword("Sightseeing"); handlePopularDealClick(e); handleScroll(); }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/seeingtours.png`} alt="Sightseeing" />
                                    <div className="slider-content">
                                      <h3>Sightseeing/tours</h3>
                                    </div>
                                  </div>
                                </div>
                                <div data-aos="zoom-in-right " className="slider-main">
                                  <div className="slider-block clickable" onClick={(e) => { setKeyword("Day trip"); handlePopularDealClick(e); handleScroll(); }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/ttd_img3.png`} alt="Day trip" />
                                    <div className="slider-content">
                                      <h3>Day Trip</h3>
                                    </div>
                                  </div>
                                </div>
                                <div data-aos="zoom-in-right" className="slider-main">
                                  <div className="slider-block" onClick={(e) => { setKeyword("Top attractions"); handlePopularDealClick(e); handleScroll(); }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/ttd_img4.png`} alt="Top attractions" />
                                    <div className="slider-content">
                                      <h3>Top Attractions</h3>
                                    </div>
                                  </div>
                                </div>
                                <div data-aos="zoom-in-right " className="slider-main">
                                  <div className="slider-block clickable" onClick={(e) => { setKeyword("NightLife"); handlePopularDealClick(e); handleScroll(); }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/Nightlife.png`} alt="NightLife" />
                                    <div className="slider-content">
                                      <h3>Night Life</h3>
                                    </div>
                                  </div>
                                </div>
                                <div data-aos="zoom-in-right " className="slider-main">
                                  <div className="slider-block clickable" onClick={(e) => { setKeyword("Shopping"); handlePopularDealClick(e); handleScroll(); }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/Shopping.png`} alt="shopping" />
                                    <div className="slider-content">
                                      <h3>Shopping</h3>
                                    </div>
                                  </div>
                                </div>
                                <div data-aos="zoom-in-right" className="slider-main">
                                  <div className="slider-block clickable" onClick={(e) => { setKeyword("Fine dinning"); handlePopularDealClick(e); handleScroll(); }}>
                                    <img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/fine-dining.jpg`} alt="Fine dining" />
                                    <div className="slider-content ">
                                      <h3>Fine Dining</h3>
                                    </div>
                                  </div>
                                </div>
                              </OwlCarousel>
                            </div>
                          </div>
                        </div>
                        <PopularEntertainment />
                        <LocalMusic />
                        <div className="adver-sec">
                          <div className="container">
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <a href="https://www.kqzyfj.com/click-8265264-15098222" target="_top">
                                  <img src="https://www.tqlkg.com/image-8265264-15098222" width="728" height="90" alt="" border="0" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <EscapeRoom />
                        <FamilyAttraction />
                        <Winery />
                        <Comedy />
                        <div className="adver-sec">
                          <div className="container">
                            <div className="row">
                              <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                                <a href="https://www.tkqlhce.com/click-8265264-13167950" target="_top">
                                  <img src="https://www.lduhtrp.net/image-8265264-13167950" width="728" height="90" alt="" border="0" /></a>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* these are now static,later do these things dynamic */}
                        {/* <DigInto /> */}
                        {/* <WhereToStay />  */}
                        <div className="slider-main" ref={TopDealsRef}>
                          {
                            keyword ?
                              <div className="slider-heading">
                                {
                                  <h3>{`${keyword} in ${place.replace(/\b\w/g, x => x.toUpperCase())}`}</h3>
                                }
                              </div> : ""
                          }
                          {
                            !loader ? <div className="row" >
                              {
                                popularDeal && popularDeal?.map((ele, index) =>

                                  <div key={index} className="col-12 col-sm-6 col-md-4 col-lg-3">
                                    <a href={ele?.url}><div data-aos="zoom-in-right" className="slider-block">

                                      <img src={ele?.image_url} alt="" />
                                      <div className="slider-content">
                                        <h3>{ele?.name}</h3>
                                      </div>
                                      <div className="stars">
                                        <ul>
                                          <li>
                                            <Rate
                                              allowHalf
                                              defaultValue={ele?.rating}
                                              disabled
                                            />
                                          </li>
                                        </ul>
                                        <p>{ele?.review_count} Reviews</p>
                                      </div>
                                    </div></a>
                                  </div>
                                )
                              }
                            </div>
                              : <DataLoader />
                          }
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default ThingsToDo;

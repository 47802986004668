import React, { Fragment, useEffect, } from "react";
import { UsePlaceAuth } from "../../../AppContext/AppContext";
import { useState } from "react";
import { apiData } from "../../../actions";
import { useSelector } from "react-redux";

const CarRentalBanner = () => {
  const place = useSelector(state=>state.place)
	const [loader, setLoader] = useState(false)
	const [rentalScript, setRentalScript] = useState(false)

  const GetDeals = async () => {
    setLoader(true)
    let payload = {
        city: place,
      };
    let apiRes = await apiData.getCarRental(payload);
    if (apiRes?.status >= 200 && apiRes?.status <= 399) {
      setRentalScript(
        apiRes?.data?.data.split('"')[1]
        );
        setLoader(false);
    }
    if (apiRes?.status >= 400 && apiRes?.status <= 500) {
        console.log("error");
    }
};
    useEffect(() => {
      document.getElementById("CarRentalBanner").innerHTML=""
      let script = document.createElement('script');
      setLoader(true)
      script.src=rentalScript
      document.getElementById("CarRentalBanner").appendChild(script);
      setLoader(false)
      GetDeals();
    }, [place,rentalScript]);
  return (
    <Fragment>
    <div id="CarRentalBanner">
    </div>
    </Fragment>
  )
}
export default CarRentalBanner
import React, { Fragment } from 'react'
import Blog from './Blog'
import BlogBanner from './BlogBanner'

const BlogMain = () => {
  return (
    <div>
      <Fragment>
        <BlogBanner/>
        <Blog/>
      </Fragment>
    </div>
  )
}

export default BlogMain

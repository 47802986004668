import React, { Fragment, useEffect, useRef, useState } from "react";

import AllFoodSliders from "./allFoodSliders/AllFoodSliders";
import JsonData from "../../restaurent/jsonFilterData";
import DynamicDataFilteration from "../../restaurent/foodFilteration/dynaminDataFilteration";

import { apiData } from "../../../../actions";
import { UsePlaceAuth } from "../../../../AppContext/AppContext";
import { Modal } from "react-bootstrap";
import CuisineModalData from "../CuisineModalData";
import Autocomplete from "react-google-autocomplete";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setPlace } from "../../../../Redux/Action/Action";
import DataLoader from "../../../../Utils/DataLoader";

function FoodFilteration() {
  const [selectedCheckbox, setSelectedCheckbox] = useState("");
  const [sliderData, setSliderData] = useState();
  const [testing, setTesting] = useState([]);
  const [scrollComponent, setScrollComponent] = useState(false);
  const DataJson = JsonData ?? [];
  const CuisineBar = CuisineModalData ?? [];
  const place = useSelector(state => state.place)
  const [cuisineModalState, setCuisineModalState] = useState(false);
  const [menu, setMenu] = useState("");
  const [data, setData] = useState("");
  const [loader, setLoader] = useState(false);
  const [loadMoreLoader, setLoadMoreLoader] = useState(false);
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let LoadMoreBtn = useRef();
  useEffect(() => {
    if (window.screen.width < 990) {
      document.getElementById("MenuIcon").style.display = "block";
      document.getElementById("MenuBar").style.display = "none";
    } else {
      document.getElementById("MenuIcon").style.display = "none";
      document.getElementById("MenuBar").style.display = "block";
    }
  }, []);

  const handleClick = async (childdata) => {
    setSelectedCheckbox(childdata);
    setData(childdata);
    let payload = {
      city: place,
      keyword: childdata,
    };
    if (childdata) {
      let apiRes = await apiData.restaurantDealsFilterSearch(payload);
      setSliderData(apiRes);
      const element = document.getElementById("my-element");
      element.scrollIntoView({ behavior: "smooth" });

    }
  };
  const handleDeliveryType = (e) => {
    setMenu(e.target.value);
    const { checked } = e.target;
    // console.log('checked', checked);
    setCuisineModalState(false);
    setScrollComponent(checked);
    if (checked) {
      const element = document.getElementById("my-element");
      element.scrollIntoView({ behavior: "smooth" });

    }
    const getValue = e.target.value;
    setSelectedCheckbox(getValue);
    setTesting((prevState) => {
      return checked
        ? [prevState, getValue]
        : prevState?.filter((item) => item !== getValue);
    });

  };
  const FilterData = async (e) => {
    setCuisineModalState(false);
    setLoader(true);
    let payload = {
      city: place,
      keyword: e.target.value,
      limit: 5,
    };
    let apiRes = await apiData.restaurantDealsFilterSearch(payload);
    setSliderData(apiRes);
    setLoader(false);
    // Delay scrolling by a short timeout (e.g., 100ms) to ensure content rendering
    // setTimeout(() => {
    const element = document.getElementById("my-element");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    // }, 10);
  };
  // test checkbox

  //   let handleClick= async (e)=>{
  //      setMenu(e.target.value)
  //     setSelectedCheckbox(e.target.value);
  //     let payload = {
  //         city: place,
  //         keyword: e.target.value,
  //       };
  //       let apiRes = await apiData.restaurantDealsFilterSearch(payload);
  //       setSliderData(apiRes);
  //       const element = document.getElementById("my-element");
  //       element.scrollIntoView({ behavior: "smooth" });
  // }
  const handleMenuIcon = () => {
    if (window.screen.width < 990) {
      if (document.getElementById("MenuBar").style.display === "none") {
        document.getElementById("MenuBar").style.display = "block";
      } else {
        document.getElementById("MenuBar").style.display = "none";
      }
    }
  };
  const handleMobileMenuClick = () => {
    if (window.screen.width < 990) {
      document.getElementById("MenuBar").style.display = "none";
    }
  };
  const [isLoader,setIsLoader]=useState(true);
  const loadMore = async () => {
    try {
      setLoadMoreLoader(true);
      let payload = {
        city: place,
        keyword: selectedCheckbox,
        limit: 20,
      };
      let apiRes = await apiData?.restaurantDealsFilterSearch(payload);
      setSliderData(apiRes);
      setLoadMoreLoader(false); 
      setIsLoader(false);
      // LoadMoreBtn.current.style.display = "none";
    } catch (error) {
      console.error("Error loading more data:", error);
    } finally {
      setLoadMoreLoader(false); 
      if (LoadMoreBtn.current) {
        console.log("Hiding the button...");
        // LoadMoreBtn.current.style.display = "none";
        setIsLoader(false);
      } else {
        console.warn("LoadMoreBtn ref is null or undefined.");
      }
    
    }
  };
  const handleSearch = (e) => {
    if (e.key.toLowerCase() == "enter") {
      e.preventDefault();
      navigate(`/restaurant/${e.target.value.toLowerCase()}`);
      dispatch(setPlace(e.target.value.toLowerCase()));

    }
  };
  return (
    <Fragment>
      <div className="itemBox">
        <div className="rind-the-right-section comedy-sec">
          <div className="container">
            <div className="row">
              <button
                onClick={handleMenuIcon}
                id="MenuIcon"
                style={{ display: "none" }}
              >
                <i className="fa fa-bars" aria-hidden="true">
                  {" "}
                  Catagory
                </i>
              </button>
              <div
                className="col-xs-12 col-sm-12 col-md-12 col-lg-3 "
                id="MenuBar"
              >
                <div className="sidebar-listing">
                  <div className="specialities-checkbox">
                    <div className="searcher-sec">
                      <label className="custom-control-label">
                        Search By Name
                      </label>
                      <div className="form-group">
                        <div className="choose-sec">
                          <Autocomplete
                            className="form-control"
                            apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
                            placeholder={place.replace(/\b\w/g, (x) =>
                              x.toUpperCase()
                            )}
                            onPlaceSelected={(city) => {
                              navigate(
                                `/restaurant/${city.formatted_address
                                  .split(",")[0]
                                  .toLowerCase()}`
                              );
                              setPlace(
                                city.formatted_address
                                  .split(",")[0]
                                  .toLowerCase()
                              );
                            }}
                            onKeyPress={handleSearch}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {DataJson && (
                    <div className="specialities-checkbox">
                      {DataJson.map((ele, ind) => (
                        <Fragment key={ind}>
                          <div className="listing-check">
                            <h4>{ele?.heading}</h4>
                            {ele?.data?.map((data, index) => (
                              <div className="custom-control custom-checkbox" key={index}>
                                {!data.more && (
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={data?.label}
                                    value={data?.label}
                                    checked={testing?.includes(data?.label)}
                                    onChange={(e) => {
                                      handleDeliveryType(e);
                                      FilterData(e);
                                    }}
                                    onClick={handleMobileMenuClick}
                                  />
                                )}
                                <label
                                  className="custom-control-label"
                                  htmlFor={data?.label}
                                >
                                  <a className="clickable"
                                    onClick={() => {
                                      setCuisineModalState(true);
                                      handleMobileMenuClick();
                                    }}
                                  >
                                    {data?.more}
                                  </a>
                                  {data?.label}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Fragment>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
                <div className="white-box-area inner rest-deals">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <div className="slider-content">
                        {/* <div className="content-sec">
                        <p>Showing 3 of 257 places</p>
                        <div className="custom-select-box">
                          <span>Sort by </span>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                          >
                            <option value="">Recommended</option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                          </select>
                        </div>
                      </div> */}
                      </div>
                    </div>
                    {!loader ?
                    <div className="col-lg-12 col-12">
                    <AllFoodSliders handleClick={handleClick} />
                    {/* scrollPage */}
                    <div id="my-element">
                      {sliderData && sliderData.length > 0 && (
                        <>
                          <DynamicDataFilteration
                            {...{ selectedCheckbox, filterData: sliderData }}
                          />
                          {isLoader && <div className="view-tag">
                            {/* "Load More" button with loader */}
                            {loadMoreLoader ? (
                              <a>Loading...</a>
                            ) : (
                              <a
                                ref={LoadMoreBtn}
                                onClick={() => {
                                  loadMore();
                                }}
                                style={{'cursor':'pointer'}}
                              >
                                Load More
                              </a>
                            )}
                          </div>}
                        </>
                      )}
                    </div>
                  </div>
                  
                      : <DataLoader />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* modal section starts here */}
      <>
        <Modal
          show={cuisineModalState}
          onHide={() => setCuisineModalState(false)}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cuisine</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            <div className="us-city-popupd row">
              {CuisineBar &&
                CuisineBar?.map((ele, index) => {
                  return (
                    <div className="col-sm-6 col-md-6 col-xs-6">
                      <input
                        type="checkbox"
                        id={index + 1}
                        value={ele?.type}
                        onChange={(e) => {
                          handleDeliveryType(e);
                          FilterData(e);
                        }}
                      />{" "}
                      <label
                        htmlFor={index + 1}
                      >{ele?.type}</label>
                    </div>
                  );
                })}
            </div>
          </Modal.Body>
        </Modal>
      </>
    </Fragment>
  );
}

export default FoodFilteration;

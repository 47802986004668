import React, { useEffect, useState } from 'react'
import Slider from 'react-slick'
import OwlCarousel from 'react-owl-carousel';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import { UsePlaceAuth } from '../../../AppContext/AppContext'
// import AdverSec from '../restaurent/adverSec/AdverSec'
import AppPromo from '../restaurent/appPromo/AppPromo'
// import BannerSec from '../restaurent/bannerSec/BannerSec'
import CarRentalBanner from './CarRentalBanner'
import brand1 from "../../../../src/demo/brand1.png"
import brand2 from "../../../../src/demo/brand2.png"
import brand5 from "../../../../src/demo/brand5.png"
import brand6 from "../../../../src/demo/brand6.png"
import multipleCar from "../../../../src/demo/multiple-car.png"
import { apiData } from '../../../actions'
import { Car_Rentals } from '../../../Redux/Action/Action'
import { useDispatch } from 'react-redux'


const CarRental = () => {
	// const { active, setActive } = UsePlaceAuth()
	const [CoolDeals, setCoolDeals] = useState([]);
	const [loader, setLoader] = useState(false);
	const dispatch = useDispatch();
	const CoolFlightDeals = async () => {
		setLoader(true)
		let apiRes = await apiData.coolFlightDeals();
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setCoolDeals(
				apiRes?.data?.data
			);
			setLoader(false);
		}
		if (apiRes?.status >= 400 && apiRes?.status <= 500) {
			console.log("error");
		}
	};
	const settings1 = {
		loop: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		nav: true,
		dots:false,
		autoplay: true,
		autoplaySpeed: 2000,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 499,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};
	const settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		autoplay: true,
		
		autoplaySpeed: 2000, // Adjust the autoplay speed as needed
	  };

	useEffect(() => {
		CoolFlightDeals();
		dispatch(Car_Rentals())
	}, [])
	return (
		<div>
			<div className="banner-section rest-sec car-banner">
				<CarRentalBanner />

			</div>
			<div className="adver-sec car-rental">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12">
						
							<a href="https://www.dpbolvw.net/click-8265264-15514929" target="_top">
								<img src="https://www.lduhtrp.net/image-8265264-15514929" width="728" height="90" alt="" border="0" />
							</a>
						</div>
					</div>
				</div>
			</div>
			<div className="offer-section  car-rental-content">
				<div className="container">
					<div className="row">
						<div className="slider-sec">
						{/* </div> */}
						<div className="col-12 col-lg-12">
							<div className="slider-main">
								{/* <div  id="ProductSlide"> */}
									{CoolDeals.length>0 && <OwlCarousel {...settings1}>
										{
											CoolDeals && CoolDeals?.map((ele) => {
												return (
													<div data-aos="zoom-in-right" className="testimonial-block product">
														<a href={ele.url}>
															<div className="discount-block">
																<img src={ele.image_url} alt="cool flight deals" />								
																	<h3>{ele.title}</h3>
															
															</div>
														</a>
													</div>
												)
											})
										}
									</OwlCarousel>}
								{/* </div> */}
							</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div className="flight-sec">
		<div className="container">
			<div className="row">
				<div className="col-12 col-md-4 col-lg-4">
					<div data-aos="zoom-in-right" className="ads-sec">
						<img src="/images/ads.png" alt=''/>
						<div className="ads-content">
							<p>Enjoy these cool staycation promotions <span>Best Staycation Deals</span></p>
							<a href="#">See Activities <img src="/images/right.svg" alt=''/></a>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 col-lg-4">
					<div data-aos="zoom-in-left" className="ads-sec">
						<img src="/images/ads2.png"alt=''/>
						<div className="ads-content">
							<p>Don’t forget to check out these activities. <span>All Time Favourite Activities In Dubai</span></p>
							<a href="#">See Activities <img src="/images/right.svg" alt=''/></a>
						</div>
					</div>
				</div>
				<div className="col-12 col-md-4 col-lg-4">
					<div data-aos="zoom-in-right" className="ads-sec">
						<img src="/images/ads-last.png" alt=''/>
						<div className="ads-content">
							<p>50% Discount <span>Discover The Wow Of Europe</span></p>
							<a href="#">See Activities <img src="/images/right.svg"alt=''/></a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div> */}

			<div className="car-sec">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-12">
            <h2>Rent With Companies You Know and Trust</h2>
            {/* <Slider {...settings}> */}
				<ul>
					<li>
					<img src="/images/brand1.png" alt="Brand 1" />
				</li>
				<li>
                <img src="/images/brand2.png" alt="Brand 2" />
              </li>
              <li>
                <img src="/images/brand5.png" alt="Brand 5" />
              </li>
              <li>
                <img src="/images/brand6.png" alt="Brand 6" />
              </li>
				</ul>
             
              
            {/* </Slider> */}
            <img src="/images/cars.png" alt="Cars" />
          </div>
        </div>
      </div>
    </div>
			<AppPromo />
		</div>
	)
}
export default CarRental
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

const OtherModal=({otherModal,SetOtherModal})=>{


  return (
    <>
      <Modal
        show={otherModal}
        onHide={()=>SetOtherModal(false)}
        backdrop="static"
        size="lg"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title> Other terms and conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <p><span style={{lineHeight:"1.6em"}}>If you opt to use the FanReach Service, you will be bound by the following Terms and Conditions in addition to the General Terms and Conditions. Any capitalized term which has not been defined below, will have the same meaning as defined in the </span><a href="https://www.mysittivacations.com/generalterms%20and%20conditions" style={{lineHeight: "1.6em"}}>General Terms and Conditions</a><span style={{lineHeight:"1.6em"}}>. IF YOU DO NOT AGREE TO THESE FANREACH TERMS, DO NOT USE THE FANREACH SERVICE.</span></p>

<p><strong>1. Description of Service:</strong></p>

<p>If you are a registered user of this Site, you can use the FanReach Service to send e-mail newsletters to your targeted fans. You can get more information about the FanReach Service on our Site.</p>

<p><strong>2. Content</strong></p>

<p>You are solely responsible for all content you submit in your FanReach Service emails (&ldquo;User Content&rdquo;). You will retain all rights in and to the User Content provided by you in connection with the FanReach Service. Without limitation, your submission and our use of your content in connection with the FanReach Service is subject to all applicable representation, warranties, and agreements thereunder. We reserve the right to include additional content, branding, and promotional messages in your FanReach Service emails, including, for example, an identifying footer stating &ldquo;Powered by FanReach.&rdquo; All right, title, and interest in and to our templates and other related FanReach Service materials are owned by us.</p>

<p><strong>3. Email Monitoring and Maintenance</strong></p>

<p>You are responsible for monitoring, correcting, and updating your FanReach Service mailing list. FanReach does not regularly verify the content of all your emails. However, you acknowledge and authorize us to inspect your emails, from time to time, to ensure compliance with our internal anti-spam rules, other policies, and these FanReach Terms. You understand that such inspections may cause a delay in delivery of the emails, which you agree. We will not use your FanReach Service mailing list or any other fan information for any purposes except as set forth herein.</p>

<p><strong>4. CAN-SPAM Act</strong></p>

<p>You agree not to send any mail to anyone without obtaining their prior consent to receive e-mail from you. You may not use the FanReach Service to send any communications that violate the Controlling the Assault of Non-Solicited Pornography and Marketing Act of 2003 (the &ldquo;CAN-SPAM&rdquo; Act), or other similar laws of any state or other jurisdiction that restrict unsolicited commercial or bulk email advertising.</p>

<p><strong>5. Opt-Out Procedure</strong></p>

<p>You agree not use FanReach Service for sending unsolicited/SPAM email. You must provide a clear and conspicuous &ldquo;Unsubscribe&rdquo; link in every e-mail that you send using FanReach Service, which provides the receiver the Opt-Out benefit and stop receiving such emails. Once the receiver opts out, you agree to remove such email addresses from your FanReach Service mailing list. We reserve the right to and also may automatically remove, those emails that submit unsubscribe requests.</p>

<p><br />
&nbsp;</p>

<p><strong>6. Restrictions</strong></p>

<p>Without limiting any of your obligations under the <a href="https://www.mysittivacations.com/generaltermsandconditions">General Terms and Conditions</a>, you agree that you will not send any email message using FanReach Service that:</p>

<p style={{marginLeft:"1.27cm"}}><strong>(a)</strong> transmits, solicits, sells, or promotes any material, products, or services that violate United States federal, state or other laws that may apply in any jurisdiction or your or an email recipient&rsquo;s local area;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(b)</strong> is unlawful, threatening, abusive, harassing, defamatory, libellous, deceptive, fraudulent, invasive of another&#39;s privacy, tortuous, contains explicit or graphic descriptions or accounts of sexual acts (including but not limited to sexual language of a violent or threatening nature directed at another individual or group of individuals) or provide links to adult contents elsewhere, or otherwise violates mysittivacations&#39;s rules or policies;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(c)</strong> victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;</p>


<p style={{marginLeft:"1.27cm"}}><strong>(e)</strong> displays material that exploits, or discloses any personally identifying information about, children;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(f)</strong> provides, sells, or offers products, services, or content frequently associated with unsolicited commercial email, or SPAM;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(g)</strong> constitutes unauthorized or unsolicited advertising;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(h)</strong> solicits Site users to register or sign up with another website, platform, or other service or entity for any reason;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(i)</strong> impersonates any person or entity, including any of our employees or representatives;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(j)</strong> introduces viruses, worms, harmful code, and/or Trojan horses on the Internet; or</p>

<p style={{marginLeft:"1.27cm"}}><strong>(k)</strong> victimizes, harasses, degrades, or intimidates an individual or group of individuals on the basis of religion, gender, sexual orientation, race, ethnicity, age, or disability;.</p>

<p>For questions regarding these FanReach Terms, please contact <a href="mailto:support@mysittivacations.com">support@mysittivacations.com</a></p>

<p><br />
&nbsp;</p>

<p><strong>MobileApp Terms and Conditions</strong></p>

<p>If you opt to use the MobileApp Service, you will be bound by the following Terms and Conditions in addition to the General Terms and Conditions. Any capitalized term which has not been defined below, will have the same meaning as defined in the <a href="https://www.mysittivacations.com/generalterms%20and%20conditions">General Terms and Conditions</a>. IF YOU DO NOT AGREE TO THESE MOBILEAPP TERMS, DO NOT USE THE MOBILEAPP SERVICE.</p>

<p><strong>1. Description of Service</strong></p>

<p>If you are a registered user of this Site, you can use the MobileApp Service to develop a mobile application for use with mobile devices. You can get more information about the MobileApp Service on the Site.</p>

<p><strong>2. Platforms and Fees</strong></p>

<p style={{marginLeft:"1.27cm"}}><strong>(a)</strong> The MobileApp Service provides both Android application (&ldquo;Android MobileApp&rdquo;) and Apple application (&ldquo;Apple MobileApp&rdquo;) development services. We may remove certain platforms as necessary or expand and/or enhance the MobileApp Services, in our sole discretion, to provide for the creation of MobileApps that will run on other platforms.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(b)</strong> MobileApp development is chargeable. The present fee schedules are available on the Site and/or in your Dashboard.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(c)</strong> We will make your Android MobileApp available only on our Site for download. You may submit your Android MobileApp to any other mobile app providers or stores. Apple MobileApps can only be distributed in the Apple App Store in accordance with Section 4 below. We do not guarantee that your MobileApp will be accepted or distributed by any mobile app providers or store.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(d)</strong> You will not receive any payments in connection with our distribution of your MobileApp. Your MobileApp will be made available for free download on a worldwide basis.</p>

<p><strong>3. Content</strong></p>

<p>You are solely responsible for all content you submit for your MobileApp. You retain all rights in and to the User Content provided by you. Your submission and our use of your content in connection with the MobileApp Service is subject to all applicable representation, warranties, and agreements thereunder. We reserve the right to include additional content, branding, and promotional messages in your MobileApp. All right, title, and interest in and to our templates and other related MobileApp Service materials are owned by us.</p>

<p><strong>4. Apple App Store</strong></p>

<p style={{marginLeft:"1.27cm"}}><strong>(a)</strong> In order to include your Apple MobileApp in the Apple App Store, you must have a Personal Developer Account with Apple. As you have requested for this Service, you hereby authorize us to create a &ldquo;Personal Developer Account&rdquo; under your name with Apple. You agree to provide us with any information needed to establish your Personal Development Account. You authorize us to represent you in connection with the submission of your MobileApp in the Apple App Store. Fees to be paid to create this account with the Apple App Store shall be included in the Service Fee payable to us.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(b)</strong> We do not guarantee acceptance of your MobileApp by Apple. We also do not guarantee the time Apple will take to approve your App as you understand that we have no control over the scheduling of the approval process of Apple. Apple may take unduly long time to approve your MobileApp. If your MobileApp is rejected by Apple, we will make reasonable efforts to assist you with making certain modifications to the MobileApp, and resubmit your MobileApp. This is subject to payment of an additional Service Fee.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(c)</strong> If you make any changes to your MobileApp after its rejection and it necessitates resubmission of the MobileApp to Apple, we will resubmit your MobileApp subject to your payment of an additional Service Fee.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(d)</strong> Upon termination of your use of the MobileApp Services, we will close your Personal Development Account. Once your Account is terminated, we have no obligation to either answer any emails or forward to you any emails from Apple or any other third party.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(e)</strong> All apps that are distributed from the Apple App Store including your MobileApp are distributed pursuant to acceptance by the User of End User License Agreement (EULA) provided by Apple. You acknowledge and agree that we do not have control over the terms of this End User License Agreement or the acceptance of it by end users of your MobileApp.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(f)</strong> You acknowledge that in providing Apple&rsquo;s MobileApp Services, we may be required to enter into one or more agreements with Apple. You agree that these MobileApp Terms shall be subject to any applicable terms and conditions of any such agreements with Apple.</p>

<p>For questions regarding these Mobile App Terms, please contact <a href="mailto:support@mysittivacations.com">support@mysittivacations.com</a>.</p>

<p>============================================================================</p>

<p><br />
&nbsp;</p>

<p><strong>PROMOTE IT TERMS AND CONDITIONS</strong></p>

<p><br />
&nbsp;</p>

<p>If you opt to use Promote It Service, you will be bound by the following Terms and Conditions in addition to the General Terms and Conditions. Any capitalized term which has not been defined below, will have the same meaning as defined in the <a href="https://www.mysittivacations.com/generalterms%20and%20conditions">General Terms and Conditions</a>. IF YOU DO NOT AGREE TO THESE PROMOTE IT TERMS, DO NOT USE THE PROMOTE IT SERVICE.</p>

<p><strong>1. Description of Service</strong></p>

<p>If you are a registered user of this Site, you can use Promote It Service for Internet advertising. You can get additional information about the Promote It Service on the Site.</p>

<p><strong>2. Service Fees</strong></p>

<p>If you are a registered user of this Site, you can use Promote It Service in accordance with our <a href="https://www.mysittivacations.com/generalterms%20and%20conditions">General Terms and Conditions</a>. You can get information about the present fee schedule on the Site or in your Dashboard.</p>

<p><strong>3. Authorization</strong></p>

<p>In order solely to create custom Internet advertisements and place Advertisements on your behalf on any web page on a worldwide basis, you hereby appoint us as your nonexclusive authorized representative.</p>

<p><br />
&nbsp;</p>

<p><br />
&nbsp;</p>

<p><strong>4. Content</strong></p>

<p>You are solely responsible for all content you submit for Promote It Service. You retain all rights in and to the User Content provided by you. Your submission and our use of your content in connection with Promote It Service is subject to all applicable representation, warranties, and agreements thereunder. We reserve the right to include additional content, branding, and promotional messages in your Advertisements. All right, title, and interest in and to our templates and other related MobileApp Service materials are owned by us.</p>

<p><strong>5. Advertising Services</strong></p>

<p style={{marginLeft:"1.27cm"}}><strong>(a)</strong> We will help you grow your fan base, promote your show or concert, or promote a song by creating unique Advertisements for you based on your User Content. We will create one Advertisement for your review and approval. Based on your review and approval of this initial advertisement, we will create all other Advertisements and place them. We may not seek your review or approval, or show subsequent advertisements to you once you approve the initial advertisement. Your approval of the first Advertisement will be deemed as your approval of all future advertisements. If you require us to place an advertisement to promote a song, you must make available for free download at least one song. You may discontinue availing this service at any time if you are not satisfied with our Advertisements.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(b)</strong> You can fill in and submit your responses to the advertising questionnaire in our ad input interface. Based on your responses, we will organize to place the Advertisements for you and use reasonable efforts to target your Advertisements to the appropriate audience. However, we cannot inform you in advance where the Advertisements will be placed. We also cannot guarantee that any particular Advertisement can or will be placed on any particular site or web page. Usually we place the Advertisements on Facebook, Google, or other web sites. The location of the Advertisement and the time length up to which the advertising campaign will be available on any site depends on the Fee paid by you.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(c)</strong> We will provide you with a report that includes the metrics related to your Advertisements. Such metrics will include, the start date for an Advertisement; the percentage completion of the advertising campaign (e.g., the campaign is 50% completed or 75% completed), impressions, and click-throughs. Additional metrics may be included at our discretion.</p>

<p style={{marginLeft:"1.27cm"}}><strong>(d)</strong> When a User clicks on an Advertisement, he comes to landing page/ Conversion page. We will create a landing page/conversion page for you. As required by you, we will provide an option for the user to become your &ldquo;fan&rdquo; or take some other affirmative action in order to download a free song that you may provide when advertising a song.</p>

<p><strong>6. Disclaimer</strong></p>

<p>WE DO NOT GUARANTEE THE PLACEMENT OF YOUR ADVERTISEMENT ON ANY SPECIFIC PAGE OR WEB SITE OR ASSURE ANY RESULTS THAT WILL MEET YOUR EXPECTATIONS. YOU ACKNOWLEDGE AND AGREE THAT THESE PAGES AND WEBSITES BELONG TO THIRD PARTIES AND WE HAVE NO CONTROL OVER WHEN AN ADVERTISEMENT MAY APPEAR OR FOR HOW LONG AN ADVERTISEMENT MAY RUN OR BE USED. WE DO NOT PROMISE THAT THE PROMOTE IT SERVICE WILL BE PROVIDED IN A TIMELY MANNER AND WITHOUT ERRORS.</p>

<p>For questions regarding these Promote It Terms, please contact <a href="mailto:support@mysittivacations.com">support@mysittivacations.com</a></p>

<p><strong>DIGITAL E-COMMERCE TERMS AND CONDITIONS</strong></p>

<p>If you opt to use our Digital e-commerce Service, you will be bound by the following Terms and Conditions in addition to the General Terms and Conditions. Any capitalized term which has not been defined below, will have the same meaning as defined in the <a href="https://www.mysittivacations.com/generalterms%20and%20conditions">General Terms and Conditions</a>. IF YOU DO NOT AGREE TO THESE DIGITAL E-COMMERCE TERMS, DO NOT USE THE DIGITAL E-COMMERCE SERVICE.</p>

<p><strong>1. Description of Service</strong></p>

<p>If you are a registered user of this Site, you can use Digital e-commerce Service to upload music and sell it on the Site. You can get additional information about the Digital e-commerce Service on the Site.</p>

<p><strong>2. Content Submission and Authorization</strong></p>

<p>You are solely responsible for all content you submit through your Digital e-commerce Service (&ldquo;User Content&rdquo;). You will retain all rights in and to the User Content provided by you in connection with the Digital e-commerce Service. Without limitation, your submission and our use of your sound recordings of musical compositions (&ldquo;Music&rdquo;) in connection with the Digital eComm Service is subject to all applicable representation, warranties, and agreements thereunder.</p>

<p><strong>3. Sale of Music</strong></p>

<p style={{marginLeft:"1.27cm"}}>(a) Music submitted by you in connection with the Digital e-commerce Service will be made available for purchase on our Site as a permanent digital download.</p>

<p style={{marginLeft:"1.27cm"}}>(b) To the extent necessary, we may provide International Standard Recording Codes (&ldquo;ISRCs&rdquo;) or Universal Product Codes (&ldquo;UPCs&rdquo;) for your Music. These are for your use only in connection with the services provided by us and may not be transferred or resold.</p>

<p><strong>4. Purchase Price and Payments</strong></p>

<p style={{marginLeft:"1.27cm"}}>(a) You can indicate the purchase price on your Dashboard for your Music. We will offer your Music for sale at that price indicated by you, plus the applicable sales or other taxes. We will collect proceeds from the sale of your Music using the Digital e-commerce Service.</p>

<p style={{marginLeft:"1.27cm"}}>(b) We charge &ldquo;Administrative Fee&rdquo; as Service Fee for operating the Digital e-commerce Service, and the &ldquo;Payment Processing Fee&rdquo; is charged by payment processor used for the payment process. Current fee schedules are available on the Site or in your Dashboard. We deduct Administrative Fee and the Payment Processing Fee from the sale proceeds received by us and credit the balance amount to your Mysittivacations Credit account which will be maintained in accordance with and subject to the <a href="https://www.mysittivacations.com/generaltermsandconditions">General Terms and Conditions</a>. We reserve the right to change the Administrative Fee and the Payment Processing Fee at any time.</p>

<p><strong>5. Disclaimer</strong></p>

<p>WE DO NOT GUARANTEE PURCHASE YOUR MUSIC BY ANY USER OF OUR SITE.</p>

<p>For questions regarding these Digital e-commerce Terms, please contact <a href="mailto:support@mysittivacations.com">support@mysittivacations.com</a></p>

<p><strong>SITE BUILDER TERMS AND CONDITIONS</strong></p>

<p><br />
&nbsp;</p>

<p>If you opt to use our Site Builder Service, you will be bound by the following Terms and Conditions in addition to the General Terms and Conditions. Any capitalized term which has not been defined below, will have the same meaning as defined in the <a href="https://www.mysittivacations.com/generalterms%20and%20conditions">General Terms and Conditions</a>. IF YOU DO NOT AGREE TO THESE SITE BUILDER TERMS, DO NOT USE THE SITE BUILDER SERVICE.</p>

<p><strong>1. Description of Service</strong></p>

<p>If you are a registered user of this Site, you can use Site Builder for web site design, development, hosting and maintenance services. You can get additional information about the Site Bilder Service on the Site.</p>

<p><a name="ecommerce"></a> <strong>2. Web Design &amp; Hosting</strong></p>

<p>We grant to you a limited, revocable, non-transferable and non-exclusive license to use the Site Builder Service website design and build tools to create, update, and maintain a customized websites as per your requirements. We will make commercially reasonable efforts to ensure that your Site is available to Users with the same level of availability as our Site, by hosting your Site in a secure environment.</p>

<p><strong>3. Service Fees</strong></p>

<p>If you are a registered user of this Site, you can use Site Builder Service in accordance with our <a href="https://www.mysittivacations.com/generalterms%20and%20conditions">General Terms and Conditions</a>. You can get information about the present fee schedule on the Site or in your Dashboard.</p>

<p><strong>4. Content</strong></p>

<p>You are solely responsible for all content you submit for Site Builder Service. You retain all rights in and to the User Content provided by you. Your submission and our use of your content in connection with Site Builder Service are subject to all applicable representation, warranties, and agreements thereunder. We reserve the right to include additional content, branding, and promotional messages in your Site Builder website. All right, title, and interest in and to your website, website design and build tools, templates and other related Site Builder Service materials are owned by us.</p>

<p>You may sell music from your web site only using our Digital e-commerce Services, in accordance with its terms.</p>

<p>&nbsp;</p>

<p><strong>5. Rights to Purchase Domain Name</strong></p>

<p style={{marginLeft:"1.27cm"}}>&nbsp;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(a)</strong> We will obtain a domain name of your choice for you, if available. You pay upfront fee for Domain Name and web hosting services either annually or monthly for the Site Builder Services. You hereby grant us a worldwide, royalty-free, non-exclusive license to use your Domain Name to enable us to provide you with the Site Builder Service.</p>

<p style={{marginLeft:"1.27cm"}}>&nbsp;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(b)</strong> We will obtain your Domain Name from www.xxxxx.xxx. If you opt to obtain a domain name in connection with the Site Builder Services, you hereby agree to abide by the Agreement with xxxxx.xxx. Please note that this Agreement sets forth your rights to your Domain Name. You acknowledge and agree that your rights in Your Domain Name are limited to those rights conveyed to you in the xxxxx.xxx Agreement, and are subject to the limitations stated therein.</p>

<p style={{marginLeft:"1.27cm"}}>&nbsp;</p>

<p style={{marginLeft:"1.27cm"}}><strong>(c)</strong> In the event that we have obtained your domain name and your subscription to the Site Builder Services is terminated for any reason, either by us or by you, we will provide you with detailed instructions on how to obtain Your Domain Name from xxxxx.xxx. Your request to obtain Your Domain Name must be made to us within _______ (__) months of the date of your last monthly or annual payment for the Site Builder Services. Any transfer of Your Domain Name may be subject to payment of applicable transfer fees to xxxxx.xxx.</p>

<p>&nbsp;</p>

<p>For questions regarding these Site Builder Terms, please contact <a href="mailto:support@mysittivacations.com">support@mysittivacations.com</a></p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

<p>&nbsp;</p>

        </Modal.Body>
      </Modal>
    </>
  );
}
export default OtherModal
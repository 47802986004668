import React, { Fragment, useEffect, useState } from "react";
import FirstSlider from "./firstSlider/FirstSlider";
import SecondSlider from "./secondSlider/SecondSlider";
import ThirdSlider from "./thirdSlider/ThirdSlider";
import FourthSlider from "./fourthSlider/FourthSlider";
import FifthSlider from "./fifthSlider/FifthSlider";
import SixthSlider from "./sixthSlider/SixthSlider";
import DataLoader from "../../../../../Utils/DataLoader";
import { apiData } from "../../../../../actions";
import { UsePlaceAuth } from "../../../../../AppContext/AppContext";
import { useSelector } from "react-redux";
function AllFoodSliders({handleClick}) {
  const place = useSelector(state=>state.place)
  const [loader, setLoader] = useState(false);
  const [sliderData, setSliderData] = useState();
  const AllSliderData = async () => {
    setLoader(true);
    let payload = {
      city: place,
    };
    let apiRes = await apiData.restaurantDealsSearch(payload);
    setSliderData(apiRes);
    setLoader(false);
  };
  useEffect(() => {
    AllSliderData();
  }, [place]);
  return (
    <Fragment>
      <section className="client-sec comedy">
        {!loader ? (
          <>
            <FirstSlider sliderData={sliderData?.deliveryRestaurant} />
            <SecondSlider handleClick={handleClick}/>
            <ThirdSlider sliderData={sliderData?.fineDining} />
            <div className="adver-sec">
            <a href="https://www.jdoqocy.com/click-8265264-11094263" target="_top">
               <img src="https://www.ftjcfx.com/image-8265264-11094263" width="728" height="90" alt="Buy Jersey Boys Tickets" border="0"/>
            </a>
		</div>
            <FourthSlider sliderData={sliderData?.modePricedRestaurants} />
            <FifthSlider sliderData={sliderData?.cheapEats} />
            <SixthSlider sliderData={sliderData?.breakfast} />
          </>
        ) : (
          <DataLoader />
        )}
      </section>
    </Fragment>
  );
}

export default AllFoodSliders;

import React, { Fragment } from "react";
import CreateRoutes from "./routes/routes";
import "../src/scss/style.css";
// import 'antd/dist/antd.css';
import { BrowserRouter, HashRouter } from "react-router-dom";
import { lazy } from 'react';
import DataLoader from "./Utils/DataLoader";
import { Suspense } from "react";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from "react-toastify";
import { Provider } from "react-redux";
import { store } from "./Redux/Store";
function App() {
  
  const CreateRoutes = lazy(() => import('./routes/routes'));
  // if(new Date().getHours()>=18 ||new Date().getHours()<=6){
  //   document.body.classList.add("dark-mode")
  // }
  return (
    <Fragment>
      <BrowserRouter>
      <Suspense fallback={<DataLoader />}>
      <Provider store={store}>
      <CreateRoutes/>
      </Provider>
      </Suspense>
      </BrowserRouter>
      <ToastContainer theme="dark" position="top-right"/>
    </Fragment>
  );
}

export default App;
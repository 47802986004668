import React, { useEffect, useRef, useState } from 'react'
import AdverSec from '../../../restaurent/adverSec/AdverSec'
import BannerSec from '../../../restaurent/bannerSec/BannerSec'
import OwlCarousel from 'react-owl-carousel';
import AppPromo from '../../../restaurent/appPromo/AppPromo';
import DataLoader from '../../../../../Utils/DataLoader';
import { apiData } from '../../../../../actions';
import location from "../../../../../demo/lcation.png"
import { useSelector } from 'react-redux';
const Family = () => {
	const [deal, setDeal] = useState([])
	const [popularDeal, setPopularDeal] = useState([])
	const [keyword, setKeyword] = useState("")
	const [restaurant, setRestaurant] = useState([])
	const place = useSelector(state => state.place)
	const [tourData, setTourData] = useState("");
	const [loader, setLoader] = useState(false);
	const TopDealsRef = useRef();
	const [stay_hotel, setStay_Hotel] = useState([])
	const [viewLink, setViewLink] = useState("")
	var settings = {
		items: 4,
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		nav: true,
		dots: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 499,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};
	var settings1 = {
		items: 4,
		loop: true,
		autoplay: true,
		autoplayTimeout: 3000,
		autoplayHoverPause: true,
		nav: true,
		dots: false,
		responsive: [
			{
				breakpoint: 991,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: 499,
				settings: {
					slidesToShow: 1,
				}
			}
		]
	};
	const GetDeals = async () => {
		setLoader(true);
		let payload = {
			city: place,
			keyword: "attraction",
			limit: 12
		};
		let apiRes = await apiData.getYelpDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {

			setDeal(apiRes?.data?.data?.businesses);
			setLoader(false);
		}
	};
	const GetRestaurantDeals = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.getRestaurantDeals(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setRestaurant(apiRes?.data?.data?.data?.travelExperienceProducts?.resultList);
			setLoader(false);
		}
	};
	const GetTourTravel = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.ToursTravel(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setTourData(apiRes?.data?.data);
			setLoader(false);
		}
	};
	const handlePopularDealClick = async () => {
		setLoader(true);
		let payload = {
			city: place,
			keyword: keyword,
			limit: 12
		};
		if (payload.keyword === "" || null || undefined) {
			setLoader(true);
		} else {
			let apiRes = await apiData.getYelpDeals(payload);
			if (apiRes?.status >= 200 && apiRes?.status <= 399) {
				setPopularDeal(apiRes?.data?.data?.businesses);
				setLoader(false);
			}
		}
	}
	const handleScroll = () => {
		TopDealsRef.current.scrollIntoView({ behavior: "smooth" })
	}
	const Specific_Hotel = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.get_Specific_Hotel(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setStay_Hotel(apiRes?.data?.data[0].content.split('"')[1]);
			setLoader(false);
		
		}
	}
	const GetHotelView = async () => {
		setLoader(true);
		let payload = {
			city: place,
		};
		let apiRes = await apiData.get_Hotel_View(payload);
		if (apiRes?.status >= 200 && apiRes?.status <= 399) {
			setViewLink(apiRes?.data?.data);
			setLoader(false);
		
		}
	}
	useEffect(() => {
		setLoader(true)
		//script for Tours and travel
		document.getElementById("tour").innerHTML = ""
		let script = document.createElement('script');
		script.src = tourData.split('"')[1]
		document.getElementById("tour").appendChild(script);
		GetDeals()
		GetRestaurantDeals()
		GetTourTravel()
		handlePopularDealClick()
		Specific_Hotel()
		GetHotelView()
	}, [place, keyword, tourData])
	return (
		<div>
			<BannerSec />
			<AdverSec />
			<div className="rind-the-right-section comedy-sec">
				<div className="container">
					<div className="row">
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
							<div className="white-box-area inner rest-deals">
								<div className="row">
									<div className="col-lg-12 col-12">
										<section className="client-sec comedy">
											<div className="flight-sec exitment-sec">
												<div className="slider-sec">
													<h3>Popular things to do</h3>
													<p>Find fun places to see and things to do experience the art, museums, music, zoo</p>
													<OwlCarousel {...settings}>
														<div data-aos="zoom-in-right" className="slider-main">
															<div className="slider-block" onClick={() => { setKeyword("Museum"); handlePopularDealClick(); handleScroll() }}>
																<img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/things1.png`} alt='museum' />
																<div className="slider-content">
																	<h3>Museum</h3>
																</div>
															</div>
														</div>
														<div data-aos="zoom-in-right" className="slider-main">
															<div className="slider-block" onClick={() => { setKeyword("Sightseeing"); handlePopularDealClick(); handleScroll() }}>
																<img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/seeingtours.png`} alt='Sightseeing' />
																<div className="slider-content things">
																	<h3 >Sightseeing/tours</h3>
																</div>
															</div>
														</div>
														<div data-aos="zoom-in-right" className="slider-main">
															<div className="slider-block" onClick={() => { setKeyword("Day trip"); handlePopularDealClick(); handleScroll() }}>
																<img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/ttd_img3.png`} alt='Day-trip' />
																<div className="slider-content ">
																	<h3>Day Trip</h3>
																</div>
															</div>
														</div>
														<div data-aos="zoom-in-right" className="slider-main">
															<div className="slider-block" onClick={() => { setKeyword("Top attractions"); handlePopularDealClick(); handleScroll() }}>
																<img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/ttd_img4.png`} alt='Top Attractions' />
																<div className="slider-content ">
																	<h3>Top Attractions</h3>
																</div>
															</div>
														</div>
														<div data-aos="zoom-in-right" className="slider-main">
															<div className="slider-block" onClick={() => { setKeyword("NightLife"); handlePopularDealClick(); handleScroll() }}>
																<img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/Nightlife.png`} alt='Night Life' />
																<div className="slider-content ">
																	<h3>Night Life</h3>
																</div>
															</div>
														</div>
														<div data-aos="zoom-in-right" className="slider-main">
															<div className="slider-block" onClick={() => { setKeyword("Shopping"); handlePopularDealClick(); handleScroll() }}>
																<img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/Shopping.png`} alt='Shopping' />
																<div className="slider-content">
																	<h3>Shopping</h3>
																</div>
															</div>
														</div>
														<div data-aos="zoom-in-right" className="slider-main">
															<div className="slider-block" onClick={() => { setKeyword("Fine dinning"); handlePopularDealClick(); handleScroll() }}>
																<img src={`${process.env.REACT_APP_IMAGE_URL}backend/assets/images/city_images/fine-dining.jpg`} alt='Fine Dining' />
																<div className="slider-content">
																	<h3>Fine Dining</h3>
																</div>
															</div>
														</div>
													</OwlCarousel>
												</div>

												{/* Code for where to stay */}
												{/* <div className="testimonial-section products family-sec where_say_sec">
												<div className="head-yelp">
													<h3>Where to stay</h3>
													<p>Low hotel rates for luxury, comfort, pet-friendly rooms</p>
												</div>
												<div id="hotel_stay">
                                                    </div>
				                                    <div className="view-tag">
														<a href={viewLink}>View All</a>
															</div>
											</div> */}
												<div className="flight-sec exitment-sec Attractions">
													<div className="slider-sec">
														<h3>{`Top Attractions in ${place.replace(/\b\w/g, x => x.toUpperCase())}`}</h3>
													</div>
													<div data-aos="zoom-in-left" className="slider-main">
														<div className="container">
															<div className="row">
																{
																	deal && deal?.map((ele, index) =>
																		<div key={index} data-aos="zoom-in-right" className="col-lg-3 col-12 p-0">
																			<a href={ele?.url}>
																				<div className="slider-block ">
																					<img src={ele?.image_url} alt=''/>
																					<div className="slider-content">
																						<h3>{ele?.name}</h3>
																						<div className="escape-room">
																						<p className='time-formate'><img src="/images/location.svg" alt=''/>{ele?.location?.address1 ? ele?.location?.address1 : ele?.location?.display_address[0]}</p>
																							<p className='trav-sec desti'>{ele?.location?.city}</p>																		
																						</div>
																					</div>
																				</div>
																			</a>
																		</div>
																	)
																}
															</div>
														</div>
													</div>
												</div>

												<div className="flight-sec Things-sec">
													<div className="container">
														<div className="row">
															<div className="col-12 col-md-12 col-lg-12">
																<div className="slider-sec">
																	<h2>Tours & Travel</h2>
																	<p>Enjoy the scenic views of National Parks</p>
																</div>
															</div>
															<div data-aos="zoom-in-left" className='blog-main' id="tour">
																{/* <Slider {...settings}>
														{
															tourData && tourData?.map((ele) =>
																<div data-aos="zoom-in-right" className="testimonial-block product">
																	<div className="discount-block">
																		<a href={ele?.link}><img src={ele?.image_link} alt="" />
																		<div className="discount-content things">
																			<h3>{ele?.title}</h3>
																		</div>
																		</a>
																	</div>
																</div>
															)
														}
													</Slider> */}

															</div>
														</div>
													</div>
												</div>
												<div className="flight-sec exitment-sec tour-slider Restaurant">
													<div className="col-12 col-md-12 col-lg-12">
														<div className="slider-sec">
															<h3>Restaurant Deals</h3>
															<p>Save Yourself Or Family Money With Meal Deals</p>

															<div className="container">

																<div className="row">

																	<OwlCarousel {...settings1}>

																		{restaurant && restaurant?.map((ele) =>

																			<div key={ele.id} data-aos="zoom-in-left" className="slider-main">
																				<div className="slider-block">
																					<a href={ele?.link}>
																						<img src={ele?.imageLink} alt=''/>

																						<div className="slider-content winery-content">
																							<h3>{ele?.title.slice(0, 20)}...</h3>
																							<p>{ele?.description.length > 40 ? `${ele?.description.slice(0, 35)}...` : ele?.description}</p>

																							<div className="escape-room">
																								{/* <div className="review-sec"> */}
																								<p><span>{ele?.locationName}</span></p>
																								<p><span className="old-price">${ele?.price?.amount}</span> <span className="new-price">${ele?.salePrice?.amount}</span> <span className="dis-percent">- {Math.round((Number(ele?.price?.amount) - Number(ele?.salePrice?.amount)) * 100 / Number(ele?.price.amount))}%</span></p>
																							</div>
																							<div className="review-sec">
																								<a className="siti-btn full" >Book Now</a>
																							</div>
																						</div>
																					</a>

																				</div>
																			</div>

																		)
																		}
																	</OwlCarousel>										
																</div>
															</div>
														</div>
													</div>
												</div>
												<div className="slider-main" ref={TopDealsRef}>
													{
														keyword ?
															<div className="slider-heading">
																{
																	<h3>{`${keyword} in ${place.replace(/\b\w/g, x => x.toUpperCase())}`}</h3>
																}
															</div> : ""
													}

													{
														!loader ? <div className="row" >
															{
																popularDeal && popularDeal?.map((ele) =>

																	<div className="col-12 col-sm-6 col-md-4 col-lg-3">
																		<a href={ele?.url}><div data-aos="zoom-in-right" className="slider-block">
																			<img src={ele?.image_url} alt=''/>
																			<div className="slider-content">
																				<h3>{ele?.name}</h3>
																			</div>
																			<div className="stars">
																				<p><i className="fa fa-star" aria-hidden="true"></i>
																					<i className="fa fa-star" aria-hidden="true"></i>
																					<i className="fa fa-star" aria-hidden="true"></i>
																					<i className="fa fa-star" aria-hidden="true"></i></p>
																				<p>{ele?.review_count} Reviews</p>
																			</div>
																			{/* <div className="sticker"><img src={dig}/></div> */}
																		</div></a>
																	</div>
																)
															}
														</div>
															: <DataLoader />
													}

												</div>
											</div>
										</section>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>

			<AppPromo />
		</div>
	)
}

export default Family
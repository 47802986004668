import React from 'react'

import AppPromo from '../../restaurent/appPromo/AppPromo';
import { useState } from 'react';
import { UsePlaceAuth } from '../../../../AppContext/AppContext';
import Autocomplete from "react-google-autocomplete";
import { useEffect } from 'react';
import { apiData } from '../../../../actions';
import moment from 'moment';
import DataLoader from '../../../../Utils/DataLoader';
import { useMemo } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom/dist';
import { useDispatch, useSelector } from 'react-redux';
import { Things_to_do, setPlace } from '../../../../Redux/Action/Action';

const Sports = () => {
	const dispatch = useDispatch();
	let { SportsKeyword } = UsePlaceAuth()
	const place = useSelector(state => state.place)
	// const { active, setActive } = UsePlaceAuth()
	const [sportsSideBarAll, setSportsSideBarAll] = useState({})
	const [scrollComponent, setScrollComponent] = useState(false);
	const [testing, setTesting] = useState([SportsKeyword]);
	const [selectedCheckbox, setSelectedCheckbox] = useState(SportsKeyword);
	const [sliderData, setSliderData] = useState();
	const [loader, setLoader] = useState(false);
	let navigate = useNavigate()
	const handleSearch = (e) => {
		if (e.key.toLowerCase() == "enter") {
			navigate(`/sports/${e.target.value.toLowerCase()}`)
			e.preventDefault()
			dispatch(setPlace(e.target.value.toLowerCase()));
		}
	}
	useEffect(() => {
		window.scroll({
			top: 0,
			behavior: 'instant'
		}
		);
	}, [])

	let apiRes;
	const handleSideBar = useMemo(async () => {
		if (place) {
			setLoader(true);
			let payload = {
				city: place,
			};
			apiRes = await apiData.Sports_Side_Bar(payload);
			if (apiRes?.status >= 200 && apiRes?.status <= 399) {
				setSportsSideBarAll(apiRes?.data?.data[0])
				setLoader(false);
			}
		}

	}, [place])
	const handleMenuClick = (e) => {
		const { checked } = e.target;
		setScrollComponent(checked);
		if (checked) {
			const element = document.getElementById("items");
			element.scrollIntoView({ behavior: "smooth" });
		}
		const getValue = e.target.value;
		setSelectedCheckbox(!getValue ? SportsKeyword : getValue);
		setTesting((prevState) => {
			return checked
				? [prevState, getValue]
				: prevState?.filter((item) => item !== getValue);
		});
	};
	const FilterData = async (e, ind) => {
		if (!e || !e.target || !e.target.value) {
			console.error("Invalid event or event target value");
			return;
		}

		setLoader(true);
		let payload = {
			city: place,
			Keyword: e.target.value
		};

		try {
			let apiRes = await apiData.get_Sports(payload);
			if (apiRes?.status >= 200 && apiRes?.status <= 399) {
				setSliderData(apiRes?.data?.data);
				console.log("sssss", apiRes?.data?.data);
				setLoader(false);
			} else {
				console.error("Invalid response status:", apiRes.status);
				setLoader(false);
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			setLoader(false);
		}
	};

	//   useEffect(()=>{
	// 	if (apiRes?.status >= 200 && apiRes?.status <= 399) {
	// 		setSportsSideBarAll(apiRes?.data?.data[0])
	// 		setLoader(false);
	// 	}
	//   },[handleSideBar])
	useEffect(() => {
		FilterData()
		return () => {
			dispatch(Things_to_do())
		}
	}, [selectedCheckbox]);
	useEffect(() => {
		if (window.screen.width < 990) {
			document.getElementById("MenuIcon").style.display = "block"
			document.getElementById("MenuBar").style.display = "none"
		}
		else {
			document.getElementById("MenuIcon").style.display = "none"
			document.getElementById("MenuBar").style.display = "block"
		}
	}, [sliderData])

	const handleMenuIcon = () => {
		if (window.screen.width < 990) {
			if (document.getElementById("MenuBar").style.display === "none") {
				document.getElementById("MenuBar").style.display = "block"
			}
			else {
				document.getElementById("MenuBar").style.display = "none"
			}
		}
	}

	const handleMobileMenuClick = () => {
		if (window.screen.width < 990) {
			document.getElementById("MenuBar").style.display = "none"
		}
	}
	useEffect(() => {
		axios.get(`${process.env.REACT_APP_BASE_URL}ticketmaster?city=${place}&keyword=${SportsKeyword}`)
			.then((response) => {
				setLoader(true);
				setSliderData(response?.data?.data);
				setLoader(false);
			})
	}, [SportsKeyword])

	useEffect(() => {
		return () => {
			window.scroll({
				top: 0,
				behavior: "instant",
			});
		};
	});
	return (
		<div>
			<div className="banner-section rest-sec sport-banners">
				<div className="container">
					<div className="row">
						<div className="col-12 col-sm-12 col-md-12 col-lg-12">
							<div className="tab-content" id="myTabContent">
								<div className="tab-pane fade show active" id="hotel-tab-pane" role="tabpanel" aria-labelledby="hotel-tab" tabIndex="0">
									<div className="select-form restaurent-sec">
										<form>
											<div className="choose-sec">
												<Autocomplete
													className="form-control"
													apiKey="AIzaSyDrSVPFOzjxmlv2WLSnfJ0ARrmYP9QDajs"
													placeholder={place?.replace(/\b\w/g, x => x.toUpperCase())}
													onPlaceSelected={async (city) => {
														await dispatch(setPlace((city.formatted_address.split(',')[0]).toLowerCase()))
														navigate(`/sports/${(city.formatted_address.split(',')[0]).toLowerCase()}`)
													}}
													onKeyPress={handleSearch}
												/>
											</div>
										</form>
									</div>
									{/* <div className="action-sec" >
										<a onClick={() => { setPlace(selectedplace) }}>Search</a>
									</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="rind-the-right-section comedy-sec sports-grid-result">
				<div className="container">
					<div className="row">
						<button onClick={handleMenuIcon} id="MenuIcon" style={{ display: "none" }}>
							<i className="fa fa-bars" aria-hidden="true"> Catagory</i>
						</button>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-3" id="MenuBar">
							<div className="sidebar-listing">
								<div className="specialities-checkbox">
									<div className="searcher-sec">
										<label className="custom-control-label">Search By Name</label>
										<div className="form-group">
											<input type="Name" readOnly className="form-control" id="exampleFormControlInput1" placeholder={place} />
										</div>
									</div>
								</div>
								{/* <div className="specialities-checkbox"> */}
									<div className="specialities-checkbox">
										{Object.keys(sportsSideBarAll)?.map((ele, index) => {
											let res = sportsSideBarAll[ele].split(",");
											return (
												<div className="listing-check" key={ele}>
													<h4>{ele}</h4>
													{res && res.map((e, innerIndex) => (
														<div className="custom-control custom-checkbox" key={`${ele}-${innerIndex + 1}`}>
															<input
																type="checkbox"
																className="custom-control-input"
																id={`${ele}-${innerIndex + 1}`}
																value={e}
																checked={testing.includes(e)}
																onChange={(event) => {
																	handleMenuClick(event);
																	FilterData(event);
																}}
															/>
															<label
																className="custom-control-label"
																htmlFor={`${ele}-${innerIndex + 1}`}
																onClick={handleMobileMenuClick}
																key={`label-${ele}-${innerIndex + 1}`}
															>
																{e}
															</label>
														</div>
													))}
												</div>
											);
										})}
									</div>

								{/* </div> */}
								<div>
								</div>
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-9">
							<div className="white-box-area inner rest-deals">
								<div className="row">
									<div className="col-md-12 col-12">
										<div className="heading-content">
											<div className="content-sec" id="items">
												<p>Showing 3 of 257 places</p>
											</div>
										</div>
									</div>
									<div className="col-lg-12 col-12">
										{
											!loader ? <section className="client-sec comedy">
												<div className="comedy-bottom-sec  testimonial-section products">
													{
														sliderData && sliderData?.map((ele) => {
															return (
																<>
																	<div data-aos="zoom-in-right" className="slider-main">
																		<a href={ele?.url} target="_blank">
																		</a>
																		<div className="slider-block">
																			<a href={ele?.url} target="_blank">
																				<div className="cities">
																					<img src={ele?.images[1]?.url} alt=''/>
																				</div>
																				<div className="slider-content">
																					<h3>{ele?.name.slice(0,20)}...</h3> <span><i className="fa fa-map-marker" aria-hidden="true"></i>{ele?._embedded.venues[0].name}</span>
																				</div>
																				<div className="escape-room">
																					<p><i className="fa fa-map-marker" aria-hidden="true"></i>{ele?._embedded?.venues?.map((ele) => {
																						return (
																							ele?.address?.line1
																						)
																					})}</p>
																					<p><i className="fa fa-calendar" aria-hidden="true"></i>


																						{new Date().toUTCString(ele?.sales?.public?.startDateTime).split(",")[0]},{moment(ele?.sales?.public?.startDateTime).format(
																							"MMMM Do YYYY"
																						)}
																						{/* {
	new Intl.DateTimeFormat('en', { day:"2-digit",weekday:"short",month:"long",year:"numeric"}).format(new Date(new Date(ele?.sales?.public?.startDateTime).toLocaleDateString()))
} */}
																					</p>
																					<p><i className="fa fa-clock-o" aria-hidden="true"></i>{
																						new Date(ele?.sales?.public?.startDateTime).toLocaleTimeString(navigator.language, { hour: '2-digit', minute: '2-digit' })
																					}</p>
																				</div>
																			</a>
																			<div className="review-sec">
																				<a className="siti-btn full" href={ele?.url} target="_self">See Ticket </a>
																			</div>
																		</div>
																	</div>
																</>
															)
														})
													}
												</div>
											</section>
												:
												<DataLoader />
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <AdverSec /> */}
			<AppPromo />
		</div>
	)
}
export default Sports
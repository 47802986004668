import React from 'react'

import { useEffect } from 'react';

const FlightHomeBanner = () => {
    useEffect(() => {
        let script = document.createElement('script');
        script.src = "https://tp.media/content?currency=usd&trs=26480&shmarker=130544&combine_promos=101_7873&show_hotels=false&powered_by=false&locale=en&searchUrl=travel.mysittivacations.com%2Fflights&primary_override=%23196ae1&color_button=%23196AE1&color_icons=%23196AE1&dark=%23262626&light=%23FFFFFF&secondary=%23FFFFFF&special=%23C4C4C4&color_focused=%23196ae1&border_radius=0&no_labels=true&plain=true&promo_id=7879&campaign_id=100"
        document.getElementById("flightSearch").appendChild(script)
        // document.body.style.backgroundColor="transparent"
        // document.querySelector(".search-box-wrapper").style.backgroundColor="transparent"
    }, [])
  return (
    <div>
	   <div id="flightSearch">
       </div>
    </div>
  )
}

export default FlightHomeBanner
import React, { Fragment } from "react";
import Slider from "react-slick";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
// import { UsePlaceAuth } from "../../../../../../AppContext/AppContext";
// import rest6 from "../../../../../../demo/rest6.png"
// import rest7 from "../../../../../../demo/rest7.png"
// import rest8 from "../../../../../../demo/rest8.png"
import { useSelector } from "react-redux";

function SecondSlider({handleClick}) {
  const place = useSelector(state=>state.place)
  var settings = {
    items: 3,
    loop: true,
    autoplay: true,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    nav: true,
    dots: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 499,
        settings: {
          slidesToShow: 1,
        }
      }
    ]
  };
  // let payload = {
  //   city: place,
  //   keyword: e.target.value,
  // };
  // let apiRes = await apiData.restaurantDealsFilterSearch(payload);
  // setSliderData(apiRes);
  // const element = document.getElementById("my-element");
  // element.scrollIntoView({ behavior: "smooth" });
  return (
    <Fragment>
      <div className="col-lg-12 col-12">
      <div className="slider-restaurent">
      <div data-aos="zoom-in-left" className="slider-main">
        <div className="slider-heading">
          <h3>{`${place?.replace(/\b\w/g, x => x?.toUpperCase())} by Food`}</h3>
        </div>
        <div className=" " id="slide-restaurent" onClick={(e)=>{handleClick(e?.target?.alt)}}>
          <OwlCarousel {...settings}>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/mexican-cuisine.jpg" alt="MEXICAN" />
                    <div className="slider-content">
                      <h3>
                      MEXICAN</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/lunchnew.jpg" alt="Lunch" />
                    <div className="slider-content">
                      <h3>
                      Lunch</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right">
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/cheapeat.jpg" alt="Cheap Eats" />
                    <div className="slider-content">
                      <h3>
                      Cheap Eats</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/coffytea.jpg" alt="Coffee & Tea" />
                    <div className="slider-content">
                      <h3>
                      Coffee & Tea</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/seafoodnew.jpg" alt="Seafood" />
                    <div className="slider-content">
                      <h3>
                      Seafood</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/brkfirstImg.jpg" alt="Breakfast" />
                    <div className="slider-block">
                      <h3>
                      Breakfast</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right">
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/chinese-cuisine.jpg" alt="Chinese" />
                    <div className="slider-content">
                      <h3>
                      Chinese</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/italian-cuisine.jpg" alt="Italian" />
                    <div className="slider-content">
                      <h3>
                      Italian</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/pizza-cuisine.jpg" alt="Pizza" />
                    <div className="slider-content">
                      <h3>
                      Pizza</h3>
                    </div>
                  </div>
                  </div>
                  <div data-aos="zoom-in-right" >
                  <div className="slider-block">
                    <img src="https://www.mysittivacations.com/backend/assets/images/city_images/barbecue-beef.jpg" alt="Barbeque" />
                    <div className="slider-content">
                      <h3>
                      Barbeque</h3>
                    </div>
                  </div>
                  </div>
          </OwlCarousel>
        </div>
      </div>
      </div>
      </div>
    </Fragment>
  );
}

export default SecondSlider;

import React from 'react'

const BlogBanner = () => {
  return (
    <div>
            <div className="banner-section rest-sec" style={{ height: "250px" }}>
            </div>
    </div>
  )
}

export default BlogBanner
